var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.chart[0] !== undefined)?_c('v-container',{attrs:{"id":"Config-app-view","fluid":"","tag":"section"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"9"}},[(_vm.error && !_vm.errorMessage)?_c('Alert',{attrs:{"message":"Impossible de joindre le backend !"}}):(_vm.error)?_c('Alert',{attrs:{"message":_vm.$t(_vm.errorMessage)}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',[_c('MaterialCard',{attrs:{"color":"secondary","icon":"tune"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('deploiement.header'))}})]},proxy:true}],null,false,4000735710)},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"label":_vm.$t('deploiement.nom'),"rules":_vm.nomRegle,"counter":"","maxlength":"50","required":""},model:{value:(_vm.nom),callback:function ($$v) {_vm.nom=$$v},expression:"nom"}}),(_vm.versions !== undefined)?_c('v-select',{attrs:{"hint":_vm.$t('deploiement.version'),"item-text":function (versions) { return versions !== undefined &&
                    versions.pkgVersion +
                    ' - ' +
                    'App version ' +
                    versions.appVersion; },"item-value":function (versions) { return versions.appVersion; },"items":_vm.versions,"label":_vm.$t('deploiement.version'),"persistent-hint":"","single-line":""},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}}):_vm._e(),_c('v-select',{attrs:{"hint":_vm.$t('deploiement.deploiementLabel'),"item-text":function (deployementPlan) { return _vm.$t(deployementPlan.name) +
                    ' - Memory : ' +
                    Math.round(
                      _vm.limits.get('resources.limits.memory') *
                        deployementPlan.ratio *
                        100
                    ) /
                    100 +
                    'Mi - CPU : ' +
                    Math.round(
                      _vm.limits.get('resources.limits.cpu') *
                        deployementPlan.ratio
                    ) +
                    'm'; },"item-value":function (deployementPlans) { return deployementPlans; },"items":_vm.deployementPlans,"label":_vm.$t('deploiement.deploiementLabel'),"persistent-hint":"","single-line":""},model:{value:(_vm.deployementPlan),callback:function ($$v) {_vm.deployementPlan=$$v},expression:"deployementPlan"}}),_vm._l((_vm.items),function(item,index){return _c(item,{key:index,tag:"FormConfiguration",staticClass:"mt-3 mb-3",attrs:{"config":_vm.chart[0].application.userConfig[index],"index":index,"configs":_vm.chart[0].application.userConfig},on:{"configuration-form-deploy":_vm.updateConfiguration}})})],2)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AppCard',{staticClass:"mt-4 text-center",attrs:{"name":"name"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-img',{staticClass:"mt-n12 d-inline-block",attrs:{"src":_vm.chart[0].iconUrl,"width":"128"}}),_c('v-card-text',{staticClass:"text-center"},[_c('h6',{staticClass:"text-h6 mb-2 text--secondary"},[_vm._v(" "+_vm._s(_vm.chart[0].categories[0])+" ")]),_c('h4',{staticClass:"text-h4 mb-3 text--primary"},[_vm._v(" "+_vm._s(_vm.chart[0].name)+" ")]),_c('p',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.chart[0].shortDescription)+" ")]),_c('v-btn',{staticClass:"mr-0",attrs:{"disabled":!_vm.valid,"loading":_vm.loading,"block":"","color":"secondary","min-width":"100","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("deploiement.commander"))+" ")])],1)],1)],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }