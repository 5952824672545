export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_PROVIDER = "AUTH_PROVIDER";
export const AUTH_SUCCESS_PROVIDER = "AUTH_SUCCESS_PROVIDER";
export const AUTH_CREATION_SUCCESS = "AUTH_CREATION_SUCCESS";
export const AUTH_ERROR = "AUTH_ERROR";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const AUTH_REFRESH_TOKEN = "AUTH_REFRESH_TOKEN";
export const AUTH_CREATE_ACCOUNT = "AUTH_CREATE_ACCOUNT";
export const AUTH_CREATE_ACCOUNT_AND_JOIN = "AUTH_CREATE_ACCOUNT_AND_JOIN";
