








































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class ApplicationAdmin extends Vue {
  @Prop() application: any

  get url() {
    return process.env.VUE_APP_SPRING_API_BASEURL
  }

  public modifier() {
    this.$emit("update-app", this.application);
  }

  public supprimer() {
    this.$emit("delete-app", this.application);
  }
}
