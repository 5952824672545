




























































































import Vue from "vue";
import axios from "axios";

import {Component} from "vue-property-decorator";

import Filtre from "@/components/Filtre.vue";
import Chart from "@/components/Chart.vue";
import Alert from "@/components/alert/Alert.vue";
import SearchApp from "@/components/SearchApp.vue";

@Component({
  components: {
    Filtre,
    Chart,
    Alert,
    SearchApp,
  },
})
export default class Catalogue extends Vue {
  filtres: string[] = [];
  charts: { categories: [] }[] = [];
  filtresSelected: string[] = [];
  page: number = 0;
  maxPage: number = 1;
  error: boolean = false;
  emptySearch: boolean = false;
  loading: boolean = true;
  searchName: string = "";
  isAllowToDeploy: boolean = true;

  public beforeMount(): void {
    this.loadCharts();
    this.getDeploymentState();
  }

  public mounted(): void {
    this.getNextCharts();
  }

  public getDeploymentState() {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/default/deploymentAllowed`
        )
        .then((response) => {
          this.isAllowToDeploy = response.data;
        })
        .catch(() => {
        });
  }

  public loadCharts(): void {
    this.loading = true;

    axios
        .get(
            `${
                process.env.VUE_APP_SPRING_API_BASEURL
            }/api/default/${localStorage.getItem("namespace")}/applications?page=${
                this.page
            }`
        )
        .then((response) => {
          this.charts = this.charts.concat(response.data.availablePackageSummaries);
          this.maxPage = response.data.nextPageToken ? response.data.nextPageToken - 1 : 0;
          this.charts.forEach((value: any) => {
            if (value.categories.length > 0 && this.filtres.indexOf(value.categories[0]) === -1) {
              this.filtres.push(value.categories[0]);
            }
          });
          if (this.charts.length < 20) {
            if (this.page < this.maxPage) {
              this.page++;
              this.loadCharts();
            }
          }
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
        });
  }

  public updateFiltre(filtre: string): void {
    // On doit charger tous les éléments
    if (this.filtresSelected.indexOf(filtre) === -1) {
      this.filtresSelected.push(filtre);
    } else {
      this.filtresSelected.splice(this.filtresSelected.indexOf(filtre), 1);
    }
  }

  public updateSearchApp(input: string): void {
    this.searchName = input;
  }

  public getNextCharts(): void {
    window.onscroll = () => {
      const bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
      if (bottomOfWindow && this.page < this.maxPage) {
        this.page++;
        this.loadCharts();
      }
    };
  }

  public loadAll(): void {
    if (this.page < this.maxPage) {
      this.page++;
      this.loadCharts();
      this.loadAll();
    }
  }
}
