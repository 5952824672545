var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.$route.name !== 'Login' &&
      _vm.$route.name !== 'NotFoundPage' &&
      _vm.$route.name !== '500' &&
      _vm.$route.name !== 'Signup' &&
      _vm.$route.name !== 'Action' &&
      _vm.$route.name !== 'ForgotPassword' &&
      _vm.userData
  )?_c('v-app-bar',{attrs:{"app":"","color":"tertiary","flat":""}},[_c('v-container',{staticClass:"py-0",attrs:{"fill-height":"","fluid":""}},[_c('v-btn',{staticClass:"no-active",attrs:{"ripple":false,"active-class":"no-active","icon":"","to":"/"}},[(!_vm.avatar)?_c('v-avatar',{attrs:{"color":"secondary","rounded":"","size":"32"}},[_c('span',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.userData.name.substr(0, 1).toUpperCase()))])]):_c('v-avatar',{attrs:{"rounded":"","size":"32"}},[_c('img',{attrs:{"src":_vm.userData.picture
              ? _vm.avatar
              : 'data:image/png;base64,' + _vm.avatar,"alt":"A"}})])],1),(_vm.loadingApp)?[_c('v-skeleton-loader',{staticClass:"d-none d-lg-flex mr-2",attrs:{"type":"button"}}),_c('v-skeleton-loader',{staticClass:"d-none d-lg-flex mr-2",attrs:{"type":"button"}}),_c('v-skeleton-loader',{staticClass:"d-none d-lg-flex mr-2",attrs:{"type":"button"}}),_c('v-skeleton-loader',{staticClass:"d-none d-lg-flex mr-2",attrs:{"type":"button"}})]:_vm._l((_vm.links),function(link){return [(
          !link.requiredAdmin ||
            (link.requiredAdmin &&
              _vm.userData.realm_access &&
              _vm.userData.realm_access.roles.includes('ADMIN'))
        )?_c('v-btn',{key:link.name,staticClass:"d-none d-lg-flex",attrs:{"disabled":!_vm.hasEntreprise,"to":link.url,"text":""}},[_vm._v(" "+_vm._s(_vm.$t(link.name))+" ")]):_vm._e()]}),(
        _vm.userData.realm_access && _vm.userData.realm_access.roles.includes('ADMIN')
      )?_c('AppBarDropdown',{attrs:{"data-list":_vm.namespaces,"name":'appbar.namespaces'}}):_vm._e(),(_vm.entreprises.length > 1)?_c('AppBarDropdown',{attrs:{"data-list":_vm.entreprises,"name":'appbar.entreprises'}}):_vm._e(),_c('v-spacer'),_c('v-responsive',{staticClass:"d-none d-sm-flex",attrs:{"max-width":"500"}},[(_vm.$route.params.app)?_c('div',{staticClass:"text-uppercase float-right"},[_vm._v(" "+_vm._s(_vm.$route.params.app.split(_vm.$route.params.namespace + "-")[1])+" ")]):_vm._e()]),_c('Notifications',{attrs:{"user-data":_vm.userData}}),_c('v-btn',{staticClass:"float-right d-none d-lg-flex",attrs:{"text":"","to":"/account/infos"}},[_vm._v(" "+_vm._s(_vm.$t("appbar.compte"))+" ")]),_c('v-btn',{staticClass:"float-right d-none d-lg-flex",attrs:{"text":""},on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.$t("appbar.deconnexion"))+" ")]),_c('v-btn',{staticClass:"d-none d-lg-flex",attrs:{"color":"primary","elevation":"2","rounded":"","small":""},on:{"click":_vm.darkMode}},[(_vm.$vuetify.theme.dark)?_c('v-icon',[_vm._v(" mdi-white-balance-sunny ")]):_c('v-icon',[_vm._v(" mdi-weather-night ")])],1),_c('v-app-bar-nav-icon',{staticClass:"d-flex d-md-flex d-lg-none",on:{"click":function($event){return _vm.setMobile()}}})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }