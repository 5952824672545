



















































































































































































































































































































































































































































































































































































































































































































































import colors from "vuetify/es5/util/colors";

import {INVITATION_REQUEST} from "@/api/actions/user/const_invitation";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class ErrorComponent extends Vue {
  @Prop() title: String | undefined
  @Prop() subtitle: String | undefined
  @Prop() message: String | undefined
  @Prop() btn: boolean | undefined
  @Prop() type: any

  public color: any = {
    border: "#000000",
    visor: "#FFFFFF",
    fill: "#FFFFFF",
    circleBorder: "#000000",
    starSmallBorder: "#000000",
    starBigBorder: "#000000",
    planetRing: "#000000",
  }

  public invitation: boolean = false

  get darkTheme() {
    return this.$vuetify.theme.dark;
  }

  get userData() {
    return this.$store.getters.userData;
  }

  public mounted() {
    this.setImgColor(this.darkTheme);
    this.hasInvitation();
  }

  public delAccount() {
    this.$emit("delAccount");
  }

  public createNewEntreprise() {
    this.$emit("createNewEntreprise");
  }

  public joinEntreprise() {
    this.$emit("joinEntreprise");
  }

  public hasInvitation() {
    this.$store
        .dispatch(INVITATION_REQUEST, this.userData.sub)
        .then((response) => {
          if (response != null && response.length > 0) {
            this.invitation = true;
          }
        })
        .catch(() => {
        });
  }

  public setImgColor(isDark: any) {
    if (isDark) {
      this.color.border = colors.teal.accent3;
      this.color.visor = colors.blueGrey.darken1;
      this.color.fill = colors.blueGrey.darken3;
      this.color.circleBorder = colors.teal.accent3;
      this.color.starSmallBorder = colors.teal.lighten1;
      this.color.starBigBorder = colors.teal.accent3;
      this.color.planetRing = colors.teal.accent3;
    } else {
      this.color.border = colors.blueGrey.darken3;
      this.color.visor = colors.blueGrey.darken1;
      this.color.fill = colors.deepOrange.darken3;
      this.color.circleBorder = colors.amber.lighten2;
      this.color.starSmallBorder = colors.yellow.darken3;
      this.color.starBigBorder = colors.lime.lighten2;
      this.color.planetRing = colors.orange.darken2;
    }
  }

  @Watch('darkTheme')
  public onThemeChange(val: boolean) {
    this.setImgColor(val)
  }

  @Watch('userData')
  public onUserDateChange(val: any) {
    if (val.realm_access && val.sub) {
      this.hasInvitation();
    }
  }
}
