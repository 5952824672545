






































































































































import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import {USER_NEW_SCREENSHOT, USER_NEW_TICKET} from "@/api/actions/support/const_user_ticket";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {TranslateResult} from "vue-i18n";

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class NewUserTicket extends Vue {
  @Prop({}) dialog: boolean | undefined;

  dialogTrigger: boolean = false;
  sujet: string = "";
  type: string = "AIDE";
  message: string = "";

  error: boolean = false;
  errorMessage: TranslateResult = "";

  success: boolean = false;
  successMessage: TranslateResult = "";

  screenshotUploaded: any = null;
  screenshotUrls: { file: File, url: string }[] = [];
  screenshotList: File[] = [];
  screenshotZoom: string = "";

  zoomDialog: boolean = false;

  get userData() {
    return this.$store.getters.userData;
  }

  get typeTicket(): string[] {
    return ["AIDE", "BUG", "AUTRE", "QUESTION"];
  }

  get observer(): Vue & { validate: () => boolean } {
    return this.$refs.observer as Vue & { validate: () => boolean };
  }

  public submit(): void {
    this.observer.validate();

    const {message, type} = this;

    const data = {
      utilisateur: this.userData.preferred_username,
      sujet: this.sujet,
      supportType: type,
      ticketStatus: "EN_ATTENTE",
      messagesSupportList: [
        {
          auteur: this.userData.preferred_username,
          message: message,
        },
      ],
    };

    this.$store
        .dispatch(USER_NEW_TICKET, data)
        .then((response) => {
          if (this.screenshotList.length > 0) {
            const formData = new FormData();

            this.screenshotList.forEach((screen) => {
              formData.append("screenshot", screen);
            });

            this.$store.dispatch(USER_NEW_SCREENSHOT, {
              ticketId: response.id,
              screenshot: formData
            });

            this.screenshotUrls = [];
            this.screenshotList = [];
            this.screenshotUploaded = null;
          }

          this.success = true;
          this.error = false;
          this.successMessage = "OK";

          this.sujet = "";
          this.message = "";

          this.$emit("reloadTickets");
        })
        .catch((err) => {
          this.error = true;
          this.success = false;
          this.errorMessage = err;
        });
  }

  public addScreenshot(e: any): void {
    this.screenshotList.push(e);
    this.screenshotUrls.push({file: e, url: URL.createObjectURL(e)});
  }

  public zoomImage(file: string): void {
    this.screenshotZoom = file;
    this.zoomDialog = true;
  }

  public deleteScreen(screen: { file: File, url: string }): void {
    const indexList = this.screenshotList.indexOf(screen.file);

    // L'index dans le tableau screenshotList sera toujours identique à celui dans screenshotUrls
    if (indexList !== -1) {
      this.screenshotList.splice(indexList, 1);
      this.screenshotUrls.splice(indexList, 1);
    }
  }

  @Watch("dialog")
  onDialogChange() {
    this.dialogTrigger = true;
  }
}
