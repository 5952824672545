


























import PriceDetails from "@/components/basket/PriceDetails.vue"
import Vue from "vue"
import {Component} from "vue-property-decorator"
import Details from "@/components/basket/Details.vue";

@Component({
  components: {
    Details,
    PriceDetails,
  },
})
export default class Panier extends Vue {
  public error: boolean = false
  public errorMessage: String = ''

  get applicationValues() {
    return this.$store.getters.applicationValues
  }

  public errorDeployment(msg: any) {
    this.error = true;
    this.errorMessage = msg;
  }
}
