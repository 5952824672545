
































































































import Vue from "vue";
import {Component} from "vue-property-decorator";

@Component
export default class Details extends Vue {
  public details: boolean = false

  get URL() {
    return process.env.VUE_APP_SPRING_API_BASEURL
  }

  get applicationValues() {
    return this.$store.getters.applicationValues
  }

  get entreprise() {
    return this.$store.getters.entreprise;
  }

  public getConfiguration(name: any) {
    if (this.applicationValues.configuration)
      return this.applicationValues.configuration.find(
          (app: any) => app.key === name
      );
  }

  public getConfigurations() {
    if (this.applicationValues.configuration)
      return this.applicationValues.configuration.filter(
          (app: any) => app.visible
      );
  }
}
