import axios from "axios";
import * as ACTIONS from "../../actions/pricing/const_monthly_facture";

const moment = require("moment");

const actions = {
  // eslint-disable-next-line no-unused-vars
  [ACTIONS.MONTHLY_FACTURES_REQUEST]: ({commit}: any, entreprise: any) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/monthly-facture/${entreprise}`
        )
        .then(response => {
          const factures: any[] = [];

          moment.locale("fr");

          response.data.forEach((facture: any) => {
            factures.push({
              application: facture.application,
              nomApplication: facture.nomApplication,
              date: moment(facture.date).format("L"),
              actions: facture.actions,
              timestamp: moment(facture.date).format("x"),
              pdf: facture.pdf,
            });
          })

          resolve(factures);
        })
        .catch((error) => {
          reject(error.response.data.message);
        })
    });
  }
}

export default {
  actions
};
