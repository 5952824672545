





























import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class Filtre extends Vue {
  @Prop() filtres: object | undefined
  @Prop() activefiltres: object | undefined

  public selectFiltre(filtre: any) {
    this.$emit("select-filtre", filtre);
  }
}
