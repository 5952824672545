



































import Vue from "vue";

import {Component} from "vue-property-decorator";

import {TranslateResult} from "vue-i18n";

@Component({})
export default class DialogConfirmation extends Vue {
  dialog: boolean = false;
  resolve: any;
  reject: any;
  message: TranslateResult = "";
  title: string | TranslateResult = "";
  options: object = {
    color: "tertiary",
    width: 400,
    zIndex: 200,
    noconfirm: false,
  };

  public open(title: string | TranslateResult, message: string | TranslateResult, options: any): Promise<string> {
    this.dialog = true;
    this.title = title;
    this.message = message;
    this.options = Object.assign(this.options, options);
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public agree(): void {
    this.resolve(true);
    this.dialog = false;
  }

  public cancel(): void {
    this.resolve(false);
    this.dialog = false;
  }
}
