import axios from "axios";
import * as ACTIONS from "../../actions/pricing/const_promotion";

const actions = {
  // eslint-disable-next-line no-unused-vars
  [ACTIONS.PROMO_NEW]: ({commit}: any, data: any) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/promos`, data
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.PROMO_REQUEST]: ({commit}: any) => {
    return new Promise((resolve, reject) => {
      axios
        .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/promos`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.PROMO_STOP]: ({commit}: any, data: any) => {
    return new Promise((resolve, reject) => {
      axios.put(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/promos`, data)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.PROMO_DELETE]: ({commit}: any, id: any) => {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/promos/${id}`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.PROMO_USE]: ({commit}: any, code: any) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/promos/${code}`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.PROMO_REQUEST_ONE]: ({commit}: any, code: any) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/promos/${code}`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        });
    });
  },
}

export default {
  actions
};
