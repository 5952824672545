










import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class SearchApp extends Vue {
  @Prop() labelBar: String | undefined

  public searchInput: String = ""

  public searchApp() {
    this.$emit("search-app", this.searchInput);
  }

  @Watch("searchInput")
  onSearchInput(value: String) {
    if (value !== this.searchInput) {
      this.searchApp()
    }
  }
}
