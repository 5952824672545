
























































































































import Vue from "vue";

import {Component, Prop} from "vue-property-decorator";

@Component({})
export default class BasicPreference extends Vue {
  @Prop({}) newApp: number | undefined;
  @Prop({}) deleteApp: number | undefined;
  @Prop({}) invitationNotif: number | undefined;
  @Prop({}) memberNotif: number | undefined;
  @Prop({}) disableEnableApp: number | undefined;
  @Prop({}) updateApp: number | undefined;
  @Prop({}) items: number | undefined;

  public newAppBasic: number | undefined = 0;
  public deleteAppBasic: number | undefined = 0;
  public invitationNotifBasic: number | undefined = 0;
  public memberNotifBasic: number | undefined = 0;
  public disableEnableAppBasic: number | undefined = 0;
  public updateAppBasic: number | undefined = 0;
  public itemsBasic: number | undefined = 0;

  get userData() {
    return this.$store.getters.userData;
  }

  get entrepriseName() {
    return this.$store.getters.entreprise;
  }

  public mounted(): void {
    this.newAppBasic = this.newApp;
    this.deleteAppBasic = this.deleteApp;
    this.invitationNotifBasic = this.invitationNotif;
    this.memberNotifBasic = this.memberNotif;
    this.disableEnableAppBasic = this.disableEnableApp;
    this.updateAppBasic = this.updateApp;
    this.itemsBasic = this.items;
  }

  public changeValue(type: string, value: number): void {
    this.$emit("value-change", {type, value});
  }
}
