


















































































import Vue from "vue";
import axios from "axios";

import {Component, Watch} from "vue-property-decorator";

import AppBarMobile from "@/components/app/appbar/mobile/AppBarMobile.vue";
import AppBar from "@/components/app/appbar/desktop/AppBar.vue";
import {AUTH_LOGOUT, AUTH_REFRESH_TOKEN} from "@/store/actions/auth";
import {
  SET_ENTREPRISE,
  SET_HAVE_ENTERPRISE,
  SET_SELECTED_ENTREPRISE,
  SET_SELECTED_NAMESPACE,
} from "@/store/actions/data";
import {INVITATION_REQUEST} from "@/api/actions/user/const_invitation";

import i18n from "@/plugins/i18n";
import Highcharts from "highcharts";
import CookieConsent from "@/components/CookieConsent.vue";
import {theme, theme1} from "@/main";

@Component({
  components: {
    CookieConsent,
    AppBar,
    AppBarMobile,
  },
})
export default class App extends Vue {
  links: { name: string; url: string; requiredAdmin: boolean }[] = [
    {name: "appbar.application", url: "/", requiredAdmin: false},
    {name: "appbar.catalogue", url: "/catalogue", requiredAdmin: false},
    {
      name: "appbar.administration",
      url: "/administration/statistiques",
      requiredAdmin: true,
    },
  ];
  namespaces: string[] = [];
  viewKey: number = 1;
  namespace: any = "";
  mailLoad: boolean = false;
  invitation: boolean = false;
  inviteCompany: string = "";
  loadingApp: boolean = true;
  entreprises: string[] = [];

  get userData() {
    return this.$store.getters.userData;
  }

  get selectedEntreprise() {
    return this.$store.getters.selectedEntreprise;
  }

  get selectedNamespace() {
    return this.$store.getters.selectedNamespace;
  }

  public mounted(): void {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      this.$vuetify.theme.dark = theme === "true";
    }

    if (this.$store.getters.isAuthenticated) {
      this.loadNamespace(false);
      this.hasInvitation();
    }

    // Définition de la langue par defaut
    const lang = localStorage.getItem("lang");

    // Les langues disponible sont dans le store
    if (lang && this.$store.getters.availableLanguage.includes(lang)) {
      i18n.locale = lang;
    } else {
      i18n.locale = "fr";
    }

    localStorage.setItem("lang", i18n.locale);
  }

  public hasInvitation(): void {
    this.$store
        .dispatch(INVITATION_REQUEST, this.userData.sub)
        .then((response) => {
          this.inviteCompany = response.entreprise;
          this.invitation = true;
        })
        .catch(() => {
          this.invitation = false;
        });
  }

  public sendEmail(): void {
    this.mailLoad = true;
    axios
        .post(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/authentication?userId=${this.userData.sub}&action=validateEmail`
        )
        .then(() => {
          this.mailLoad = false;
        })
        .catch(() => {
        });
  }

  public logout(): void {
    this.$store.dispatch(AUTH_LOGOUT, this.userData.session_state).then(() => {
      this.$router.push("/login").catch(() => {});
    });
  }

  public account(): void {
    this.$router.push("/account/infos").catch(() => {});
  }

  public darkMode(): void {
    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    if (this.$vuetify.theme.dark) {
      Highcharts.setOptions(theme);
    } else {
      Highcharts.setOptions(theme1);
    }
    localStorage.setItem("dark_theme", this.$vuetify.theme.dark.toString());
  }

  loadNamespace(refreshed: boolean): void {
    this.namespaces = [];

    // Si l'utilisateur est un admin
    if (
        this.userData.realm_access &&
        this.userData.realm_access.roles.indexOf("ADMIN") !== -1
    ) {
      axios
          .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/default/namespaces`)
          .then((response) => {
            response.data.namespaces.forEach((value: any) => {
              this.namespaces.push(value.metadata.name);
            });

            if (this.namespaces.length > 0) {
              // Mise dans le localstorage du namespace
              if (
                  localStorage.getItem("namespace") &&
                  localStorage.getItem("namespace") !== "undefined"
              ) {
                this.namespace = localStorage.getItem("namespace");
              } else {
                this.namespace = this.namespaces[0];
                localStorage.setItem("namespace", this.namespace);
              }

              this.$store.dispatch(
                  SET_SELECTED_NAMESPACE,
                  this.namespaces.indexOf(this.namespace)
              );

              // Chargement des entreprises
              this.getCompanies(false);
            }
          })
          .catch(() => {
            if (!refreshed) {
              // Refresh du token
              // Evite les bugs en itg de unauthorized, si le token est expiré mais pas le refresh
              this.$store.dispatch(AUTH_REFRESH_TOKEN).then(() => {
                this.loadNamespace(true);
              });
            }
          });
    } else {
      // Utilisateur classique
      // Chargement des entreprises
      this.getCompanies(false);
    }
  }

  public getCompanies(refreshed: boolean): void {
    axios
        .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/company`)
        .then((response) => {
          if (response.data != null) {
            for (let data in response.data) {
              if (!response.data[data].startsWith('nadmin_')) {
                this.entreprises.push(response.data[data]);
              }
            }

            let index = this.entreprises.indexOf("default-roles-elosi");

            if (index === -1) {
              index = this.entreprises.indexOf("default-roles-autoscaling-realm");
            }

            if (index !== -1) {
              this.entreprises.splice(index, 1);
            }

            // Si pas admin, on dois mettre l'entreprise dans le local storage
            if (!localStorage.getItem("namespace")) {
              this.namespace = this.entreprises[0];
              localStorage.setItem("namespace", this.namespace);
            }

            // Si utilisateur classique le namespace n'est pas définie
            if (this.namespace === "undefined" || this.namespace.length === 0) {
              this.namespace = localStorage.getItem("namespace");
            }

            // En admin on peut ne pas etre dans l'entreprise ciblée
            if (!this.entreprises.includes(this.namespace)) {
              this.namespace = this.entreprises[0];
              localStorage.setItem("namespace", this.namespace);
              this.$store.dispatch(
                  SET_SELECTED_NAMESPACE,
                  this.namespaces.indexOf(this.namespace)
              );
            }

            // Pour l'écran des entreprises
            this.$store.dispatch(SET_ENTREPRISE, this.namespace);

            this.$store.dispatch(
                SET_SELECTED_ENTREPRISE,
                this.entreprises.indexOf(this.namespace)
            );

            this.$store.dispatch(
                SET_HAVE_ENTERPRISE,
                this.entreprises.length !== 0
            );
          }
        })
        .catch(() => {
          if (!refreshed) {
            // Refresh du token
            // Evite les bugs en itg de unauthorized, si le token est expiré mais pas le refresh
            this.$store.dispatch(AUTH_REFRESH_TOKEN).then(() => {
              this.getCompanies(true);
            });
          }
        });

    this.viewKey += 1;
    this.loadingApp = false;
  }

  public updateNamespace(db: boolean): void {
    if (db) {
      axios
          .put(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/namespace/${this.namespace}`
          )
    }

    // Update entreprise
    if (this.entreprises.includes(this.namespace)) {
      this.$store.dispatch(SET_ENTREPRISE, this.namespace);
      this.$store.dispatch(
          SET_SELECTED_ENTREPRISE,
          this.entreprises.indexOf(this.namespace)
      );
    }

    // Rechargement
    this.viewKey += 1;
    this.loadingApp = false;
  }

  public updateEntreprise(): void {
    // Pour l'écran des entreprises
    this.$store.dispatch(SET_ENTREPRISE, this.namespace);

    // Update le namespace
    if (this.namespaces.length > 0) {
      this.$store.dispatch(
          SET_SELECTED_NAMESPACE,
          this.namespaces.indexOf(this.namespace)
      );
    }

    // Rechargement
    this.viewKey += 1;
    this.loadingApp = false;
  }

  @Watch("userData")
  onUserDataChange(newV: any, oldV: any) {
    if (newV && newV.sub !== oldV.sub) {
      this.hasInvitation();
      this.loadNamespace(false);
    }
  }

  @Watch("selectedEntreprise")
  onSelectedEntrepriseChange(newV: any, oldV: any) {
    if (newV !== oldV) {
      this.namespace = this.entreprises[newV];
      localStorage.setItem("namespace", this.namespace);
      this.updateEntreprise();
    }
  }

  @Watch("selectedNamespace")
  onSelectedNamespaceChange(newV: any, oldV: any) {
    if (newV !== oldV) {
      this.namespace = this.namespaces[newV];
      localStorage.setItem("namespace", this.namespace);
      this.updateNamespace(false);
    }
  }
}
