import axios from "axios";
import * as ACTIONS from "../../actions/pricing/const_pricing";

const actions = {
  // eslint-disable-next-line no-unused-vars
  [ACTIONS.PRICING_REQUEST]: ({commit}: any) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/pricing`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        });
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.PRICING_UPDATE]: ({commit}: any, pricing: any) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/pricing`, pricing
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        });
    });
  }
}

export default {
  actions
};
