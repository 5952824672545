

























































import Vue from "vue";

import {Component} from "vue-property-decorator";

const axios = require("axios");

@Component({})
export default class Action extends Vue {
  dialog: boolean = true;
  loading: boolean = true;
  checked: boolean = false;
  error: string = "";
  success: string = "";

  public mounted(): void {
    this.validate();
  }

  public validate(): void {
    const i = this;
    if (i.$route.query.actionToken) {
      delete axios.defaults.headers.common["Authorization"];

      axios
          .get(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/authentication?actionToken=${i.$route.query.actionToken}`
          )
          .then((response: any) => {
            i.checked = true;
            i.loading = false;
            i.success = response.data;
          })
          .catch((error: any) => {
            i.error = error.response.data.message;
            i.loading = false;
          });
    } else {
      i.loading = false;
      i.$router.push({path: "/"}).catch(() => {});
    }
  }
}
