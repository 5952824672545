var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"700px","min-width":"360px","persistent":""},model:{value:(_vm.dialogTrigger),callback:function ($$v) {_vm.dialogTrigger=$$v},expression:"dialogTrigger"}},[_c('v-card',[_c('v-card-title',{staticClass:"justify-center"},[_vm._v(" "+_vm._s(this.$t("newUserTicket.header").toUpperCase())+" ")]),(_vm.error)?_c('v-card-text',[_c('v-alert',{attrs:{"dense":"","dismissible":"","prominent":"","type":"error"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.errorMessage))+" ")])],1):_vm._e(),(_vm.success)?_c('v-card-text',[_c('v-alert',{attrs:{"dense":"","dismissible":"","prominent":"","type":"success"},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.successMessage))+" ")])],1):_vm._e(),_c('v-card-text',[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('v-form',{staticClass:"pt-5",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"mode":"aggressive","name":"Sujet","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('newEntrepriseTicket.sujet'),"color":"secondary"},model:{value:(_vm.sujet),callback:function ($$v) {_vm.sujet=$$v},expression:"sujet"}})]}}],null,true)})],1),(_vm.haveApp)?_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"items":_vm.applications,"label":_vm.$t('newEntrepriseTicket.label')},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.nomApplication)+" ")]}},{key:"item",fn:function(data){return [_vm._v(" "+_vm._s(data.item.nomApplication)+" ")]}}],null,true),model:{value:(_vm.applicationSelected),callback:function ($$v) {_vm.applicationSelected=$$v},expression:"applicationSelected"}})],1):_vm._e(),(_vm.haveApp)?_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"mode":"aggressive","name":"Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"error-messages":errors,"items":_vm.typeTicket,"color":"secondary","label":"Type"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})]}}],null,true)})],1):_vm._e(),(_vm.haveApp)?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"mode":"aggressive","name":"Message","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"color":"secondary","label":"Message"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}})]}}],null,true)})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-file-input',{attrs:{"label":_vm.$t('newEntrepriseTicket.addScreenshot'),"rules":[
                  function (value) { return !value ||
                    value.size < 2000000 ||
                    'Le screenshot ne peut pas dépasser 2MB!'; } ],"accept":"image/png, image/jpeg, image/bmp","placeholder":"Ajouter un screenshot","prepend-icon":"mdi-camera","show-size":""},on:{"change":_vm.addScreenshot},model:{value:(_vm.screenshotUploaded),callback:function ($$v) {_vm.screenshotUploaded=$$v},expression:"screenshotUploaded"}})],1),_c('v-col',{staticClass:"d-flex justify-space-around",attrs:{"cols":"12"}},_vm._l((_vm.screenshotUrls),function(item,i){return _c('div',{key:i},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"alt":i,"src":item.url,"width":"200"},on:{"click":function($event){return _vm.zoomImage(item.url)}}}),_c('div',{staticStyle:{"position":"relative"}},[_c('v-btn',{attrs:{"absolute":"","color":"red","dark":"","fab":"","right":"","top":"","x-small":""},on:{"click":function($event){return _vm.deleteScreen(item)}}},[_c('v-icon',[_vm._v("delete")])],1)],1)])}),0),_c('v-dialog',{attrs:{"width":"80%"},model:{value:(_vm.zoomDialog),callback:function ($$v) {_vm.zoomDialog=$$v},expression:"zoomDialog"}},[_c('img',{attrs:{"src":_vm.screenshotZoom,"alt":"zoom"}})]),(_vm.haveApp)?_c('v-col',{attrs:{"cols":"6 d-flex justify-center"}},[_c('v-btn',{staticClass:"success",attrs:{"disabled":invalid,"type":"submit"}},[_vm._v(_vm._s(_vm.$t("newEntrepriseTicket.envoyer"))+" ")])],1):_vm._e(),_c('v-col',{attrs:{"cols":"6 d-flex justify-center"}},[_c('v-btn',{staticClass:"error ml-5",attrs:{"type":"button"},on:{"click":function($event){_vm.dialogTrigger = false}}},[_vm._v(_vm._s(_vm.$t("newEntrepriseTicket.fermer"))+" ")])],1)],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }