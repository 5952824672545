



































































import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import SearchApp from "@/components/SearchApp.vue";
import SupportUser from "@/components/account/support/SupportUser.vue";
import SupportCompany from "@/components/account/support/SupportCompany.vue";
import TicketDetailsUser from "@/components/account/support/TicketDetailsUser.vue";
import TicketDetailsEntreprise from "@/components/account/support/TicketDetailsEntreprise.vue";
import {TranslateResult} from "vue-i18n";

@Component({
  components: {
    SearchApp,
    SupportUser,
    SupportCompany,
    TicketDetailsUser,
    TicketDetailsEntreprise,
  },
})
export default class Support extends Vue {
  @Prop({}) isAdmin: boolean | undefined;

  companyName: string = "";

  loading: boolean = true;

  showTicketDetailsUser: boolean = false;
  showTicketDetailsEntreprise: boolean = false;

  ticket: any = {};

  search: string = "";

  selected: TranslateResult = "";

  get typeRecherche(): TranslateResult[] {
    return [
      this.$t("support.id"),
      this.$t("support.utilisateur"),
      this.$t("support.sujet"),
      this.$t("support.type"),
      this.$t("support.app"),
      this.$t("support.nomApp"),
      this.$t("support.entreprise"),
      this.$t("support.status"),
    ];
  }

  get typeRechercheSelected(): TranslateResult {
    return this.selected;
  }

  set typeRechercheSelected(newValue: TranslateResult) {
    this.selected = newValue;
  }

  public mounted(): void {
    this.companyName = this.$store.getters.entreprise;
    this.typeRechercheSelected = this.$t("support.id");
  }

  public detailsTicketUser(ticket: {}): void {
    this.showTicketDetailsUser = true;
    this.showTicketDetailsEntreprise = false;
    this.ticket = ticket;
  }

  public detailsTicketEntreprise(ticket: {}): void {
    this.showTicketDetailsUser = false;
    this.showTicketDetailsEntreprise = true;
    this.ticket = ticket;
  }

  public updateSearchTicket(search: string): void {
    this.search = search;
  }

  @Watch("typeRechercheSelected")
  onTypeRechercheSelectedChange() {
    this.updateSearchTicket(this.search);
  }
}
