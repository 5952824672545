import axios from "axios";
import * as ACTIONS from "../../actions/support/const_entreprise_ticket";

const moment = require("moment");

const actions = {
  // eslint-disable-next-line no-unused-vars
  [ACTIONS.ENTREPRISE_ALL_TICKET_REQUEST]: ({commit}: any, nomEntreprise: any) => {
    return new Promise((resolve, reject) => {
      if (nomEntreprise) {
        axios
          .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/entreprise/${nomEntreprise}`
          )
          .then(response => {
            const tickets: any[] = [];

            moment.locale("fr");

            response.data.forEach((data: any) => {
              tickets.push({
                id: data.id,
                entreprise: data.entreprise,
                sujet: data.sujet,
                supportType: data.supportType,
                application: data.application,
                nomApplication: data.nomApplication,
                date: moment(data.date).calendar(),
                timestamp: moment(data.date).format("x"),
                ticketStatus: data.ticketStatus,
              });
            });

            resolve(tickets);
          })
          .catch(error => {
            if (error.response) {
              reject(error.response.data.message);
            } else if (error.request) {
              reject(error.request);
            }
            reject(error.message);
          });
      } else {
        axios
          .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/entreprise`
          )
          .then(response => {
            const tickets: any[] = [];

            moment.locale("fr");

            if (response.data) {
              response.data.forEach((data: any) => {
                tickets.push({
                  id: data.id,
                  entreprise: data.entreprise,
                  sujet: data.sujet,
                  supportType: data.supportType,
                  application: data.application,
                  nomApplication: data.nomApplication,
                  date: moment(data.date).calendar(),
                  timestamp: moment(data.date).format("x"),
                  ticketStatus: data.ticketStatus,
                });
              });
            }

            resolve(tickets);
          })
          .catch(error => {
            if (error.response) {
              reject(error.response.data.message);
            } else if (error.request) {
              reject(error.request);
            }
            reject(error.message);
          });
      }
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.ENTREPRISE_TICKET_REQUEST]: ({commit}: any, idTicket: any) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/entreprise/id/${idTicket}`
        )
        .then((response) => {
          const messages: any[] = [];

          response.data.messagesSupportList.forEach((msg: any) => {
            messages.push({
              message: msg.message,
              auteur: msg.auteur,
              date: moment(msg.date).calendar(),
            });
          });

          const ticket = {
            id: response.data.id,
            entreprise: response.data.entreprise,
            sujet: response.data.sujet,
            supportType: response.data.supportType,
            application: response.data.application,
            nomApplication: response.data.nomApplication,
            date: moment(response.data.date).calendar(),
            lastModification: moment(response.data.lastModification).calendar(),
            ticketStatus: response.data.ticketStatus,
            messagesSupportList: messages,
            screenshots: response.data.screenshotsString,
          };

          resolve(ticket);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        })
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.ENTREPRISE_TICKET_NEW_MESSAGE]: ({commit}: any, data: any) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/entreprise/id/${data.ticketId}/message`, data.message
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        })
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.ENTREPRISE_NEW_SCREENSHOT]: ({commit}: any, data: any) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/entreprise/${data.ticketId}/screenshot`, data.screenshot, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        })
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.ENTREPRISE_UPDATE_TICKET_STATUS]: ({commit}: any, data: any) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/entreprise/id/${data.ticketId}/status/${data.ticketStatus}`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        })
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.ENTREPRISE_NEW_TICKET]: ({commit}: any, data: any) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/entreprise/`, data
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        })
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.ENTREPRISE_APPLICATIONS_REQUEST]: ({commit}: any, namespace: string) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_SPRING_API_BASEURL
          }/api/services/clusters/default/namespaces/${namespace}/releases`
        )
        .then((response) => {
          const applications: any[] = [];

          response.data.data.forEach((app: any) => {
            applications.push({
              application: app.chart,
              nomApplication: app.releaseName,
            });
          });

          resolve(applications);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        })
    });
  },
}

export default {
  actions
};
