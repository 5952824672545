

























import Vue from "vue";
import {TranslateResult} from "vue-i18n";

import {Component, Prop} from "vue-property-decorator";

@Component({})
export default class StatsGenerales extends Vue {
  @Prop({}) stats: any;

  statsGenerales: {
    stat: string | number;
    texte: TranslateResult;
    couleur: string;
    icon: string;
  }[] = [];

  public mounted(): void {
    this.initStatsGenerales();
  }

  public initStatsGenerales(): void {
    this.statsGenerales = [
      {
        stat: this.stats.version,
        texte: this.$t("statsGenerales.version"),
        couleur: "success",
        icon: "mdi-numeric",
      },
      {
        stat: this.stats.keycloakStats.accountNb,
        texte: this.$t("statsGenerales.compteActif"),
        couleur: "info",
        icon: "mdi-account-multiple",
      },
      {
        stat: this.stats.backStats.totalUserAllTime,
        texte: this.$t("statsGenerales.compteTotal"),
        couleur: "info",
        icon: "mdi-account-multiple",
      },
      {
        stat: this.stats.backStats.applicationNb,
        texte: this.$t("statsGenerales.appActive"),
        couleur: "warning",
        icon: "mdi-application-brackets",
      },
      {
        stat: this.stats.backStats.totalAppAllTime,
        texte: this.$t("statsGenerales.appTotal"),
        couleur: "warning",
        icon: "mdi-application-brackets",
      },
    ];
  }
}
