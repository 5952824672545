var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","right":"","temporary":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item-group',[_c('v-list-item',{on:{"click":function($event){return _vm.darkMode()}}},[_c('v-list-item-title',[(_vm.$vuetify.theme.dark)?_c('v-icon',[_vm._v("mdi-white-balance-sunny ")]):_c('v-icon',[_vm._v(" mdi-weather-night ")])],1)],1),_c('v-list-item',{attrs:{"to":"/account/infos"}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("appbar.compte")))])],1),_c('v-list-item',{on:{"click":function($event){return _vm.logout()}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t("appbar.deconnexion"))+" ")])],1)],1)],1)]},proxy:true}]),model:{value:(_vm.mobileNav),callback:function ($$v) {_vm.mobileNav=$$v},expression:"mobileNav"}},[_vm._l((_vm.links),function(link){return _c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(
      link.requiredAdmin === false ||
        (link.requiredAdmin === true &&
          _vm.userData.realm_access &&
          _vm.userData.realm_access.roles.indexOf('ADMIN') !== -1)
    ),expression:"\n      link.requiredAdmin === false ||\n        (link.requiredAdmin === true &&\n          userData.realm_access &&\n          userData.realm_access.roles.indexOf('ADMIN') !== -1)\n    "}],key:link.name,staticClass:"d-none d-lg-flex",attrs:{"to":link.url,"text":""}},[_vm._v(" "+_vm._s(link.name)+" ")])}),_c('v-list',{attrs:{"dense":"","nav":""}},[_c('v-list-item-group',[_vm._l((_vm.links),function(link){return _c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(
          link.requiredAdmin === false ||
            (link.requiredAdmin === true &&
              _vm.userData.realm_access &&
              _vm.userData.realm_access.roles.indexOf('ADMIN') !== -1)
        ),expression:"\n          link.requiredAdmin === false ||\n            (link.requiredAdmin === true &&\n              userData.realm_access &&\n              userData.realm_access.roles.indexOf('ADMIN') !== -1)\n        "}],key:link.name,attrs:{"to":link.url}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t(link.name)))])],1)}),(
          _vm.userData.realm_access &&
            _vm.userData.realm_access.roles.includes('ADMIN')
        )?_c('AppBarDropdownMobile',{attrs:{"data-list":_vm.namespaces,"name":'appbar.namespaces'}}):_vm._e(),(_vm.entreprises.length > 1)?_c('AppBarDropdownMobile',{attrs:{"data-list":_vm.entreprises,"name":'appbar.entreprises'}}):_vm._e()],2)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }