
















































































































































import axios from "axios";
import Alert from "@/components/alert/Alert.vue";
import MaterialCard from "@/components/material/MaterialCard.vue";
import AppCard from "@/components/app/AppCard.vue";
import FormConfiguration from "@/components/deploiement/FormConfiguration.vue";
// @ts-ignore
import {v4 as uuidv4} from "uuid";
import {SET_APPLICATION_VALUES} from "@/store/actions/data";
import {Component, Vue} from "vue-property-decorator";

@Component({
  components: {
    MaterialCard,
    AppCard,
    FormConfiguration,
    Alert
  }
})
export default class Deploiement extends Vue {
  public chart: any = []
  public error: boolean = false
  public errorMessage: String = ""
  public items: any = []
  public datas: any = []
  public versions: any = []
  public version: any = []
  public nom: String = ""
  public valid: boolean = true
  public loading: boolean = false

  public deployementPlan: any = {name: "deploiement.basic", ratio: 1}
  public limits: any = new Map()

  get deployementPlans() {
    return [
      {name: "deploiement.basic", ratio: 1},
      {name: "deploiement.avance", ratio: 1.5},
      {name: "deploiement.premium", ratio: 2},
    ]
  }

  get nomRegle() {
    return [
      (v: string) => v.length <= 50 || 'Maximum 50 caractères',
      (v: any) => !!v || "Le nom est requis",
      (v: any) =>
          /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/.test(
              v
          ) || "Le nom du service est incorrect !",
    ]
  }

  get URL() {
    return process.env.VUE_APP_SPRING_API_BASEURL
  }

  get userData() {
    return this.$store.getters.userData;
  }

  public beforeMount() {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/assets/default/${this.$route.params.namespace}/charts/${this.$route.params.repo}`
        )
        .then((response) => {
          this.chart = response.data.availablePackageDetail;

          for (let i = 0; i < this.chart[0].application.userConfig.length; i++) {
            this.items.push(FormConfiguration);

            switch (this.chart[0].application.userConfig[i].key) {
              case "resources.limits.memory":
                this.limits.set(
                    this.chart[0].application.userConfig[i].key,
                    this.chart[0].application.userConfig[i].value.split("Mi")[0]
                );
                break;
              case "resources.limits.cpu":
                this.limits.set(
                    this.chart[0].application.userConfig[i].key,
                    this.chart[0].application.userConfig[i].value.split("m")[0]
                );
                break;
            }
          }

          this.loadVersions();
          this.generateName();
        })
        .catch(() => {
          this.error = true;
        });
  }

  public async remove() {
    this.loading = true;
    await new Promise((resolve) => setTimeout(resolve, 3000));
    this.loading = false;
  }

  public loadVersions() {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/assets/default/${this.$route.params.namespace}/charts/${this.$route.params.repo}/versions`
        )
        .then((response) => {
          this.versions = response.data.packageAppVersions;
          this.version = this.versions[0].appVersion;
        })
        .catch(() => {
          this.chart = null;
        });
  }

  public updateConfiguration(conf: any, n: any) {
    if (this.datas[n] === undefined) this.datas[n] = {};
    this.datas[n] = conf;
  }

  public submit() {
    this.valid = (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.valid) {
      this.loading = true;

      let values = "";
      this.datas.forEach((value: any) => {
        switch (value.key) {
          case "resources.limits.memory":
            values += `value.key=${
                Math.round(
                    this.deployementPlan.ratio * this.limits.get(value.key) * 100
                ) / 100
            }Mi\n`;
            value.value = `${
                this.deployementPlan.ratio * this.limits.get(value.key)
            }Mi`;
            break;
          case "resources.limits.cpu":
            values += `${value.key}=${
                this.deployementPlan.ratio * this.limits.get(value.key)
            }m\n`;
            value.value = `
                ${this.deployementPlan.ratio * this.limits.get(value.key)}m`;
            break;
          default:
            values += `${value.key}=${value.value}\n`;
        }
      });

      // On met ** pour échapper le . (remplacer dans le back dans releaseCreatorProperties)
      values +=
          `podAnnotations.fluentbit**io/logstash_prefix=${this.nom}\n`;

      axios
          .get(// @ts-ignore
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/gandi/${this.nom}.app${window.location.origin.includes("itg") ? "-itg" : ""}`
          )
          .then(() => {
            this.error = true;
            this.errorMessage = "deploiement.exist";
          })
          .catch(() => {
            this.$store.dispatch(SET_APPLICATION_VALUES, {
              name: this.nom,
              plan: this.deployementPlan.name,
              values: values,
              pricing: this.deployementPlan.ratio,
              configuration: this.datas,
              chart: this.chart[0],
              version: this.version,
            });

            this.$router.push({name: "Panier"}).catch(() => {});
          })
          .finally(() => {
            this.loading = false;
          });
    }
  }

  public generateName() {
    const chartName = this.$route.params.repo.split('/')[1];
    this.nom = `${localStorage.getItem("namespace")}-${chartName}-${
        uuidv4().split("-")[0]
    }`;
  }
}
