
































































































































import Vue from "vue";

import {Component, Watch} from "vue-property-decorator";

import axios from "axios";
import {ValidationObserver, ValidationProvider} from "vee-validate";

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
  },
})
export default class InfoCompany extends Vue {
  error: boolean = false;
  errorMessage: string = "";
  success: boolean = false;
  successMessage: string = "";

  loading: boolean = true;
  saveLoading: boolean = false;

  entreprise: any;

  adresse: string = "";
  ville: string = "";
  codePostal: string = "";

  get userData() {
    return this.$store.getters.userData;
  }

  get observer(): Vue & { validate: () => boolean } {
    return this.$refs.observer as Vue & { validate: () => boolean };
  }

  get companyName() {
    return this.$store.getters.entreprise;
  }

  public mounted(): void {
    if (this.companyName) {
      this.initialize();
    }
  }

  public initialize(): void {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/entreprises/${this.companyName}`
        )
        .then((response) => {
          this.entreprise = response.data;
          this.adresse = this.entreprise.adresse;
          this.ville = this.entreprise.ville;
          this.codePostal = this.entreprise.codePostal;
          this.loading = false;
        })
        .catch((error) => {
          if (error.response.data) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = "Une erreure est survenue";
          }
          this.error = true;
          this.loading = false;
        });
  }

  public dateInformations(): void {
    this.observer.validate();
    this.saveLoading = true;

    axios
        .put(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/entreprises`, {
          id: this.entreprise.id,
          nom: this.entreprise.nom,
          dateCreation: this.entreprise.dateCreation,
          adresse: this.adresse,
          ville: this.ville,
          codePostal: this.codePostal,
        })
        .then((response) => {
          this.successMessage = response.data;
          this.success = true;
          this.error = false;
          this.saveLoading = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
          } else {
            this.errorMessage = "Une erreure est survenue";
          }
          this.error = true;
          this.saveLoading = false;
        });
  }

  @Watch("companyName")
  onCompanyNameChange(newV: any) {
    if (newV !== undefined) this.initialize();
  }
}
