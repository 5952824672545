






import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class Alert extends Vue {
  @Prop({type: String}) message: String | undefined
}
