
































































import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import {USER_NEW_SCREENSHOT, USER_TICKET_NEW_MESSAGE} from "@/api/actions/support/const_user_ticket";
import {ENTREPRISE_NEW_SCREENSHOT, ENTREPRISE_TICKET_NEW_MESSAGE} from "@/api/actions/support/const_entreprise_ticket";

@Component({})
export default class TicketReponse extends Vue {
  @Prop({}) id: string | undefined;
  @Prop({}) isUser: boolean | undefined;
  @Prop({}) isAdmin: boolean | undefined;

  reponse: string = "";
  disabledBtn: boolean = true;
  loading: boolean = false;

  screenshotUploaded: any = null;
  screenshotUrls: { file: File, url: string }[] = [];
  screenshotList: File[] = [];
  screenshotZoom: string = "";

  zoomDialog: boolean = false;

  get userData() {
    return this.$store.getters.userData;
  }

  public sendReponse(): void {
    this.loading = true;

    const message = {
      auteur: this.isAdmin
          ? `admin_${this.userData.preferred_username}`
          : this.userData.preferred_username,
      message: this.reponse,
    };
    const data = {ticketId: this.id, message: message};

    const target = this.isUser
        ? USER_TICKET_NEW_MESSAGE
        : ENTREPRISE_TICKET_NEW_MESSAGE;

    this.$store
        .dispatch(target, data)
        .then((res) => {
          this.reponse = "";

          if (this.screenshotList.length > 0) {
            const apiTarget = this.isUser ? USER_NEW_SCREENSHOT : ENTREPRISE_NEW_SCREENSHOT;

            const formData = new FormData();

            this.screenshotList.forEach((screen) => {
              formData.append("screenshot", screen);
            });

            this.$store.dispatch(apiTarget, {
              ticketId: this.id,
              screenshot: formData
            }).finally(() => {
              this.$emit("refreshTicket", res);
            });

            this.screenshotUrls = [];
            this.screenshotList = [];
            this.screenshotUploaded = null;
          } else {
            this.$emit("refreshTicket", res);
          }
        })
        .catch((error) => {
          this.$emit("errorTicket", error);
        })
        .finally(() => (this.loading = false));
  }

  public addScreenshot(e: any): void {
    this.screenshotList.push(e);
    this.screenshotUrls.push({file: e, url: URL.createObjectURL(e)});
  }

  public zoomImage(file: string): void {
    this.screenshotZoom = file;
    this.zoomDialog = true;
  }

  public deleteScreen(screen: { file: File, url: string }): void {
    const indexList = this.screenshotList.indexOf(screen.file);

    // L'index dans le tableau screenshotList sera toujours identique à celui dans screenshotUrls
    if (indexList !== -1) {
      this.screenshotList.splice(indexList, 1);
      this.screenshotUrls.splice(indexList, 1);
    }
  }

  @Watch("reponse")
  onReponseChange(newV: string) {
    this.disabledBtn = newV.length <= 0;
  }
}
