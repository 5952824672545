








































































































































































































import Vue from "vue";

import {Component, Prop} from "vue-property-decorator";

import {AUTH_CREATE_ACCOUNT, AUTH_CREATE_ACCOUNT_AND_JOIN,} from "@/store/actions/auth";
import {confirmed, email, max, min, regex, required,} from "vee-validate/dist/rules";
import {extend, setInteractionMode, ValidationObserver, ValidationProvider,} from "vee-validate";
import DialogConfirmation from "@/components/dialogue/DialogConfirmationComponent.vue";
import {VuetifyThemeItem} from "vuetify/types/services/theme";

const {AUTH_PROVIDER} = require("@/store/actions/auth");

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} ne peut pas être vide",
});
extend("confirmed", {
  ...confirmed,
  message: "{_field_} ne correspond pas",
});
extend("min", {
  ...min,
  message: "{_field_} doit avoir au moins {length} caractères",
});
extend("max", {
  ...max,
  message: "{_field_} doit avoir au maximum {length} caractères",
});
extend("regex", {
  ...regex,
  message: "{_field_} {_value_} ne respecte pas la règle {regex}",
});
extend("email", {
  ...email,
  message: "L'email doit être valide",
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
    DialogConfirmation,
  },
})
export default class Signup extends Vue {
  @Prop({type: Object}) token: any;

  icon: VuetifyThemeItem = "";
  nom: string = "";
  prenom: string = "";
  username: string = "";
  mail: string = "";
  companyName: string = "";
  password: string = "";
  confirmPassword: string = "";
  cgu: boolean = false;
  passwordView: boolean = true;
  passwordViewConfirm: boolean = true;
  disable: boolean = false;
  error: boolean = false;
  errorMessage: string = "";
  dialog: boolean = true;
  loadingSignup: boolean = false;
  provider: boolean = false;

  get observer(): Vue & { validate: () => boolean } {
    return this.$refs.observer as Vue & { validate: () => boolean };
  }

  get confirm(): DialogConfirmation {
    // Obligatoire sinon il ne reconnait pas la méthode open de DialogConfirmation
    return this.$refs.confirm as DialogConfirmation;
  }

  public mounted(): void {
    if (!this.$vuetify.theme.dark) {
      this.icon = '/logo_autoscaling.svg';
    } else {
      this.icon = '/logo_autoscaling_dark.svg';
    }

    if (this.token) {
      this.provider = true;
      this.mail = this.token.email;
      this.nom = this.token.family_name;
      this.prenom = this.token.given_name;
      this.username = this.token.preferred_username;
    }
  }

  public submit(): void {
    this.observer.validate();
    this.loadingSignup = true;

    const {username, nom, prenom, companyName, mail, password, provider} =
        this;

    this.$store
        .dispatch(AUTH_CREATE_ACCOUNT, {
          username,
          nom,
          prenom,
          companyName,
          mail,
          password,
          provider,
        })
        .then(() => {
          if (provider) {
            const token = this.$route.fullPath.split("&")[1].split("=")[1];
            this.$store.dispatch(AUTH_PROVIDER, {token}).then(() => {
              this.$router.push({name: "Applications"}).catch(() => {});
            });
          } else {
            this.$router.push({
              path: "/login",
              query: {success: 'true'},
            }).catch(() => {});
          }
        })
        .catch(async (error) => {
          if (error.response.status === 409) {
            if (
                await this.confirm.open(
                    "Confirmation",
                    `La société ${this.companyName} existe déjà ! Voulez-vous effectuer une demande pour la rejoindre ?`,
                    null
                )
            ) {
              this.$store
                  .dispatch(AUTH_CREATE_ACCOUNT_AND_JOIN, {
                    username,
                    nom,
                    prenom,
                    companyName,
                    mail,
                    password,
                    provider,
                  })
                  .then(() => {
                    if (provider) {
                      const token = this.$route.fullPath
                          .split("&")[1]
                          .split("=")[1];
                      this.$store.dispatch(AUTH_PROVIDER, {token}).then(() => {
                        this.$router.push({name: "Applications"}).catch(() => {});
                      });
                    } else {
                      this.$router.push({
                        path: "/login",
                        query: {success: 'true'},
                      }).catch(() => {});
                    }
                  })
                  .catch(async (error2) => {
                    this.error = true;
                    this.errorMessage = error2.response.data.errorMessage
                        ? error2.response.data.errorMessage
                        : error2.response.data.message;
                  });
            } else {
              this.loadingSignup = false;
            }
          } else {
            this.loadingSignup = false;
            this.error = true;
            this.errorMessage = error.response.data.errorMessage
                ? error.response.data.errorMessage
                : error.response.data.message;
          }
        });
  }
}
