export default {
  // components/dialogue/DialogueConfirmationComponent.vue
  dialogConfirmation: {
    titleDelete: 'Confirmation',
    textDelete: 'Êtes-vous sûr de vouloir supprimer votre compte ? <small><b>CETTE OPERATION EST IRREVERSIBLE !</b></small>',
    annuler: 'Annuler',
    confirmer: 'Confirmer',
  },

  // components/dialogue/DialogueInputComponent.vue
  dialogInput: {
    titleCreateEntreprise: 'Création d\'entreprise',
    textCreateEntreprise: 'Vous êtes sur le point de créer une nouvelle entreprise ! <small><b>APRES CONFIRMATION CETTE OPERATION EST IRREVERSIBLE !</b></small>',
    titleJoinEntreprise: 'Rejoindre une entreprise',
    textJoinEntreprise: 'Vous êtes sur le point d\'envoyer une demande pour rejoindre une entreprise',
    entrepriseLabel: 'Nom de l\'entreprise',
    annuler: 'Annuler',
    confirmer: 'Confirmer',
  },

  // App.vue
  app: {
    activation: 'Votre compte est en attente d\'activation',
    mailActivation: 'Renvoyer le mail',
    invitation: 'Vous avez actuellement une demande en cours pour rejoindre la société {msg}',
  },

  // components/app/appBar/desktop/AppBar.vue + components/app/appBar/mobile/AppBarMobile.vue
  appbar: {
    application: 'Applications',
    catalogue: 'Catalogue',
    administration: 'Administration',
    namespaces: 'Namespaces',
    entreprises: 'Entreprises',
    compte: 'Mon compte',
    deconnexion: 'Déconnexion',
  },

  // views/Account.vue
  compte: {
    header: 'Gestion du compte',
    information: 'Mes informations',
    entreprise: 'Entreprise {msg}',
    informationEntreprise: 'Informations entreprise {msg}',
    historique: 'Historique',
    support: 'Support',
    preference: 'Mes préférences',
    session: 'Mes sessions',
    supprimer: 'Supprimer mon compte',
  },

  // views/Action.vue
  action: {
    connexion: 'Me connecter',
  },

  // views/AdminAddApplication.vue
  adminAddApp: {
    header: 'Ajouter une application dans le catalogue',
    application: 'Application',
    namespace: 'Namespace',
    namespaceHint: 'Si vide, l\'application sera disponible pour tous',
    ajouter: 'Ajouter',
    readme: 'Lisez-moi',
  },

  // components/admin/FormBuilder.vue
  formBuilder: {
    configuration: 'Configuration',
    description: 'Description',
    defaut: 'Valeur par défaut (facultatif)',
    visible: 'Visible ?',
    text: 'Text',
    nombre: 'Nombre',
    mdp: 'Mot de passe',
    boolean: 'Activer/Désactiver',
    depend: 'Clé dépendante (uniquement valeur booléenne)'
  },

  // views/AdminEditApplication.vue
  adminEditApp: {
    header: 'Modifier une application dans le catalogue',
    namespace: 'Namespace',
    namespaceHint: 'Si vide, l\'application sera disponible pour tous',
    modifier: 'Modifier',
    readme: 'Lisez-moi',
    annuler: 'Annuler'
  },

  // view/Administration.vue
  administration: {
    statistiques: 'Statistiques',
    applications: 'Applications',
    utilisateurs: 'Utilisateurs',
    entreprises: 'Entreprises',
    pricing: 'Prix',
    promotion: 'Code promotion',
    support: 'Support',
    logs: 'Journaux'
  },

  // view/Applications.vue
  applications: {
    showAll: 'Afficher les applications dans tous les espaces de noms',
    noApp: 'Aucune application n\'est déployée pour le moment',
    deployer: 'Déployer ici'
  },

  // view/Catalogue.vue
  catalogue: {
    filtres: 'Filtres',
    noService: 'Aucun service n\'est déployé pour le moment. Contactez l\'<strong>ADMIN</strong> afin de <strong>résoudre</strong> le problème.',
    recherche: 'Rechercher une application',
    noApp: 'Aucune application n\'est déployée pour le moment',
  },

  // view/Deploiement.vue
  deploiement: {
    header: 'Configuration - <small class="text-body-1">Déployez avec vos propres configurations</small>',
    nom: 'Nom',
    version: 'Sélection de la version',
    basic: 'Basique',
    avance: 'Avancé',
    premium: 'Premium',
    deploiementLabel: 'Sélection du plan de déploiement',
    commander: 'Commander',
    exist: "Une application possède déjà le même nom"
  },

  // views/ForgotPassword.vue
  forgotPassword: {
    header: 'Entrez l\'adresse e-mail de votre compte utilisateur et nous vous enverrons un lien de réinitialisation de mot de passe',
    email: 'Adresse email',
    envoyer: 'Envoyer le lien de réinitialisation',
    retour: 'Retour',
  },

  // views/Login.vue
  login: {
    header: 'Bienvenue sur le portail de connexion. Veuillez vous connecter pour profiter de nos services.',
    username: 'Nom d\'utilisateur',
    mdp: 'Mot de passe',
    mdpLost: 'Mot de passe oublié ?',
    connexion: 'Connexion',
    inscription: 'Inscription',
    google: 'Continuer avec Google',
    github: 'Continuer avec GitHub',
  },

  // views/NoEntreprise.vue
  noEntreprise: {
    title: 'Oh non :(',
    subtitle: 'Vous n\'êtes pour le moment dans aucune entreprise...',
    message: 'Afin de corriger cela cliquez sur un des boutons ci-dessous',
  },

  // components/error/ErrorComponent.vue
  errorComponent: {
    accueil: 'Accueil',
    creer: 'Créer une entreprise',
    rejoindre: 'Rejoindre une entreprise',
    supprimer: 'Supprimer mon compte'
  },

  // view/NotFoundPage.vue
  notFound: {
    subtitle: 'Oh oh! On dirait que vous êtes perdu !',
    message: 'La page que vous recherchez n\'existe pas. Comment vous êtes arrivé ici est un mystère. Mais vous pouvez cliquer sur le bouton ci-dessous pour revenir à la page d\'accueil.'
  },

  // view/Error50x/Error50x.vue
  error50x: {
    subtitle: 'Oh oh! On dirait qu\'il y a une erreur !',
    500: 'Il y a une erreur interne',
    502: 'La passerelle a reçu une réponse invalide',
    503: 'Le service n\'est pas disponible pour le moment',
    504: 'Le serveur n\'a pas répondu à temps',
  },

  // view/Panier.vue
  panier: {
    noApp: 'Aucune application dans le panier'
  },

  // views/Signup.vue
  signup: {
    header: 'Créer un compte',
    nom: 'Nom',
    prenom: 'Prénom',
    username: 'Nom d\'utilisateur',
    mail: 'Adresse email',
    entreprise: 'Entreprise',
    mdp: 'Mot de passe',
    confirmMdp: 'Confirmation mot de passe',
    cgu: 'Accepter les conditions générales d\'utilisation',
    retour: 'Retour',
    inscription: 'M\'inscrire',
  },

  // views/UpgradeConfiguration.vue
  upgrade: {
    header: 'Mise à jour - <small class="text-body-1">Mettre à jour mon service</small>',
    nom: 'Nom',
    version: 'Sélection de la version',
    update: 'Mettre à jour',
    annuler: 'Annuler'
  },

  // components/ReadmeComponent.vue
  readme: {
    fermer: 'Fermer',
  },

  // components/Release.vue
  release: {
    url: 'URL d\'accès',
    upgrade: 'Mise à niveau',
    rollback: 'Restaurer',
    eteindre: 'Éteindre',
    allumer: 'Allumer',
    aJour: 'A JOUR',
    outdated: 'MISE A JOUR DISPONIBLE {msg}',
    demarre: 'démarre',
    lancement: 'Votre application est en cours de lancement merci de patienter quelques instants. Cela ne devrait pas être trop long'
  },

  // components/account/preferences/AdvancedPreferences.vue
  advancedPreference: {
    serviceDeploy: 'Recevoir une notification lorsqu\'un nouveau service est déployé',
    serviceSupprimer: 'Recevoir une notification lorsqu\'un utilisateur supprime un service',
    update: 'Recevoir une notification lorsqu\'un utilisateur met à jour un service',
    rollback: 'Recevoir une notification lorsqu\'un utilisateur restaure un service',
    active: 'Recevoir une notification lorsqu\'un utilisateur active un service',
    desactive: 'Recevoir une notification lorsqu\'un utilisateur désactive un service',
    invitation: 'Recevoir une notification lorsqu\'un utilisateur demande a rejoindre l\'entreprise. Nécessite d\'avoir le role Administrateur dans l\'entreprise',
    rejoint: 'Recevoir une notification lorsqu\'un utilisateur rejoint l\'entreprise',
    quitte: 'Recevoir une notification lorsqu\'un utilisateur quitte l\'entreprise',
  },

  // components/account/preferences/BasicPreference.vue
  basicPreference: {
    serviceDeploy: 'Recevoir une notification lorsqu\'un nouveau service est déployé',
    serviceSupprimer: 'Recevoir une notification lorsqu\'un utilisateur supprime un service',
    updateRollback: 'Recevoir une notification lorsque\'un utilisateur met à jour/restaure un service',
    activeDesactive: 'Recevoir une notification lorsqu\'un utilisateur active/désactive un service',
    invitation: 'Recevoir une notification lorsqu\'un utilisateur demande à rejoindre l\'entreprise. Nécessite d\'avoir le role Administrateur dans l\'entreprise',
    rejointQuitte: 'Recevoir une notification lorsqu\'un utilisateur rejoint/quitte l\'entreprise',
  },

  // components/account/preferences/Preferences.vue
  preferences: {
    header: 'Mes préférences',
    enregistrer: 'Enregistrer',
    affichageAvance: 'Affichage avancé',
    aucun: 'Aucune',
    email: 'Email',
    site: 'Site',
    emailSite: 'Email + Site',
    success: 'Vos préférences ont bien été mise à jour !',
  },

  // components/account/support/NewEntrepriseTicket.vue
  newEntrepriseTicket: {
    header: 'Nouveau ticket',
    noApp: 'Aucune application déployée',
    label: 'Application',
    sujet: 'Sujet',
    envoyer: 'Envoyer le ticket',
    fermer: 'Fermer',
    addScreenshot: 'Ajouter une capture d\'écran',
  },

  // components/account/support/NewUserTicket.vue
  newUserTicket: {
    header: 'Nouveau ticket',
    envoyer: 'Envoyer le ticket',
    fermer: 'Fermer',
    sujet: 'Sujet',
    addScreenshot: 'Ajouter une capture d\'écran',
  },

  // components/account/support/Support.vue
  support: {
    header: 'Support',
    id: 'ID',
    utilisateur: 'Utilisateur',
    sujet: 'Sujet',
    type: 'Type',
    app: 'Application',
    nomApp: 'Nom de l\'application',
    entreprise: 'Entreprise',
    status: 'Status',
  },

  // components/account/support/SupportCompany.vue
  supportCompany: {
    header: 'Tickets de l\'entreprise {msg}',
    tooltip: 'Créer un nouveau ticket pour l\'entreprise',
    entreprise: 'Entreprise',
    id: 'ID',
    sujet: 'Sujet',
    type: 'Type',
    app: 'Application',
    appName: 'Nom',
    date: 'Date',
    status: 'Status',
    details: 'Détails',
  },

  // components/account/support/SupportUser.vue
  supportUser: {
    header: 'Mes tickets',
    tooltip: 'Créer un nouveau ticket',
    id: 'ID',
    utilisateur: 'Utilisateur',
    sujet: 'Sujet',
    type: 'Type',
    date: 'Date',
    status: 'Status',
    details: 'Détails',
  },

  // components/account/support/TicketDetailsUser.vue
  ticketDetailsUser: {
    header: 'Ticket : {msg}',
    details: 'Détails ticket',
    type: 'Type',
    date: 'Date',
    lastEdit: 'Dernière modification',
    status: 'Status',
  },

  // components/account/support/TicketDetailsEntreprise.vue
  ticketDetailsEntreprise: {
    header: 'Ticket : {msg}',
    entreprise: 'Entreprise',
    application: 'Application',
    nomApp: 'Nom application',
    details: 'Détails ticket',
    type: 'Type',
    date: 'Date',
    lastEdit: 'Dernière modification',
    status: 'Status',
  },

  // components/account/support/TicketDetails.vue
  ticketResponse: {
    label: 'Répondre',
  },

  // components/account/EditAccount.vue
  editAccount: {
    header: 'Editer mes informations',
    avatarLabel: 'Choisir un avatar',
    nom: 'Nom',
    prenom: 'Prénom',
    username: 'Nom d\'utilisateur',
    mail: 'Adresse email',
    mdp: 'Mot de passe actuel',
    newMdp: 'Nouveau mot de passe',
    newMdpConfirm: 'Confirmation nouveau mot de passe',
    enregistrer: 'Enregistrer',
  },

  // components/account/Factures.vue
  facture: {
    header: 'Factures',
    application: 'Application',
    nomApplication: 'Nom de l\'application',
    plan: 'Plan',
    date: 'Date',
    telecharger: 'Télécharger',
  },

  // components/account/MonthlyFactures.vue
  monthlyFacture: {
    header: 'Factures mensuelles',
    application: 'Application',
    nomApplication: 'Nom de l\'application',
    date: 'Date',
    telecharger: 'Télécharger',
  },

  // components/account/History.vue
  historique: {
    header: 'Historique',
    username: 'Nom d\'utilisateur',
    entreprise: 'Entreprise',
    date: 'Date',
    action: 'Action',
  },

  // components/account/InfoCompany.vue
  informationEntreprise: {
    header: 'Informations entreprise {msg}',
    headerAdmin: 'Editer informations {msg}',
    entrepriseLabel: 'Nom de l\'entreprise',
    adresse: 'Adresse',
    ville: 'Ville',
    codePostal: 'Code Postal',
    sauvegarder: 'Sauvegarder'
  },

  // components/account/Invitations.vue
  invitation: {
    header: 'Invitations',
    nombre: 'Nombre d\'invitation : {msg}',
    username: 'Nom d\'utilisateur',
    mail: 'Adresse email',
    date: 'Date',
    actions: 'Actions',
  },

  // components/account/Mycompany.vue
  myCompany: {
    header: 'Mon entreprise',
    tooltip: 'Créer ou rejoindre une entreprise',
    username: 'Nom d\'utilisateur',
    role: 'Rôle',
    mail: 'Adresse email',
    action: 'Action',
    admin: 'Mettre admin',
  },

  // components/account/Sessions.vue
  sessions: {
    header: 'Mes sessions',
    logout: 'Déconnecter toutes les sessions',
    ip: 'Adresse IP',
    start: 'Début de la session',
    last: 'Dernier accès à la session',
    action: 'Action'
  },

  // components/administration/entreprises/Entreprises.vue
  adminEntreprise: {
    header: 'Liste des entreprises',
    recherche: 'Rechercher une entreprise',
    nom: 'Nom de l\'entreprise',
    date: 'Date de création',
    page: 'Page entreprise',
    detailsHeader: 'Entreprise {msg}',
    detailsRetour: 'Retour',
  },

  // components/administration/logs/Logs.vue
  adminLogs: {
    header: 'Journal de l\'API',
  },

  // components/administration/pricing/Pricing.vue
  adminPricing: {
    header: 'Prix',
    cpu: 'Prix CPU (1000m)',
    ram: 'Prix RAM (1000Mi)',
    disque: 'Prix Disque',
    sauvegarder: 'Sauvegarder',
  },

  // components/administration/pricing/Kubecost.vu
  kubecost: {
    header: 'Coût des applications',
    nom: 'Nom',
    namespace: 'Namespace',
    cpu: 'CPU',
    ram: 'RAM',
    disque: 'DISQUE',
    total: 'Total',
    details: 'Details',
  },

  // components/administration/promotions/CreatePromotion.vue
  adminCreatePromotion: {
    header: 'Création d\'un code promotion',
    code: 'Code Promotion',
    reduction: 'Réduction (%)',
    debut: 'Date de début',
    fin: 'Date de fin',
    utilisationMax: 'Nombre d\'utilisations maximale',
    subtitle: 'Seul une date de fin ou un nombre maximum d\'utilisation est obligatoire',
    fermer: 'Fermer',
    creer: 'Créer',
  },

  // components/administration/promotions/Promotion.vue
  adminPromotion: {
    tooltipCreate: 'Ajouter un code de promotion',
    aVenir: 'Promotions à venir',
    enCours: 'Promotions en cours',
    fini: 'Promotions terminées',
  },

  // components/administration/promotions/PromotionTable.vue
  adminPromotionTable: {
    codeDelete: 'Êtes-vous sûr de vouloir supprimer <i>{msg}</i> ? <small><b>CETTE OPERATION EST IRREVERSIBLE !</b></small>',
    code: 'Code Promotion',
    reduction: 'Réduction (%)',
    debut: 'Date de début',
    fin: 'Date de fin',
    utilisatationsRestante: 'Utilisations restantes',
    utilisations: 'Utilisations',
    arreter: 'Arrêter',
    supprimer: 'Supprimer',
  },

  // components/administration/stats/LastEvent.vue
  lastEvent: {
    header: 'Derniers évènements sur Autoscaling',
    utilisateur: 'Nom d\'utilisateur',
    entreprise: 'Entreprise',
    date: 'Date',
    action: 'Action',
  },

  // components/administration/stats/Stats.vue
  stats: {
    header: 'Statistiques Autoscaling',
  },

  // components/administration/stats/StatsApp.vue
  statsApp: {
    header: 'Informations Applications',
    application: 'Nombre d\'application',
    lancer: 'Applications lancées',
    pause: 'Application en pause',
  },

  // components/administration/stats/StatsGenerales.vue
  statsGenerales: {
    header: 'Informations générales',
    version: 'Version',
    compteActif: 'Nombre de compte actif',
    compteTotal: 'Nombre total de compte',
    appActive: 'Nombre d\'application active',
    appTotal: 'Nombre d\'application total',
  },

  // components/administration/stats/StatsUtilisateur.vue
  statsUtilisateur: {
    header: 'Informations Utilisateurs',
    compte: 'Nombre de compte',
    verifie: 'Comptes vérifiés',
    nonVerifie: 'Comptes non vérifiés',
  },

  // components/administration/stats/StatsWeekly.vue
  statsWeeky: {
    header: 'Informations pour la semaine',
    lundi: 'Lundi',
    mardi: 'Mardi',
    mercredi: 'Mercredi',
    jeudi: 'Jeudi',
    vendredi: 'Vendredi',
    samedi: 'Samedi',
    dimanche: 'Dimanche',
    newUser: 'Utilisateurs enregistrés',
    delUser: 'Utilisateurs supprimés',
    newApp: 'Applications déployées',
    delApp: 'Applications supprimées',
  },

  // components/administration/stats/utilisateur/Utilisateurs.vue
  adminUtilisateur: {
    header: 'Liste des Utilisateurs',
    rechercheLabel: 'Rechercher un utilisateur',
    rechercheTypeLabel: 'Type de recherche',
    id: 'ID',
    mail: 'Email',
    nom: 'Nom',
    prenom: 'Prenom',
    username: 'Nom d\'utilisateur',
    date: 'Date d\'inscription',
    verifier: 'Verifier',
    activer: 'Activer/Désactiver',
    details: 'Détails',
    supprimer: 'Supprimer',
  },

  // components/administration/stats/utilisateur/UtilisateursDetails.vue
  adminUtilisateurDetails: {
    sessions: 'Sessions',
    details: 'Détails',
    id: 'ID',
    username: 'Nom d\'utilisateur',
    mail: 'Email',
    entreprises: 'Entreprises',
    noSession: 'Pas de session',
    ip: 'Adresse IP',
    start: 'Début de la session',
    last: 'Dernier accès à la session',
    action: 'Déconnexion',
    logout: 'Déconnecter',
    desactiver: 'Désactiver',
    activer: 'Activer',
    supprimer: 'Supprimer',
  },

  // components/administration/support/Support.vue
  adminSupport: {
    header: 'Support',
    rechercheLabel: 'Rechercher un ticket',
    rechercheTypeLabel: 'Type de recherche',
  },

  // components/administration/Applications.vue
  adminApplications: {
    rechercheLabel: 'Rechercher une application',
    noApp: 'Pas d\'application trouvée',
    addApp: 'Ajouter une application dans le catalogue',
    refresh: 'Rafraîchir les repositories',
    supprimer: 'Êtes-vous sûr de vouloir supprimer l\'application {msg} du catalogue ?',
    enableDisableDeploy: 'Permet d\'autoriser ou non les déploiements sur le cluster'
  },

  // components/admin/ApplicationAdmin.vue
  applicationAdmin: {
    supprimer: 'Supprimer',
  },

  // components/basket/PriceDetails.vue
  priceDetails: {
    header: 'Récapitulatif',
    adresse: 'Adresse de facturation',
    code: 'Code promotion',
    appliquer: 'Appliquer',
    htc: 'Sous-total',
    reduction: 'Réduction',
    taxe: 'Taxe',
    total: 'Total',
    order: 'Passer la commande',
    cancel: 'Annuler la commande',
  },

  // components/release/historique/Historique.vue
  appHistorique: {
    empty: 'Aucun historique sur l\'application n\'est disponible pour le moment',
    username: 'Nom d\'utilisateur',
    date: 'Date',
    action: 'Action',
    details: 'Détails',
  },

  // components/release/secrets/Secrets.vue
  appSecrets: {
    empty: 'L\'application actuelle n\'inclut pas de secrets',
    typeSecret: 'Type de mot de passe',
    secret: 'Mot de passe',
  },

  // components/release/cost/Cost.vue
  appCost: {
    label: 'Fenêtre',
    auj: 'Aujourd\'hui',
    hier: 'Hier',
    semaine: 'Semaine',
    semainePrecedente: 'Semaine dernière',
    mois: 'Mois',
  },

  // components/release/cost/CostTable.vue
  appCostTable: {
    nom: 'Nom',
    cpu: 'CPU',
    ram: 'RAM',
    disque: 'DISQUE',
    total: 'Total',
  },

  // components/release/cost/CostCharts.vue
  appCostChart: {
    cpu: 'CPU',
    ram: 'RAM',
    disque: 'DISQUE',
    total: 'TOTAL',
  },

  // components/CookieConsent.vue
  cookieConsent: {
    message: 'Autoscaling On Demand nécessite l\'utilisation des cookies pour l\'analyse ?',
    ok: 'Accepter',
    refuser: 'Refuser',
  },

  error: {
    deployDisabled: 'Les déploiements sont temporairement désactivés, veuillez contacter le support pour toutes questions.',
    cannotDelete: 'Impossible de supprimer le compte !',
    companyExist: 'Cette société existe déjà !',
    error: 'Une erreur est survenue !',
    noAuthorization: 'Vous n\'avez pas l\'autorisation de charger cet élement',
    authServerDown: 'Le serveur d\'authentification n\'est pas accessible',
    authDisable: 'Votre compte est désactivé !',
    authWrong: 'Vous avez entré le mauvais nom d\'utilisateur ou mot de passe !',
    internalError: 'Erreur interne',
    invalidAction: 'Action invalide',
    alreadyExistUser: 'Un compte avec le même nom d\'utilisateur ou e-mail existe déjà',
    alreadyValidate: 'Compte déjà validé',
    alreadySend: 'Vous avez déjà envoyé une demande a cette entreprise !',
    accountNotExist: 'Ce compte n\'existe pas !',
    noInvitation: 'Aucune invitation pour cet utilisateur',
    noApp: 'Pas d\'application',
    reductionRequired: 'Vous devez spécifier une réduction',
    reductionExpire: 'Vous devez spécifier un nombre d\'utilisation ou une date de fin',
    noData: 'Pas de données disponible',
    noAdresse: 'Commande impossible il manque une adresse de facturation',
    noPrice: 'Pas de prix enregistrer',
    emptyReduction: 'Veuillez renseigner une réduction',
    invalidPromo: 'Code promo invalide',
  },

  success: {
    updatePricing: 'Prix mis à jour',
    promoCreer: 'Code promo {msg} créer',
    promoDelete: 'Code promo {msg} supprimer',
    stopPromo: 'Code promo {msg} arreter',
    usedPromo: 'Code promo {msg} utiliser',
    ticketOuvert: 'Ticket ouvert',
    tickerFermer: 'Ticket fermer',
  }
}
