











import Vue from "vue";
import axios from "axios";

import {Component} from "vue-property-decorator";

import Signup from "@/views/Signup.vue";
import {AUTH_PROVIDER} from "@/store/actions/auth";

@Component({
  components: {
    Signup,
  },
})
export default class Callback extends Vue {
  token: string = "";
  haveCompany: boolean = false;
  loading: boolean = true;

  public beforeMount(): void {
    this.token = this.$route.fullPath.split("&")[1].split("=")[1];

    axios.defaults.headers.common = {
      Authorization: `Bearer ${this.token}`,
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    };

    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/authentication/validate`
        )
        .then((response) => {
          this.haveCompany = response.data;
          this.loading = false;
          if (this.haveCompany) {
            localStorage.setItem("token", this.token);
            localStorage.setItem("refresh-token", this.token);
            const {token} = this;
            this.$store.dispatch(AUTH_PROVIDER, {token}).then(() => {
              if (this.$route.query.from)
                this.$router.push({path: this.$route.query.from.toString()}).catch(() => {});
              else this.$router.push({path: "/"}).catch(() => {});
            });
          }
        });
  }

  parseJWT() {
    try {
      return JSON.parse(Buffer.from(this.token.split(".")[1], "base64").toString());
    } catch (e) {
      return null;
    }
  }
}
