














import Vue from "vue";

import {Component} from "vue-property-decorator";

import axios from "axios";
import Log from "@/components/release/log/Log.vue";

@Component({
  components: {
    Log,
  },
})
export default class Logs extends Vue {
  pods: string[] = [];

  public mounted(): void {
    this.loadPodsData();
  }

  public loadPodsData(): void {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/default/api/v1/namespaces/autoscaling-on-demand/pods`
        )
        .then((response) => {
          response.data.items.forEach((element: any) => {
            if (element.metadata.labels.app) {
              if (element.metadata.labels.app === "api") {
                this.pods.push(element.metadata.name);
              }
            }
          });
        })
        .catch(() => {
        });
  }
}
