import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/es5/util/colors";
import 'material-design-icons-iconfont/dist/material-design-icons.css'

// @ts-ignore
import fr from 'vuetify/lib/locale/fr'

Vue.use(Vuetify);

export default new Vuetify({
  lang: {
    locales: {
      fr,
    },
    current: 'fr',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: colors.grey.darken2,
        secondary: colors.blue.lighten1,
        tertiary: '#FFFFFF',
        menu: colors.blueGrey.lighten5,
        error: colors.red.accent3,
        background: colors.grey.lighten3,
        backgroundContainers: '#FFFFFF',
        chartsLineColor: colors.blueGrey.lighten2,
      },
      dark: {
        primary: colors.deepOrange.darken1,
        secondary: colors.teal.accent3,
        tertiary: colors.grey.darken3,
        menu: colors.grey.darken2,
        background: colors.grey.darken4,
        backgroundContainers: '#1E1E1E',
        chartsLineColor: colors.grey.lighten1,
      }
    }
  },
  icons: {
    iconfont: 'md',
  }
});
