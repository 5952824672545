




















































































































import Vue from "vue";

import {Component} from "vue-property-decorator";

import Alert from "@/components/alert/Alert.vue";
import axios from "axios";
import ApplicationAdmin from "@/components/admin/ApplicationAdmin.vue";
import DialogConfirmation from "@/components/dialogue/DialogConfirmationComponent.vue";
import SearchApp from "@/components/SearchApp.vue";

@Component({
  components: {
    ApplicationAdmin,
    DialogConfirmation,
    Alert,
    SearchApp,
  },
})
export default class Applications extends Vue {
  apps: string[] = [];
  error: boolean = false;
  page: number = 1;
  maxPage: number = 1;
  empty: boolean = false;
  emptySearch: boolean = false;
  refreshLoadRepo: boolean = false;
  loadAllowToDeploy: boolean = false;
  loading: boolean = true;
  isAllowToDeploy: boolean = true;

  get confirm(): DialogConfirmation {
    // Obligatoire sinon il ne reconnait pas la méthode open de DialogConfirmation
    return this.$refs.confirm as DialogConfirmation;
  }

  public beforeMount(): void {
    this.loadApps();
  }

  public mounted(): void {
    this.getNextApps();
    this.getDeploymentState();
  }

  public getDeploymentState() {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/default/deploymentAllowed`
        )
        .then((response) => {
          this.isAllowToDeploy = response.data;
        })
        .catch(() => {
        });
  }

  public refreshRepo(): void {
    this.refreshLoadRepo = true;
    axios
        .post(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/assets/default/refreshRepo`
        )
        .then(() => {
          this.refreshLoadRepo = false;
          this.apps = [];
          this.loadApps();
        })
        .catch(() => {
        });
  }

  public loadApps(): void {
    axios
        .get(
            `${
                process.env.VUE_APP_SPRING_API_BASEURL
            }/api/default/${localStorage.getItem(
                "namespace"
            )}/applications?size=1000&show=all`
        )
        .then((response) => {
          this.apps = this.apps.concat(response.data.availablePackageSummaries);
          this.maxPage = response.data.nextPageToken ? response.data.nextPageToken - 1 : 0;
          if (this.apps.length === 0) {
            this.empty = true;
          }
          this.loading = false;
        })
        .catch(() => {
          this.error = true;
        });
  }

  public getNextApps(): void {
    window.onscroll = () => {
      const bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
      if (bottomOfWindow && this.page < this.maxPage) {
        this.page++;
        this.loadApps();
      }
    };
  }

  public updateSearchApp(input: any): void {
    if (input.length > 0) {
      axios
          .get(
              `${
                  process.env.VUE_APP_SPRING_API_BASEURL
              }/api/default/${localStorage.getItem(
                  "namespace"
              )}/applications?page=0&size=0&searchInput=${input}`
          )
          .then((res) => {
            this.apps = [];
            res.data.availablePackageSummaries.forEach((value: any) => {
              this.apps.push(value);
            });
            this.emptySearch = this.apps.length === 0;
          })
          .catch(() => {
          });
    } else if (input.length === 0) {
      this.apps = [];
      this.loadApps();
    }
  }

  public ajouterApplication(): void {
    this.$router.push({name: "AddCatalogue"}).catch(() => {});
  }

  public modifierApplication(app: any): void {
    this.$router.push({
      name: "EditCatalogue",
      params: {app: app.name},
    }).catch(() => {});
  }

  public enableDisableDeploy() {
    this.loadAllowToDeploy = true;
    axios
        .post(
            `${
                process.env.VUE_APP_SPRING_API_BASEURL
            }/api/default/enableDisable`
        )
        .then((response) => {
          this.isAllowToDeploy = response.data;
        })
        .catch(() => {
        }).finally(() => {
          this.loadAllowToDeploy = false;
    });
  }

  public async supprimerApplication(app: any): Promise<void> {
    if (
        await this.confirm.open(
            this.$t("dialogConfirmation.confirmer"),
            this.$t("adminApplications.supprimer", {msg: app.name}),
            null
        )
    ) {
      axios
          .delete(
              `${
                  process.env.VUE_APP_SPRING_API_BASEURL
              }/api/default/${localStorage.getItem("namespace")}/applications/${
                  app.name
              }`
          )
          .then(() => {
            this.apps = [];
            this.loadApps();
          })
          .catch(() => {
          });
    }
  }
}
