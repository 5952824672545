

























































import axios from "axios"
import {Component, Prop, Vue, Watch} from "vue-property-decorator"

const Convert = require("ansi-to-html")
const convert = new Convert({newline: true, escapeXML: true})

@Component
export default class Log extends Vue {
  @Prop() release: any
  @Prop() adminLogs: any

  public overlay: boolean = false
  public overlayLogs: boolean = false
  public podsName: any = []
  public logs: any = new Map()
  public loaded: boolean = false
  public loadedLogs: number = 0
  public logTimeout: any = {}
  public currentIndex: number = 0
  public autoscroll: boolean = true

  get darkTheme() {
    return this.$vuetify.theme.dark
  }

  public mounted() {
    this.refreshLog()
  }

  public beforeDestroy() {
    clearTimeout(this.logTimeout)
  }

  public scrollBottom() {
    this.overlayLogs = true
    const textLog = document.getElementById(
        `logs_text_${this.podsName[this.currentIndex]}`
    )

    if (textLog === null) {
      setTimeout(this.scrollBottom, 100)
    } else {
      if (this.autoscroll) {
        textLog.scrollTop = textLog.scrollHeight
      }
      textLog.innerHTML = this.logs.get(this.podsName[this.currentIndex])
      this.overlayLogs = false
    }
  }

  public refreshLog() {
    if (this.adminLogs === undefined) {
      this.loadPods(this.release)
    } else {
      this.podsName = this.adminLogs
      this.loadLogs(true)
    }

    this.scrollBottom()
    this.logTimeout = setTimeout(this.refreshLog, 10000)
  }

  public loadPods(val: any) {
    const params = new URLSearchParams()
    params.append(
        "query",
        `kube_pod_info{namespace="${this.$route.params.namespace}", pod=~"${val[0].name}.*"}`
    )

    axios
        .post(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
            params
        )
        .then((response) => {
          response.data.data.result.forEach((pod: any) => {
            if (!this.podsName.includes(pod.metric.pod)) {
              this.podsName.push(pod.metric.pod)
            }
          })

          // Supprime les doublons
          this.podsName = Array.from(new Set(this.podsName))
          this.loadLogs(false)
        })
        .catch(() => {
        })
  }

  public loadLogs(admin: any) {
    const namespace = admin
        ? "autoscaling-on-demand"
        : this.$route.params.namespace
    this.podsName.forEach((name: any) => {
      axios
          .get(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/default/api/log/${namespace}/pod/${name}?tailLines=1000`
          )
          .then((response) => {
            this.logs.set(name, convert.toHtml(response.data))
            this.loadedLogs = this.logs.size
            this.scrollBottom()
          })
          .catch(() => {
            this.logs.set(name, convert.toHtml("<body></body>"))
            this.loadedLogs = this.logs.size
          })
    })
  }

  public downloadLog(name: any) {
    const namespace =
        this.adminLogs === undefined
            ? this.$route.params.namespace
            : "autoscaling-on-demand"

    const filename = `logs_${name}_${new Date().toLocaleDateString(
        "fr-FR"
    )}.log`
    this.overlay = true
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/default/api/log/${namespace}/pod/${name}`
        )
        .then((response) => {
          const element = document.createElement("a")
          element.setAttribute(
              "href",
              `data:text/plaincharset=utf-8,${encodeURIComponent(response.data)}`
          )
          element.setAttribute("download", filename)

          element.style.display = "none"
          document.body.appendChild(element)

          element.click()
          document.body.removeChild(element)
          this.overlay = false
        })
        .catch(() => {
        })
  }

  @Watch('loadedLogs')
  public onLoadedLogs(val: any) {
    this.loaded = val === this.podsName.length
  }

  @Watch('currentIndex')
  public onIndexChange() {
    this.scrollBottom()
  }

  @Watch('adminLogs')
  public onAdminLogs() {
    clearTimeout(this.logTimeout)
    this.refreshLog()
  }
}
