















































































































































import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import axios from "axios";
import {AUTH_LOGOUT, AUTH_REFRESH_TOKEN} from "@/store/actions/auth";
import DialogConfirmation from "@/components/dialogue/DialogConfirmationComponent.vue";
import DialogInput from "@/components/dialogue/DialogInputComponent.vue";
import Factures from "@/components/account/Factures.vue";
import MonthlyFactures from "@/components/account/MonthlyFactures.vue";
import Invitations from "@/components/account/Invitations.vue";
import {TranslateResult} from "vue-i18n";

const moment = require("moment");

@Component({
  components: {
    DialogConfirmation,
    DialogInput,
    Factures,
    MonthlyFactures,
    Invitations,
  },
})
export default class MyCompany extends Vue {
  @Prop({}) nomEntrepriseAdmin: string | undefined;

  users: { user_id: string, username: string, role: string, email: string, date_arrive: string, disabled?: boolean }[] = [];
  editedIndex: number = -1;
  editedItem: any;
  defaultItem: any;
  entrepriseName: string = "";
  errorMessage: string = "";
  error: boolean = false;
  successMessage: string = "";
  success: boolean = false;
  loading: boolean = true;
  show: boolean = true;

  get userData() {
    return this.$store.getters.userData;
  }

  get companyName() {
    return this.$store.getters.entreprise;
  }

  get headers(): {
    text: TranslateResult;
    align: string;
    sortable: boolean;
    value: string;
  }[] {
    return [
      {
        text: this.$t("myCompany.username"),
        align: "center",
        sortable: false,
        value: "username",
      },
      {
        text: this.$t("myCompany.role"),
        align: "center",
        sortable: false,
        value: "role",
      },
      {
        text: this.$t("myCompany.mail"),
        value: "email",
        align: "center",
        sortable: false,
      },
      // { text: "Date d'inscription", align: "center", value: "date_arrive" },
      {
        text: this.$t("myCompany.action"),
        value: "actions",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("myCompany.admin"),
        value: "admin",
        align: "center",
        sortable: false,
      },
    ];
  }

  get confirm(): DialogConfirmation {
    // Obligatoire sinon il ne reconnait pas la méthode open de DialogConfirmation
    return this.$refs.confirm as DialogConfirmation;
  }

  get input(): DialogInput {
    return this.$refs.input as DialogInput;
  }

  public mounted(): void {
    this.init();
  }

  public init(): void {
    if (
        this.nomEntrepriseAdmin !== undefined &&
        this.nomEntrepriseAdmin.length > 0 &&
        this.userData.realm_access.roles.includes("ADMIN")
    ) {
      this.entrepriseName = this.nomEntrepriseAdmin;
    } else {
      this.entrepriseName = this.companyName;
    }
    if (this.entrepriseName) {
      this.initialize();
    }
  }

  public initialize(): void {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/company/users/${this.entrepriseName}`
        )
        .then((response) => {
          moment.locale("fr");
          response.data.forEach((user: any) => {
            this.users.push({
              username: user.username,
              role:
                  user.attributes !== null &&
                  user.attributes["entrepriseAdmin"] &&
                  user.attributes["entrepriseAdmin"].includes(this.entrepriseName)
                      ? "Administrateur"
                      : "Membre",
              email: user.email,
              date_arrive: moment.unix(user.createdTimestamp / 1000).format("L"),
              user_id: user.id,
            });
            this.loading = false;
          });
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
          }
          this.error = true;
        });
  }

  public async delAccount(item: any): Promise<void> {
    if (
        this.userData.entrepriseAdmin &&
        this.userData.entrepriseAdmin.includes(this.entrepriseName)
    ) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (
          await this.confirm.open(
              "Confirmation",
              `Êtes-vous sûr de vouloir supprimer l'utilisateur ${item.username} la société ?
            <small><strong>CETTE OPERATION EST IRREVERSIBLE !</strong></small>`,
              null
          )
      ) {
        this.users[this.editedIndex].disabled = true;
        axios
            .delete(
                `${process.env.VUE_APP_SPRING_API_BASEURL}/api/company/user/${item.user_id}/${this.entrepriseName}`
            )
            .then(() => {
              this.users.splice(this.editedIndex, 1);
            })
            .catch((error) => {
              if (error.response.data.message) {
                this.errorMessage = error.response.data.error;
              }
              this.error = true;
            });
      }
    }
  }

  public async upgradeUser(item: any): Promise<void> {
    if (
        this.userData.entrepriseAdmin &&
        this.userData.entrepriseAdmin.includes(this.entrepriseName)
    ) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (
          await this.confirm.open(
              "Confirmation",
              `Êtes-vous sûr de vouloir mettre l'utilisateur <i>${item.username}</i> administrateur de la société ?
            <small><strong>CETTE OPERATION EST IRREVERSIBLE !</strong></small>`,
              null
          )
      ) {
        this.users[this.editedIndex].disabled = true;
        axios
            .put(
                `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/entreprise/${item.user_id}/${this.entrepriseName}`
            )
            .then(() => {
              this.reloadCompany();
              this.error = false;
              this.success = true;
              this.successMessage = `L'utilisateur ${item.name} est maintenant administrateur`;
            })
            .catch((error) => {
              if (error.message) {
                this.errorMessage = error.message;
              }
              this.success = false;
              this.error = true;
            });
      }
    }
  }

  public close(): void {
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public closeDelete(): void {
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public async createJoinCompany(): Promise<void> {
    if (
        await this.input.open(
            "Création entreprise",
            "Vous êtes sur le point de rejoindre/créer une nouvelle entreprise ! <br><small><b>APRES CONFIRMATION CETTE OPERATION EST IRREVERSIBLE !</b></small>",
            null
        )
    ) {
      axios
          .post(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/create/${this.input.input}`
          )
          .then(() => {
            // L'entreprise n'existe pas
            this.$store.dispatch(AUTH_LOGOUT, this.userData.session_state).then(() => {
              this.$router.push("/login").catch(() => {});
            });
          })
          .catch(() => {
            // L'entreprise existe
            axios
                .post(
                    `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/join/${this.input.input}`
                )
                .then((response) => {
                  this.$store.dispatch(AUTH_REFRESH_TOKEN).then(() => {
                    if (response.data) {
                      this.success = true;
                      this.successMessage = `Votre invitation a été envoyée à ${this.input.input}`;
                    }
                  });
                })
                .catch((error) => {
                  this.error = true;
                  this.errorMessage = error.response.data.error;
                });
          });
    }
  }

  public reloadCompany(): void {
    this.$emit("reload_entreprise");
  }

  @Watch("companyName")
  onCompanyNameChange(newV: any) {
    if (newV !== undefined) this.init();
  }
}
