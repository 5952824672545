var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    (_vm.activefiltres.length === 0 ||
      (_vm.chart.categories.length > 0 && _vm.activefiltres.indexOf(_vm.chart.categories[0]) !== -1)) &&
      _vm.chart.name.includes(_vm.searchname)
  )?_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
  var hover = ref.hover;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
  var on = ref.on;
  var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"float-left ma-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 0,"max-width":!_vm.$vuetify.breakpoint.smAndDown ? 200 : '100%',"min-width":!_vm.$vuetify.breakpoint.smAndDown ? 200 : '100%',"disabled":!_vm.isAllowToDeploy,"to":{
          name: 'Deploiement',
          params: {
            namespace: _vm.chart.availablePackageRef.context.namespace,
            repo: _vm.chart.availablePackageRef.identifier,
          },
        },"color":"tertiary"}},'v-card',attrs,false),on),[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"mx-auto",attrs:{"rounded":"","size":"150"}},[_c('img',{attrs:{"src":_vm.chart.iconUrl
                  ? _vm.chart.iconUrl
                  : '/default.png',"alt":"chart_icon"}})])],1),_c('v-list-item-title',{staticClass:"overline text-center"},[_vm._v(" "+_vm._s(_vm.chart.name)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.chart.shortDescription))])])]}}],null,false,979690159)}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }