var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [(_vm.upToDate)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"ma-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 0,"max-width":!_vm.$vuetify.breakpoint.smAndDown ? 200 : '100%',"min-width":!_vm.$vuetify.breakpoint.smAndDown ? 200 : '100%',"to":{
            name: 'Application',
            params: {
              namespace: _vm.releases.namespace,
              app: _vm.releases.releaseName,
              cluster: 'default',
            },
          },"color":"tertiary"}},'v-card',attrs,false),on),[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"mx-auto",attrs:{"rounded":"","size":"150"}},[_c('img',{attrs:{"src":_vm.releases.icon ? _vm.releases.icon : '/default.png',"alt":"chart_icon"}})])],1),_c('v-list-item-title',{staticClass:"overline text-center pa-1"},[_vm._v(" "+_vm._s(_vm.releases.releaseName)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.releases.releaseName))])])]:[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"not-uptodate ma-2",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 4 : 0,"max-width":!_vm.$vuetify.breakpoint.smAndDown ? 200 : '100%',"min-width":!_vm.$vuetify.breakpoint.smAndDown ? 200 : '100%',"to":{
            name: 'Application',
            params: {
              namespace: _vm.releases.namespace,
              app: _vm.releases.releaseName,
              cluster: 'default',
            },
          },"color":"tertiary"}},'v-card',attrs,false),on),[_c('v-list-item',[_c('v-list-item-avatar',{staticClass:"mx-auto",attrs:{"rounded":"","size":"150"}},[_c('img',{attrs:{"src":_vm.releases.icon ? _vm.releases.icon : '/default.png',"alt":"chart_icon"}})])],1),_c('v-list-item-title',{staticClass:"overline text-center pa-1"},[_vm._v(" "+_vm._s(_vm.releases.releaseName)+" ")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.releases.releaseName))])])]]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }