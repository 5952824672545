import axios from "axios";
import * as ACTIONS from "../../actions/user/const_invitation";

const actions = {
  // eslint-disable-next-line no-unused-vars
  [ACTIONS.INVITATION_REQUEST]: ({commit}: any, userSub: any) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/invitations/user/${userSub}`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        });
    });
  },
}

export default {
  actions
};
