




















































































import Vue from "vue";
import axios from "axios";

import {Component} from "vue-property-decorator";

import Alert from "@/components/alert/Alert.vue";
import FormConfiguration from "@/components/deploiement/FormConfiguration.vue";

@Component({
  components: {
    FormConfiguration,
    Alert,
  },
})
export default class ConfigurationDeploiement extends Vue {
  chart: any[] | undefined = [];
  items: typeof FormConfiguration[] = [];
  datas: {}[] = [];
  versions: { attributes: { version: string } }[] = [];
  version: string = "";
  error: boolean = false;
  errorMessage: string = "";
  valid: boolean = true;
  nom: string = "";
  loading: boolean = false;
  nomRegle: {}[] = [
    (v: string) => v.length <= 40 || 'Maximum 40 caractères',
    (v: string) => !!v || "Le nom est requis",
    (v: string) =>
        /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/.test(
            v
        ) || "Le nom du service est incorrect !",
  ];

  get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  public beforeMount(): void {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/assets/default/${this.$route.params.namespace}/charts/${this.$route.params.repo}/${this.$route.params.name}`
        )
        .then((response) => {
          this.chart = response.data.data;
          for (let i = 0; i < this.chart![0].application.userConfig.length; i++) {
            this.items.push(FormConfiguration);
          }
          this.loadVersions();
        })
        .catch(() => {
          this.error = true;
        });
  }

  public loadVersions(): void {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/assets/default/${this.$route.params.namespace}/charts/${this.$route.params.repo}/${this.chart![0].attributes.name}/versions`
        )
        .then((response) => {
          this.versions = response.data.data;
          this.version = this.versions[0].attributes.version;
        })
        .catch(() => {
          this.chart = undefined;
        });
  }

  public updateConfiguration(conf: any, n: any): void {
    if (this.datas[n] === undefined) this.datas[n] = {};
    this.datas[n] = conf;
  }

  public submit(): void {
    this.valid = this.form.validate();

    if (this.valid) {
      this.loading = true;

      // Conversion en YAML
      let values = "";
      this.datas.forEach((value: any) => {
        values += `${value.key} = ${value.value}\n`;
      });

      axios
          .post(
              `${
                  process.env.VUE_APP_SPRING_API_BASEURL
              }/api/services/clusters/default/namespaces/${localStorage.getItem(
                  "namespace"
              )}/deploy`,
              {
                appRepositoryResourceName: this.$route.params.repo,
                appRepositoryResourceNamespace: this.$route.params.namespace,
                chartName: this.$route.params.name,
                releaseName: this.nom,
                version: this.version,
                values: values.toString(),
                datas: this.datas,
              }
          )
          .then((response) => {
            this.$router.push({
              name: "Application",
              params: {
                cluster: "default",
                namespace: response.data.data[0].namespace,
                app: this.nom,
              },
            }).catch(() => {});
          })
          .catch((error) => {
            this.error = true;
            this.errorMessage = error.response.data.message;
            this.loading = false;
          });
    }
  }
}
