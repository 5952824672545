






































































































import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

@Component({})
export default class FormBuilder extends Vue {
  @Prop({}) index: any;
  @Prop({}) config: any;

  items: string[] = ["text", "nombre", "mdp", "boolean"];
  configuration: {} = {
    select: "text",
    value: "",
    key: "",
    description: "",
    depend: "",
    visible: true,
  };

  public mounted(): void {
    if (this.config !== undefined) {
      this.configuration = this.config;
    }
  }

  public addFormBuilder(): void {
    this.$emit("new-form-builder");
  }

  public removeFormBuilder(): void {
    this.$emit("delete-form-builder", this.index);
  }

  @Watch("configuration", {deep: true})
  onConfigurationChange(val: any) {
    this.$emit("configuration-form-builder", val, this.index);
  }
}
