






























import {Chart} from "highcharts-vue";
import Highcharts from "highcharts";
import moment from "moment";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({
  components: {
    highcharts: Chart
  }
})
export default class AdvancedMetrics extends Vue {
  @Prop() cpu: any
  @Prop() ram: any
  @Prop() storage: any
  @Prop() storageMax: any
  @Prop() cpuMax: any
  @Prop() ramMax: any
  @Prop() theme: any

  public reloadKey: number = 0
  public themeType: any = []

  get cpuChart(): any {
    return Highcharts.merge(this.graphOptions, {
      title: {
        text: "CPU",
        align: "center",
        verticalAlign: "top",
      },

      tooltip: {
        // eslint-disable-next-line no-unused-vars
        formatter: function (this: Highcharts.TooltipFormatterContextObject): any {
          return `Consommation processeur à ${moment
              .unix(this.x / 1000)
              .format("HH:mm")} est de <b> ${this.y.toFixed(2)} %</b>`;
        },
      },

      yAxis: {
        min: 0,
        max: this.cpuMax,
      },

      colors: ["#d7a2ff"],

      series: [
        {
          data: [this.cpu],
        },
      ],
    })
  }

  get memoryChart(): any {
    const self = this
    return Highcharts.merge(this.graphOptions, {
      title: {
        text: "RAM",
        align: "center",
        verticalAlign: "top",
      },

      tooltip: {
        // eslint-disable-next-line no-unused-vars
        formatter: function (this: Highcharts.TooltipFormatterContextObject): any {
          return (
              `Consommation mémoire à ${moment
                  .unix(this.x / 1000)
                  .format("HH:mm")} est de <b>${self.bytes(this.y, true)}</b>`
          );
        },
      },

      yAxis: {
        min: 0,
        max: this.ramMax,
        labels: {
          tickPositioner: 1024,
          // eslint-disable-next-line no-unused-vars
          formatter: function (this: any): any {
            return self.bytes(this.value, true);
          },
        },
      },

      colors: ["#00ff9e"],

      series: [
        {
          data: [this.ram],
        },
      ],
    })
  }

  get diskChart(): any {
    const self = this
    return Highcharts.merge(this.graphOptions, {
      title: {
        text: "Disque",
        align: "center",
        verticalAlign: "top",
      },

      tooltip: {
        // eslint-disable-next-line no-unused-vars
        formatter: function (this: Highcharts.TooltipFormatterContextObject): any {
          return (
              `Consommation stockage à ${moment.unix(this.x / 1000).format("HH:mm")} est de <b>${self.bytes(this.y, true)}</b>`
          );
        },
      },

      yAxis: {
        min: 0,
        max: this.storageMax,
        labels: {
          tickPositioner: 1024,
          formatter: function (value: any): any {
            return self.bytes(value, true);
          },
        },
      },

      colors: ["#1976D2"],

      series: [
        {
          data: [this.storage],
        },
      ],
    })
  }

  get graphOptions() {
    return {
      chart: {
        type: "area",
      },

      title: null,

      exporting: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      legend: {
        enabled: false,
      },

      time: {
        timezone: "Europe/Paris",
        timezoneOffset: 2,
        useUTC: false,
      },

      plotOptions: {
        series: {
          marker: false,
        },
      },

      xAxis: {
        type: "datetime",
      },

      yAxis: {
        title: {
          text: "",
        },
      },
    }
  }

  public bytes(bytes: any, label: any) {
    if (!bytes) return "0";
    const s = ["k", "Ko", "Mo", "Go", "To", "Po"];
    let e = Math.floor(Math.log(bytes) / Math.log(1024));
    let value = (bytes / Math.pow(1024, Math.floor(e))).toFixed(2);
    e = e < 0 ? -e : e;
    if (label) value += ` ${s[e]}`;
    return value;
  }

  public changeColors(gauge: any) {
    gauge.chart.backgroundColor = this.themeType.backgroundContainers;
    gauge.chart.plotBackgroundColor = this.themeType.backgroundContainers;

    gauge.xAxis.gridLineColor = this.themeType.chartsLineColor;
    gauge.yAxis.gridLineColor = this.themeType.chartsLineColor;
  }

  public beforeMount() {
    // Récupération du theme actuel
    this.themeType = this.theme
        ? this.$vuetify.theme.themes["dark"]
        : this.$vuetify.theme.themes["light"];

    this.changeColors(this.cpuChart);
    this.changeColors(this.memoryChart);
    this.changeColors(this.diskChart);
  }

  public mounted() {
    this.cpuChart.series[0].data = this.cpu;
    this.memoryChart.series[0].data = this.ram;
    this.diskChart.series[0].data = this.storage;
    this.diskChart.yAxis.max = this.storageMax;
    this.cpuChart.yAxis.max = this.cpuMax;
    this.memoryChart.yAxis.max = this.ramMax;
    this.cpuChart.tooltip.formatter = function () {
      return `Consommation processeur à ${moment
          .unix(this.x / 1000)
          .format("HH:mm")} est de <b> ${this.y.toFixed(2)} %</b>`;
    };
    this.reloadKey++
  }

  @Watch('cpu')
  public onCPUChange(val: any) {
    this.cpuChart.series[0].data = val;
  }

  @Watch('ram')
  public onRamChange(val: any) {
    this.memoryChart.series[0].data = val;
  }

  @Watch('storage')
  public onStorageChange(val: any) {
    this.diskChart.series[0].data = val;
  }

  @Watch('ramMax')
  public onRamMaxChange(val: any) {
    this.memoryChart.yAxis.max = val;
  }

  @Watch('storageMax')
  public onStorageMaxChange(val: any) {
    this.diskChart.yAxis.max = val;
  }

  @Watch('cpuMax')
  public onCpuMaxChange(val: any) {
    this.cpuChart.yAxis.max = val
  }

  @Watch('theme')
  public onThemeChange(val: any) {
    this.theme = val
    // Récupération du theme actuel
    this.themeType = this.theme
        ? this.$vuetify.theme.themes["dark"]
        : this.$vuetify.theme.themes["light"];

    this.changeColors(this.cpuChart)
    this.changeColors(this.memoryChart)
    this.changeColors(this.diskChart)

    this.reloadKey++
  }
}
