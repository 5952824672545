



















































import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class Chart extends Vue {
  @Prop() chart: object | undefined
  @Prop() activefiltres: object | undefined
  @Prop() searchname: String | undefined
  @Prop() isAllowToDeploy: boolean | undefined

  get url() {
    return process.env.VUE_APP_SPRING_API_BASEURL
  }
}
