



































































































































































import Vue from "vue";

import {AUTH_REQUEST} from "@/store/actions/auth";
import {required} from "vee-validate/dist/rules";
import {extend, ValidationObserver, ValidationProvider} from "vee-validate";

import CountryFlag from "vue-country-flag";
import i18n from "@/plugins/i18n";
// @ts-ignore
import {v4 as uuidv4} from "uuid";
import {Component, Watch} from "vue-property-decorator";

extend("required", {
  ...required,
  message: "{_field_} ne peut pas être vide",
});

@Component({
  components: {
    CountryFlag,
    ValidationProvider,
    ValidationObserver,
  },
})
export default class Login extends Vue {
  public username: string = "";
  public password: string = "";
  public valid: boolean = true;
  public value: boolean = true;
  public disable: boolean = false;

  public dialog: boolean = true;

  public error: boolean = false;
  public errorMessage: string = "";

  public icon: string = "";
  public locale: string | undefined;
  public languages: any = [
    {flag: "fr", language: "fr"},
    {flag: "gb", language: "en"},
  ];
  public languageSelected: {} = {};

  public mounted() {
    if (!this.$vuetify.theme.dark) {
      this.icon = '/logo_autoscaling.svg';
    } else {
      this.icon = '/logo_autoscaling_dark.svg';
    }
    this.locale = i18n.locale;

    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].language === this.locale) {
        this.languageSelected = this.languages[i];
        break;
      }
    }
  }

  @Watch("languageSelected")
  onLanguageChange(value: any) {
    i18n.locale = value.language;
    localStorage.setItem("lang", i18n.locale);
  }

  public provider(name: any) {
    const url = `${window.location.protocol}//${window.location.host}/callback${this.$route.query.from ? `?from=${this.$route.query.from}` : ""}`;
    window.location.href = `${
        process.env.VUE_APP_KEYCLOAK_API_BASEURL
    }/auth/realms/${
        process.env.VUE_APP_KEYCLOAK_API_REALM
    }/protocol/openid-connect/auth?client_id=${
        process.env.VUE_APP_KEYCLOAK_API_CLIENTID
    }&scope=openid&nonce=${uuidv4()}&redirect_uri=${url}&kc_idp_hint=${name}&response_type=token`;
  }

  public forgotPassword() {
    this.$router.push({path: "forgot-password"}).catch(() => {});
  }

  public submit() {
    this.disable = true;
    const {username, password} = this;
    this.$store
        .dispatch(AUTH_REQUEST, {username, password})
        .then(() => {
          if (this.$route.query.from)
            this.$router.push({path: this.$route.query.from.toString()}).catch(() => {});
          else this.$router.push({path: "/"}).catch(() => {});
        })
        .catch((err) => {
          this.disable = false;
          this.error = true;
          this.errorMessage = err.response.data.error;
        });
  }
}
