















































import CostChart from "@/components/release/cost/CostChart.vue";
import CostTable from "@/components/release/cost/CostTable.vue";

import axios from "axios";
import {Component, Prop, Watch} from "vue-property-decorator";
import Vue from "vue";
import {TranslateResult} from "vue-i18n";

const moment = require("moment");

@Component({
  components: {
    CostChart,
    CostTable
  }
})
export default class Cost extends Vue {
  @Prop() release: any

  public loading: boolean = true
  public reloadKey: number = 0
  public stats: any = {}
  public start: number = 0
  public end: number = 0
  public window: any = [
    {text: this.$t("appCost.auj"), value: "today"},
    {text: this.$t("appCost.hier"), value: "yesterday"},
    {text: this.$t("appCost.semaine"), value: "week"},
    {text: this.$t("appCost.semainePrecedente"), value: "lastweek"},
    {text: this.$t("appCost.mois"), value: "month"},
  ]
  public windowSelected: any = null
  public error: boolean = false
  public errorMessage: TranslateResult | undefined
  public cost: any;

  public mounted() {
    this.windowSelected = this.window[0].value
    this.loadCost()
  }

  public loadCost() {
    let application = this.$route.params.app

    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/kubecost/${application}*?window=${this.windowSelected}`
        )
        .then((response) => {
          if (response.data.data[0][application]) {
            this.cost = response.data.data[0]

            moment.locale("fr")

            this.start = moment(this.cost[application].window.start).format(
                "DD-MM-YYYY HH:mm:ss"
            )
            this.end = moment(this.cost[application].window.end).format(
                "DD-MM-YYYY HH:mm:ss"
            )

            this.error = false

            this.getStats()
          } else {
            this.stats = []
            this.start = 0
            this.end = 0
            this.error = true
            this.errorMessage = this.$t("error.noData")
          }

          this.reloadKey++

          this.loading = false
        })
        .catch((error) => {
          this.errorMessage = error
          this.loading = false
        })
  }

  public getStats() {
    this.stats = [];

    const entries = Object.entries(this.cost);

    let totalCPU = 0;
    let totalRAM = 0;
    let totalDISK = 0;
    let totalAPP = 0;

    for (let i = 0; i < entries.length; i++) {
      const values: any = entries[i][1];

      const nom = entries[i][0] === '__unallocated__' ? values.properties.controller : entries[i][0];
      const cpu = isNaN(values.cpuCost) ? 0 : this.arrondi(values.cpuCost);
      const ram = isNaN(values.ramcost) ? 0 : this.arrondi(values.ramcost);
      const disk = isNaN(values.pvCost) ? 0 : this.arrondi(values.pvCost);
      const total = this.arrondi(cpu + ram + disk);

      totalCPU = this.arrondi(totalCPU + cpu);
      totalRAM = this.arrondi(totalRAM + ram);
      totalDISK = this.arrondi(totalDISK + disk);
      totalAPP = this.arrondi(totalAPP + total);

      this.stats.push({
        nom: nom,
        cpu: cpu,
        ram: ram,
        disque: disk,
        total: total,
      })
    }

    this.stats.push({
      nom: 'Total',
      cpu: totalCPU,
      ram: totalRAM,
      disque: totalDISK,
      total: totalAPP,
    })
  }

  public arrondi(nombre: number) {
    let result = nombre * 100
    result = Math.round(result)
    result = result / 100
    return result
  }

  @Watch('windowSelected')
  public onWindowSelected() {
    this.loadCost()
  }
}
