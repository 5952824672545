var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ml-12",staticStyle:{"width":"100%"}},[(_vm.loading)?_c('v-col',[_c('v-row',{staticClass:"pa-3"},[_c('v-skeleton-loader',{attrs:{"type":"text","width":"100%"}}),_vm._l((5),function(index){return _c('v-skeleton-loader',{key:index,staticClass:"float-left ma-2",attrs:{"max-width":!_vm.$vuetify.breakpoint.smAndDown ? 200 : '100%',"min-width":!_vm.$vuetify.breakpoint.smAndDown ? 200 : '100%',"color":"tertiary","type":"image"}})})],2)],1):_c('v-col',{attrs:{"md":"12"}},[(_vm.error)?_c('Alert',{attrs:{"message":"Impossible de joindre le backend !"}}):_vm._e(),(!_vm.isAllowToDeploy)?_c('Alert',{attrs:{"message":"Les déploiements sont actuellement désactivé !"}}):(_vm.apps.length === 0 && _vm.empty)?_c('v-alert',{attrs:{"color":"secondary","type":"info"}},[_c('strong',[_vm._v("Aie ...")]),_vm._v(" Aucune application n'est configurée pour le moment. ")]):_vm._e(),_c('SearchApp',{attrs:{"label-bar":'adminApplications.rechercheLabel'},on:{"search-app":function($event){return _vm.updateSearchApp($event)}}}),(_vm.apps.length === 0 && _vm.emptySearch)?_c('v-alert',{attrs:{"dense":"","prominent":"","type":"error"}},[_vm._v(_vm._s(_vm.$t("adminApplications.noApp"))+" ")]):_vm._e(),_c('v-row',{staticClass:"pa-3"},_vm._l((_vm.apps),function(app){return _c('ApplicationAdmin',{key:app.availablePackageRef.identifier,attrs:{"application":app},on:{"delete-app":_vm.supprimerApplication,"update-app":_vm.modifierApplication}})}),1)],1),_c('div',{staticClass:"admin-btn-position"},[_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"space-admin-btn admin-btn-position-top2",attrs:{"color":_vm.isAllowToDeploy ? 'success' : 'error',"loading":_vm.loadAllowToDeploy,"elevation":"2","fab":"","large":"","rounded":""},on:{"click":_vm.enableDisableDeploy}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-calendar-clock")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("adminApplications.enableDisableDeploy")))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"space-admin-btn admin-btn-position-top",attrs:{"color":"secondary","elevation":"2","fab":"","large":"","rounded":""},on:{"click":_vm.ajouterApplication}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("adminApplications.addApp")))])]),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"space-admin-btn",attrs:{"loading":_vm.refreshLoadRepo,"color":"primary","elevation":"2","fab":"","large":"","rounded":""},on:{"click":_vm.refreshRepo}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("adminApplications.refresh")))])])],1),_c('DialogConfirmation',{ref:"confirm"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }