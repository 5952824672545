













































































import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import AppBarDropdownMobile from "@/components/app/appbar/mobile/AppBarDropdownMobile.vue";
import {SET_MOBILE} from "@/store/actions/data";

@Component({
  components: {
    AppBarDropdownMobile,
  },
})
export default class AppBarMobile extends Vue {
  @Prop({}) entreprises: any;
  @Prop({}) namespaces: any;
  @Prop({}) links: any;

  mobileNav: boolean = false;

  get userData() {
    return this.$store.getters.userData;
  }

  get mobile() {
    return this.$store.getters.mobile;
  }

  public mounted(): void {
    this.mobileNav = this.mobile;
  }

  public darkMode(): void {
    this.$emit("darkMode");
  }

  public logout(): void {
    this.$emit("logout");
  }

  @Watch("mobile")
  onMobileChange(newV: boolean) {
    // Changement de valeur seulement si true, si on veux afficher
    if (newV) {
      this.mobileNav = newV;
    }
  }

  @Watch("mobileNav")
  onMobileNavChange(newV: boolean) {
    // Changement de valeur seulement si false, sinon on peut le mettre a true alors qu'il est déjç a true
    if (!newV) {
      this.$store.dispatch(SET_MOBILE, newV);
    }
  }
}
