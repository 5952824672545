








































































import AppCard from "@/components/app/AppCard.vue";
import {Component, Prop} from "vue-property-decorator";
import Vue from "vue";

@Component({
  components: {
    AppCard
  }
})
export default class MaterialCard extends Vue {
  @Prop() color: String | undefined
  @Prop() fullHeader: Boolean | undefined
  @Prop() heading: String | undefined
  @Prop() icon: String | undefined
  @Prop() iconSmall: Boolean | undefined
  @Prop() subtitle: String | undefined
  @Prop() title: String | undefined

  get hasHeading() {
    return !!(
        this.icon ||
        this.heading ||
        this.$slots.heading
    )
  }

  get hasTitle() {
    return !!(
        this.title ||
        this.subtitle ||
        this.$slots.title ||
        this.$slots.subtitle
    )
  }
}
