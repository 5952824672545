











































































































































































































import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import axios from "axios";
import DialogConfirmation from "@/components/dialogue/DialogConfirmationComponent.vue";
import {extend, setInteractionMode, ValidationObserver, ValidationProvider,} from "vee-validate";
import {email, required} from "vee-validate/dist/rules";
import {TranslateResult} from "vue-i18n";

const moment = require("moment");

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} ne peut pas être vide",
});
extend("email", {
  ...email,
  message: "L'email doit être valide",
});

@Component({
  components: {
    DialogConfirmation,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class Invitations extends Vue {
  @Prop({}) nomEntrepriseAdmin: string | undefined;

  users: {}[] = [];
  editedIndex: number = -1;
  editedItem: any;
  defaultItem: any;
  errorMessage: string = "";
  error: boolean = false;
  dialog: boolean = false;
  disable: boolean = false;
  icon: string = "";
  nom: string = "";
  prenom: string = "";
  username: string = "";
  mail: string = "";
  companyName: string = "";
  loading: boolean = false;
  loader: string | undefined = "";
  firstLoad: boolean = true;
  show: boolean = true;
  entrepriseName: string = "";

  get userData() {
    return this.$store.getters.userData;
  }

  get companyNameStore() {
    return this.$store.getters.entreprise;
  }

  get headers(): {
    text: TranslateResult;
    align: string;
    sortable: boolean;
    value: string;
  }[] {
    return [
      {
        text: this.$t("invitation.username"),
        align: "center",
        sortable: false,
        value: "utilisateur",
      },
      {
        text: this.$t("invitation.mail"),
        value: "email",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("invitation.date"),
        align: "center",
        value: "date_demande",
        sortable: true,
      },
      {
        text: this.$t("invitation.actions"),
        value: "actions",
        align: "center",
        sortable: false,
      },
    ];
  }

  get observer(): Vue & { validate: () => boolean } {
    return this.$refs.observer as Vue & { validate: () => boolean };
  }

  get confirm(): DialogConfirmation {
    // Obligatoire sinon il ne reconnait pas la méthode open de DialogConfirmation
    return this.$refs.confirm as DialogConfirmation;
  }

  public mounted(): void {
    if (!this.$vuetify.theme.dark) {
      this.icon = '/logo_autoscaling.svg';
    } else {
      this.icon = '/logo_autoscaling_dark.svg';
    }
    this.init();
  }

  public init(): void {
    if (
        this.nomEntrepriseAdmin !== undefined &&
        this.nomEntrepriseAdmin.length > 0
    ) {
      this.entrepriseName = this.nomEntrepriseAdmin;
    } else {
      this.entrepriseName = this.companyNameStore;
    }

    if (this.entrepriseName) {
      this.initialize();
    }
  }

  public initialize(): void {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/invitations/${this.entrepriseName}`
        )
        .then((response) => {
          moment.locale("fr");
          response.data.forEach((invitation: any) => {
            this.users.push({
              utilisateur: `${invitation.prenom} ${invitation.nom.toUpperCase()}`,
              email: invitation.email,
              date_demande: moment(invitation.date_demande).format("L"),
              user_id: invitation.user_id,
            });
          });
          this.firstLoad = false;
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
          }
          this.error = true;
        });
  }

  public async acceptInvitation(item: any): Promise<void> {
    if (this.userData.entrepriseAdmin.includes(this.entrepriseName)) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (
          await this.confirm.open(
              "Confirmation",
              `Êtes-vous sûr de vouloir accepter l'utilisateur ${item.utilisateur} dans la société ? <small><strong>CETTE OPERATION EST IRREVERSIBLE !</strong></small>`,
              null
          )
      ) {
        axios
            .post(
                `${process.env.VUE_APP_SPRING_API_BASEURL}/api/invitations/${this.entrepriseName}/${item.user_id}`
            )
            .then(() => {
              this.users.splice(this.editedIndex, 1);
              this.$emit("reload_entreprise");
            })
            .catch((error) => {
              if (error.response.data.message) {
                this.errorMessage = error.response.data.message;
              }
              this.error = true;
            });
      }
    }
  }

  public async refuseInvitation(item: any): Promise<void> {
    if (this.userData.entrepriseAdmin.includes(this.entrepriseName)) {
      this.editedIndex = this.users.indexOf(item);
      this.editedItem = Object.assign({}, item);
      if (
          await this.confirm.open(
              "Confirmation refus",
              `Êtes-vous sûr de vouloir refuser l'utilisateur ${item.utilisateur} dans la société ? <small><strong>CETTE OPERATION EST IRREVERSIBLE !</strong></small>`,
              null
          )
      ) {
        axios
            .delete(
                `${process.env.VUE_APP_SPRING_API_BASEURL}/api/invitations/${this.entrepriseName}/${item.user_id}`
            )
            .then(() => {
              this.users.splice(this.editedIndex, 1);
            })
            .catch((error) => {
              if (error.response.data.message) {
                this.errorMessage = error.response.data.message;
              }
              this.error = true;
            });
      }
    }
  }

  public close(): void {
    this.dialog = false;
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public closeDelete(): void {
    this.$nextTick(() => {
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
    });
  }

  public submit(): void {
    this.loader = "loading";

    this.observer.validate();

    this.companyName = this.entrepriseName;

    const {username, nom, prenom, companyName, mail} = this;

    axios({
      url: `${process.env.VUE_APP_SPRING_API_BASEURL}/api/authentication/createInsideEntreprise`,
      data: {username, nom, prenom, companyName, mail},
      method: "POST",
    })
        .then(() => {
          // Fermeture de la boite de dialogue
          this.dialog = false;
          // Reset du formulaire
          this.username = "";
          this.nom = "";
          this.prenom = "";
          this.mail = "";

          // Emet un event pour reload les datas de mon entreprise
          this.$emit("reload_entreprise");
        })
        .catch((err) => {
          this.errorMessage = err;
          this.error = true;
        })
        .finally(() => {
          this.loader = undefined;
        });
  }

  @Watch("loader")
  onLoaderChange(newV: any) {
    this.loading = newV !== null;
  }

  @Watch("companyNameStore")
  onCompanyNameStoreChange(newV: any) {
    if (newV !== undefined) this.init();
  }
}
