















































































import axios from "axios";
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component
export default class Releases extends Vue {
  @Prop() releases: any

  public upToDate: boolean = true

  public mounted() {
    axios
        .get(
            `${
                process.env.VUE_APP_SPRING_API_BASEURL
            }/api/assets/default/${localStorage.getItem("namespace")}/charts?filterOptions.query=${
                this.releases.chart
            }`
        )
        .then((response) => {
          if (
              (response.data.data.length > 0 &&
                  this.releases.chartMetadata.version !==
                  response.data.data[0].relationships.latestChartVersion.data
                      .version) ||
              this.releases.chartMetadata.appVersion !==
              response.data.data[0].relationships.latestChartVersion.data
                  .app_version
          ) {
            this.upToDate = false;
          }
        })
        .catch(() => {
        });
  }
}
