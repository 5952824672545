














































































































































































































































































































































































































import axios from "axios";
import Alert from "@/components/alert/Alert.vue";
import DialogueRollback from "@/components/dialogue/DialogueRollback.vue";
import YAML from "yaml";
import ErrorComponent from "@/components/error/ErrorComponent.vue";
import DialogConfirmation from "@/components/dialogue/DialogConfirmationComponent.vue";
import BasicMetrics from "@/components/release/metrics/BasicMetrics.vue";
import AdvancedMetrics from "@/components/release/metrics/AdvancedMetrics.vue";
import UltraMetrics from "@/components/release/metrics/UltraMetrics.vue";
import Historique from "@/components/release/historique/Historique.vue";
import Secrets from "@/components/release/secrets/Secrets.vue";
import Log from "@/components/release/log/Log.vue";
import Cost from "@/components/release/cost/Cost.vue";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

const moment = require("moment");

const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};

@Component({
  components: {
    DialogConfirmation,
    ErrorComponent,
    DialogueRollback,
    Alert,
    BasicMetrics,
    AdvancedMetrics,
    UltraMetrics,
    Historique,
    Secrets,
    Log,
    Cost
  }
})
export default class Release extends Vue {
  @Prop() release: any

  public mobileNav: boolean = false
  public color: any = {
    border: "#000000",
    visor: "#FFFFFF",
    fill: "#FFFFFF",
    circleBorder: "#000000",
    starSmallBorder: "#000000",
    starBigBorder: "#000000",
    planetRing: "#000000",
  }
  public tab: String = "informations"
  public tabMetrics: String = "basic"
  public error: boolean = false
  public errorMessage: String = ""
  public loading: boolean = true
  public dialog: boolean = false
  public dialogRollback: boolean = false
  public secrets: any = []
  public services: any = []
  public status: any = []
  public chart: any = []
  public renderKey: number = 0
  public cpuTimeout: any = {}
  public memoryTimeout: any = {}
  public diskTimeout: any = {}
  public appLoadTimeout: any = {}
  public request: number = 0
  public loaded: number = 0
  public cpuDataBasic: number = 0
  public ramDataBasic: number = 0
  public storageDataBasic: number = 0
  public cpuDataAdvanced: any = []
  public ramDataAdvanced: any = []
  public storageDataAdvanced: any = []
  public cpuDataMax: number = 0
  public ramDataMax: number = 3000000000
  public storageDataMax: number = 0
  public loadPrometheus: boolean = false
  public loadValue: any = []
  public appReady: boolean = false
  public readyReplicas: number = 0
  public requestedReplicas: number = 0
  public filterPublicServicesResult: any = []
  public loadingDelete: boolean = false
  public dialogEnableDisable: boolean = false

  get darkTheme() {
    return this.$vuetify.theme.dark;
  }

  get isOnline() {
    let online = 0;
    this.status.forEach((value: any) => {
      if (value.kind === "Deployment") online += value.spec.replicas;
    });
    return online !== 0;
  }

  get confirm(): DialogConfirmation {
    // Obligatoire sinon il ne reconnait pas la méthode open de DialogConfirmation
    return this.$refs.disableApp as DialogConfirmation;
  }

  public mounted() {
    if (this.release && this.release[0] !== undefined) {
      this.load(this.release[0]);
    }

    document.addEventListener("visibilitychange", () => {
      this.loadPrometheus = !document.hidden;
    });
  }

  public beforeDestroy() {
    if (this.cpuTimeout) clearTimeout(this.cpuTimeout);
    if (this.memoryTimeout) clearTimeout(this.memoryTimeout);
    if (this.diskTimeout) clearTimeout(this.diskTimeout);
    if (this.appLoadTimeout) clearTimeout(this.appLoadTimeout);
  }

  public openService(url: any) {
    window.open(url, "_blank");
  }


  public async enableOrDisableApp() {
    if (
        await this.confirm.open(
            `${this.isOnline ? "Désactiver" : "Activer"} l'application`,
            `Êtes vous sur de vouloir ${
                this.isOnline ? "désactiver" : "activer"
            } le service ? <small><b>CETTE OPERATION EST IRREVERSIBLE !</b></small>`, null
        )
    ) {
      this.status.forEach((value: any) => {
        if (value.kind === "Deployment") {
          axios
              .put(
                  `${
                      process.env.VUE_APP_SPRING_API_BASEURL
                  }/api/services/clusters/${
                      this.$route.params.cluster
                  }/namespaces/${this.$route.params.namespace}/releases/${
                      value.metadata.name
                  }/disableEnable?action=${
                      this.isOnline ? "disable" : "enable"
                  }&serviceName=${this.$route.params.app}&replicas=${
                      value.spec.replicas
                  }`
              )
              .then(() => {
                this.dialogEnableDisable = false;
                this.request += 1;
              })
              .catch((e1) => {
                this.errorMessage = e1.response.data.message;
                this.error = true;
                this.dialogEnableDisable = false;
              });
        }
      });
    }
  }

  public async deleteApp() {
    if (
        await this.confirm.open(
            "Supprimer l'application",
            "Êtes vous sur de vouloir supprimer l'application ? <small><b>CETTE OPERATION EST IRREVERSIBLE !</b></small>", null
        )
    ) {
      this.loadingDelete = true;
      axios
          .delete(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/services/clusters/${this.$route.params.cluster}/namespaces/${this.$route.params.namespace}/releases/${this.$route.params.app}`
          )
          .then(() => {
            this.loadingDelete = false;
            this.appReady = false;
            this.$router.push({name: "Applications"}).catch(() => {});
          })
          .catch((error) => {
            this.errorMessage = error.response.data.message;
            this.error = true;
          });
    }
  }

  public rollbackRelease() {
    this.dialogRollback = true;
    this.error = false;
  }

  public cancelRollback(v: any) {
    this.dialogRollback = !v;
  }

  public confirmRollback(v: any) {
    this.dialogRollback = !v;
    this.request += 1;
  }

  public load(val: any) {
    this.loadValue = val;
    if (val && val[0].manifest) {
      const values = YAML.parseAllDocuments(val[0].manifest)
          .map((doc) => doc.toJSON())
          .filter((value) => value && value.kind);
      values.forEach((value: any) => {
        switch (value.kind) {
          case "Secret":
            Object.keys(value.data).forEach((value1) => {
              const split = value1.split(".");
              if (!split.includes("tls") && !split.includes("ca")) {
                this.secrets.push({key: value1, value: value.data[value1]});
              }
            });
            this.loaded += 1;
            break;
          case "Deployment":
            axios
                .get(
                    `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.cluster}/apis/${value.apiVersion}/namespaces/${this.$route.params.namespace}/deployments/${value.metadata.name}`
                )
                .then((response) => {
                  this.status.push(response.data);
                  this.loaded += 1;
                  this.getStatusServices();
                })
                .catch(() => {
                });
            break;
          case "StatefulSet":
            axios
                .get(
                    `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${
                        this.$route.params.cluster
                    }/apis/${value.apiVersion}/namespaces/${
                        value.metadata.namespace
                            ? value.metadata.namespace
                            : this.$route.params.namespace
                    }/statefulsets/${value.metadata.name}`
                )
                .then((response) => {
                  this.status.push(response.data);
                  this.loaded += 1;
                })
                .catch(() => {
                });
            break;
          case "Service":
            axios
                .get(
                    `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${
                        this.$route.params.cluster
                    }/api/${value.apiVersion}/namespaces/${
                        value.metadata.namespace
                            ? value.metadata.namespace
                            : this.$route.params.namespace
                    }/services/${value.metadata.name}`
                )
                .then((response) => {
                  this.services.push(response.data);
                  this.loaded += 1;
                })
                .catch(() => {
                });
            break;
          case "Ingress":
            axios
                .get(
                    `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${
                        this.$route.params.cluster
                    }/apis/${value.apiVersion}/namespaces/${
                        value.metadata.namespace
                            ? value.metadata.namespace
                            : this.$route.params.namespace
                    }/ingresses/${value.metadata.name}`
                )
                .then((response) => {
                  this.services.push(response.data);
                  this.loaded += 1;
                })
                .catch(() => {
                });
            break;
          default:
            this.loaded += 1;
            break;
        }
      });
    }
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/assets/${this.$route.params.cluster}/${this.$route.params.namespace}/charts?filterOptions.query=${this.release[0].chart.metadata.name}&version=${val[0].chart.metadata.version}`
        )
        .then((response) => {
          this.chart = response.data.availablePackageSummaries;
        })
        .catch(() => {
          this.chart = [];
        });
  }

  public loadDataCPU() {
    if (this.isOnline && this.loadPrometheus) {
      let params = new URLSearchParams();
      params.append(
          "query",
          'kube_pod_container_resource_limits{resource="cpu", namespace="' +
          this.$route.params.namespace +
          '", pod=~"' +
          this.release[0].name +
          '-.*"}'
      );
      axios
          .post(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
              params,
              config
          )
          .then((response) => {
            if (response.data.data.result[0]) {
              this.cpuDataMax = parseFloat(
                  response.data.data.result[0].values[
                  response.data.data.result[0].values.length - 1
                      ][1]
              );
              params = new URLSearchParams();
              params.append(
                  "query",
                  'node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{namespace="' +
                  this.$route.params.namespace +
                  '", pod=~"' +
                  this.release[0].name +
                  '-.*", container="' +
                  response.data.data.result[0].metric.container +
                  '"}'
              );

              axios
                  .post(
                      `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
                      params,
                      config
                  )
                  .then((data) => {
                    if (data.data.data.result[0]) {
                      data.data.data.result[0].values.forEach((values: any) => {
                        // Data pour l'affichage basic
                        this.cpuDataBasic = parseInt(((values[1] * 100) / this.cpuDataMax).toFixed(0))
                        // Data pour l'affichage avancé
                        if (this.cpuDataAdvanced.length > 100) {
                          this.cpuDataAdvanced.shift();
                        }
                        this.cpuDataAdvanced.push([
                          moment(values[0] * 1000).unix() * 1000,
                          (parseFloat(values[1]) * 100) / this.cpuDataMax,
                        ]);
                      });

                      // On le met a 100 pour avoir un charts avec un max a 100 pour l'affichage avancé
                      this.cpuDataMax = 100;
                    }
                  })
                  .catch(() => {
                  });
            }
          })
          .catch(() => {
          });
    }

    this.cpuTimeout = setTimeout(this.loadDataCPU, 15000);
  }

  public loadDataMemory() {
    if (this.isOnline && this.loadPrometheus) {
      let params = new URLSearchParams();
      params.append(
          "query",
          'sum(node_namespace_pod_container:container_memory_working_set_bytes{namespace="' +
          this.$route.params.namespace +
          '", pod=~"' +
          this.release[0].name +
          '-.*"})'
      );

      axios
          .post(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
              params,
              config
          )
          .then((response) => {
            if (response.data.data.result[0]) {
              let ramTemp: number;
              response.data.data.result[0].values.forEach((values: any) => {
                ramTemp = parseInt(values[1]);
                // Data pour l'affichage avancé
                if (this.ramDataAdvanced.length > 100) {
                  this.ramDataAdvanced.shift();
                }
                this.ramDataAdvanced.push([
                  values[0] * 1000,
                  parseFloat(values[1]),
                ]);
              });

              params = new URLSearchParams();
              // Récupération de la limite de ram
              params.append(
                  "query",
                  'kube_pod_container_resource_limits{namespace="' +
                  this.$route.params.namespace +
                  '", pod=~"' +
                  this.release[0].name +
                  '-.*", resource="memory"}'
              );

              axios
                  .post(
                      `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
                      params,
                      config
                  )
                  .then((data) => {
                    if (data.data.data.result[0]) {
                      this.ramDataMax = parseInt(
                          data.data.data.result[0].values[0][1]
                      );
                      this.ramDataBasic = parseInt(((ramTemp / this.ramDataMax) * 100).toFixed(0))
                    }
                  })
                  .catch(() => {
                  });
            }
          })
          .catch(() => {
          });
    }

    this.memoryTimeout = setTimeout(this.loadDataMemory, 15000);
  }

  public loadDataDisque() {
    if (this.isOnline && this.loadPrometheus) {
      let params = new URLSearchParams();

      params.append(
          "query",
          'sum without(instance, node) (kubelet_volume_stats_capacity_bytes{namespace="' +
          this.$route.params.namespace +
          '",persistentvolumeclaim=~".*' +
          this.release[0].name +
          '.*"}) - sum without(instance, node) (kubelet_volume_stats_available_bytes{namespace="' +
          this.$route.params.namespace +
          '",persistentvolumeclaim=~".*' +
          this.release[0].name +
          '.*"})'
      );
      axios
          .post(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
              params,
              config
          )
          .then((response) => {
            if (response.data.data && response.data.data.result) {
              let sum = 0;
              response.data.data.result.forEach((result: any) => {
                // Somme pour l'affichage basic
                sum += parseFloat(result.values[result.values.length - 1][1]);

                // Data pour l'affichage avancé
                result.values.forEach((data: any) => {
                  if (this.storageDataAdvanced.length > 100) {
                    this.storageDataAdvanced.shift();
                  }
                  this.storageDataAdvanced.push([
                    data[0] * 1000,
                    parseInt(data[1]),
                  ]);
                });
              });
              const storageTemp = sum;

              // Requete pour avoir l'espace de stockage libre
              params = new URLSearchParams();
              params.append(
                  "query",
                  'sum without(instance, node) (kubelet_volume_stats_capacity_bytes{namespace="' +
                  this.$route.params.namespace +
                  '",persistentvolumeclaim=~".*' +
                  this.release[0].name +
                  '.*"})'
              );
              axios
                  .post(
                      `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
                      params,
                      config
                  )
                  .then((data) => {
                    sum = 0;
                    data.data.data.result.forEach((r: any) => {
                      sum += parseFloat(r.values[r.values.length - 1][1]);
                    });

                    // Data pour l'affichage basic, en %
                    this.storageDataBasic = parseInt(((storageTemp * 100) / (storageTemp + sum)).toFixed(0))
                    this.storageDataMax = sum
                  });
            }
          });
    }
    this.diskTimeout = setTimeout(this.loadDataDisque, 15000);
  }

  public filterPublicServices(services: any) {
    if (this.filterPublicServicesResult.length === 0) {
      services.forEach((service: any) => {
        if (
            service.spec &&
            service.spec.type === "LoadBalancer" &&
            service.status &&
            service.status.loadBalancer &&
            service.status.loadBalancer.ingress
        ) {
          this.filterPublicServicesResult.push(service);
        }
        if (service.spec && service.spec.rules) {
          this.filterPublicServicesResult.push(service);
        }
      });
    }
    return this.filterPublicServicesResult;
  }

  public getStatusServices() {
    // Recupère le dernier index de status
    const s = this.status[this.status.length - 1];

    if (s) {
      if (s.status) {
        if (s.status.readyReplicas) {
          this.readyReplicas = s.status.readyReplicas;
        }
        if (s.status.replicas) {
          this.requestedReplicas = s.status.replicas;
        }
      }
    }

    // Si différent, l'app n'est pas lancée
    if (this.readyReplicas < this.requestedReplicas) {
      this.appReady = false;
      // Désactivation des requetes prometheus
      this.loadPrometheus = false;
      // Timeout de 1 sec
      this.appLoadTimeout = setTimeout(
          () => this.load(this.loadValue),
          1000
      );
    } else {
      this.appReady = true;
      // Envois des requetes prometheus
      this.loadPrometheus = true;
      // Clear du timeout
      clearTimeout(this.appLoadTimeout);
    }
  }

  public upgradeRelease() {
    this.$router.push({
      name: "ApplicationUpgrade",
      params: {
        cluster: "default",
        namespace: this.$route.params.namespace,
        app: this.$route.params.app,
      },
    }).catch(() => {});
  }

  @Watch('loaded')
  public onLoaded(val: any, old: any) {
    if (val !== old) {
      const values = YAML.parseAllDocuments(this.release[0].manifest)
          .map((doc) => doc.toJSON())
          .filter((value) => value && value.kind);
      if (val === values.length) {
        this.loading = false;
        if (this.appReady) {
          this.loadDataCPU();
          this.loadDataMemory();
          this.loadDataDisque();
        }
        this.loaded = 0;
      }
    }
  }

  @Watch('request')
  public onRequest(val: any, old: any) {
    if (val !== old) {
      if (
          this.status.filter((value: any) => value.kind === "Deployment").length === val
      ) {
        this.$emit("refreshRelease");
        this.appReady = false;
        this.request = 0;
      }
    }
  }

  @Watch('loader')
  public onLoader(val: any) {
    this.loading = val !== null;
  }

  @Watch('release')
  public onReleaseChange(val: any, old: any) {
    if (val !== old) {
      this.secrets = [];
      this.services = [];
      this.status = [];
      this.chart = [];
      this.loaded = 0;
      if (this.cpuTimeout) clearTimeout(this.cpuTimeout);
      if (this.memoryTimeout) clearTimeout(this.memoryTimeout);
      if (this.diskTimeout) clearTimeout(this.diskTimeout);
      this.loadValue = val;
      this.load(val);
    }
  }
}
