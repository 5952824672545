







































import DialogConfirmation from "@/components/dialogue/DialogConfirmationComponent.vue";

import {PROMO_DELETE, PROMO_STOP,} from "@/api/actions/pricing/const_promotion";
import {Component, Prop, Vue} from "vue-property-decorator";

@Component({
  components: {
    DialogConfirmation
  }
})
export default class PromotionTable extends Vue {
  @Prop() nomTable: any
  @Prop() promos: any

  public show: boolean = true

  get headers() {
    return [
      {
        text: this.$t("adminPromotionTable.code"),
        align: "center",
        sortable: false,
        value: "code",
      },
      {
        text: this.$t("adminPromotionTable.reduction"),
        align: "center",
        sortable: true,
        value: "reduction",
      },
      {
        text: this.$t("adminPromotionTable.debut"),
        align: "center",
        sortable: true,
        value: "dateDebut",
      },
      {
        text: this.$t("adminPromotionTable.fin"),
        align: "center",
        sortable: true,
        value: "dateFin",
      },
      {
        text: this.$t("adminPromotionTable.utilisatationsRestante"),
        align: "center",
        sortable: true,
        value: "utilisationsRestantes",
      },
      {
        text: this.$t("adminPromotionTable.utilisations"),
        align: "center",
        sortable: true,
        value: "nbUtilisations",
      },
      {
        text: this.$t("adminPromotionTable.arreter"),
        value: "actions",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("adminPromotionTable.supprimer"),
        value: "actionDelete",
        align: "center",
        sortable: false,
      },
    ];
  }

  get confirm(): DialogConfirmation {
    return this.$refs.confirm as DialogConfirmation
  }

  public stopPromo(item: any) {
    this.$store
        .dispatch(PROMO_STOP, item)
        .finally(() =>
            this.$emit("reloadPromo", this.$t("success.stopPromo", {msg: item.code}))
        );
  }

  public async deletePromo(item: any) {
    if (
        await this.confirm.open(
            this.$t("dialogConfirmation.confirmer"),
            this.$t("adminPromotionTable.codeDelete", {msg: item.code}), null
        )
    ) {
      this.$store
          .dispatch(PROMO_DELETE, item.id)
          .then(() => {
            this.$emit(
                "reloadPromo",
                this.$t("success.promoDelete", {msg: item.code})
            );
          })
          .catch((error) => {
            this.$emit("errorPromo", error);
          });
    }
  }
}
