




import {Chart} from "highcharts-vue";
import Highcharts from "highcharts";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({
  components: {
    highcharts: Chart,
  },
})
export default class CostChart extends Vue {
  @Prop() stats: any
  public loading: boolean = true;
  public columnChart: any;
  public reloadKey: number = 0;
  public themeType: any;

  get chartOptions() {
    return {
      chart: {
        type: "column",
      },

      title: null,

      exporting: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      yAxis: {
        min: 0,
        title: null,
      },

      xAxis: {
        categories: [
          this.$t("appCostChart.cpu"),
          this.$t("appCostChart.ram"),
          this.$t("appCostChart.disque"),
          this.$t("appCostChart.total"),
        ],
        crosshair: true,
      },

      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y} €</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },

      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
    };
  }

  get darkTheme() {
    return this.$vuetify.theme.dark;
  }

  public beforeMount() {
    let series = [];

    for (let i = 0; i < this.stats.length; i++) {
      series.push({name: this.stats[i].nom});
    }

    this.columnChart = Highcharts.merge(this.chartOptions, {
      title: null,
      series: series,
    });

    this.themeType = this.darkTheme
        ? this.$vuetify.theme.themes["dark"]
        : this.$vuetify.theme.themes["light"];
    this.changeColor();
  }

  public mounted() {
    this.initStats();
  }

  public initStats() {
    for (let i = 0; i < this.stats.length; i++) {
      this.columnChart.series[i].data = [
        this.stats[i].cpu,
        this.stats[i].ram,
        this.stats[i].disque,
        this.stats[i].total,
      ]
    }

    this.reloadKey++;
  }

  public changeColor() {
    this.columnChart.chart.backgroundColor = this.darkTheme
        ? "#424242"
        : this.themeType.backgroundContainers;
    this.columnChart.chart.columnBackgroundColor = this.darkTheme
        ? "#424242"
        : this.themeType.backgroundContainers;
    this.columnChart.chart.plotBackgroundColor = this.darkTheme
        ? "#424242"
        : this.themeType.backgroundContainers;
  }

  @Watch("darkTheme")
  public onThemeChange() {
    this.themeType = this.darkTheme
        ? this.$vuetify.theme.themes["dark"]
        : this.$vuetify.theme.themes["light"];
    this.changeColor();
    this.reloadKey++;
  }
}
