







































































import Vue from "vue";

import {Component} from "vue-property-decorator";

import axios from "axios";
import AdvancedPreference from "@/components/account/preferences/AdvancedPreference.vue";
import BasicPreference from "@/components/account/preferences/BasicPreference.vue";

@Component({
  components: {
    AdvancedPreference,
    BasicPreference,
  },
})
export default class Preferences extends Vue {
  // ADVANCED
  invitationNotif: number = 3;
  newApp: number = 3;
  disableApp: number = 3;
  activateApp: number = 3;
  deleteApp: number = 3;
  updateApp: number = 3;
  rollbackApp: number = 3;
  memberNotif: number = 3;
  memberLeaveNotif: number = 3;

  // BASIC
  basicMemberApp: number = 3;
  basicEnableDisableApp: number = 3;
  basicUpdateApp: number = 3;

  success: boolean = false;
  advancedDisplay: boolean = false;

  loadingBtn: boolean = false;

  get userData() {
    return this.$store.getters.userData;
  }

  get items() {
    return [
      this.$t("preferences.aucun"),
      this.$t("preferences.email"),
      this.$t("preferences.site"),
      this.$t("preferences.emailSite"),
    ];
  }

  public beforeMount(): void {
    this.loadPreferences();
  }

  public loadPreferences(): void {
    axios
      .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/preferences`)
      .then((response) => {
        if (response.data.preferences) {
          response.data.preferences.forEach((value: any) => {
            this.setValueInsideVar(value);
          });
        }
      })
      .catch(() => {
      });
  }

  public savePreferences(): void {
    this.loadingBtn = true;

    axios
      .post(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/preferences`, {
        preferences: [
          {type: "NEW_APP", value: this.newApp},
          {type: "UPDATE_APP", value: this.updateApp},
          {type: "ROLLBACK_APP", value: this.rollbackApp},
          {type: "INVITATION", value: this.invitationNotif},
          {type: "DISABLE_APP", value: this.disableApp},
          {type: "ACTIVATE_APP", value: this.activateApp},
          {type: "DELETE_APP", value: this.deleteApp},
          {type: "NEW_MEMBER", value: this.memberNotif},
          {type: "MEMBER_LEAVE", value: this.memberLeaveNotif},
          {type: "SUPPORT_MESSAGE", value: 2},
        ],
      })
      .then(() => {
        this.success = true;
        this.loadingBtn = false;
      })
      .catch(() => {
      })
  }

  public setValueInsideVar(value: { type: string; value: number }): void {
    switch (value.type) {
      case "NEW_APP":
        this.newApp = value.value;
        break;
      case "UPDATE_APP":
        if (value.value < this.basicUpdateApp) {
          this.basicUpdateApp = value.value;
        }

        this.updateApp = value.value;
        break;
      case "ROLLBACK_APP":
        if (value.value < this.basicUpdateApp) {
          this.basicUpdateApp = value.value;
        }

        this.rollbackApp = value.value;
        break;
      case "INVITATION":
        this.invitationNotif = value.value;
        break;
      case "DISABLE_APP":
        if (value.value < this.basicEnableDisableApp) {
          this.basicEnableDisableApp = value.value;
        }

        this.disableApp = value.value;
        break;
      case "ACTIVATE_APP":
        if (value.value < this.basicEnableDisableApp) {
          this.basicEnableDisableApp = value.value;
        }

        this.activateApp = value.value;
        break;
      case "DELETE_APP":
        this.deleteApp = value.value;
        break;
      case "NEW_MEMBER":
        if (value.value < this.basicMemberApp) {
          this.basicMemberApp = value.value;
        }

        this.memberNotif = value.value;
        break;
      case "MEMBER_LEAVE":
        if (value.value < this.basicMemberApp) {
          this.basicMemberApp = value.value;
        }

        this.memberLeaveNotif = value.value;
        break;
    }
  }

  public changeValueBasic(event: { type: string; value: number }): void {
    switch (event.type) {
      case "newApp":
        this.newApp = event.value;
        break;
      case "deleteApp":
        this.deleteApp = event.value;
        break;
      case "updateRollback":
        this.updateApp = event.value;
        this.rollbackApp = event.value;
        break;
      case "enableDisable":
        this.activateApp = event.value;
        this.disableApp = event.value;
        break;
      case "join":
        this.invitationNotif = event.value;
        break;
      case "member":
        this.memberNotif = event.value;
        this.memberLeaveNotif = event.value;
        break;
    }
  }

  public changeValueAdvanced(event: { type: string; value: number }): void {
    this.setValueInsideVar(event);
  }
}
