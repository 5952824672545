export default {
  // components/dialogue/DialogueConfirmationComponent.vue
  dialogConfirmation: {
    titleDelete: 'Confirmation',
    textDelete: 'Are you sur to delete your account ? <small><b>THIS OPERATION IS IRREVERSIBLE !</b></small>',
    annuler: 'Cancel',
    confirmer: 'Confirm',
  },

  // components/dialogue/DialogueInputComponent.vue
  dialogInput: {
    titleCreateEntreprise: 'Company creation',
    textCreateEntreprise: 'You are about to create a new company! <small><b>THIS OPERATION IS IRREVERSIBLE !</b></small>',
    titleJoinEntreprise: 'Join a company',
    textJoinEntreprise: 'You are about to send a request to join a company',
    entrepriseLabel: 'Company name',
    annuler: 'Cancel',
    confirmer: 'Confirm',
  },

  // App.vue
  app: {
    activation: 'Your account is awaiting activation',
    mailActivation: 'Resend email',
    invitation: 'You currently have a pending request to join the company {msg}',
  },

  // components/app/appBar/desktop/AppBar.vue + components/app/appBar/mobile/AppBarMobile.vue
  appbar: {
    application: 'Applications',
    catalogue: 'Catalog',
    administration: 'Administration',
    namespaces: 'Namespaces',
    entreprises: 'Companies',
    compte: 'My account',
    deconnexion: 'Logout',
  },

  // views/Account.vue
  compte: {
    header: 'Account management',
    information: 'My information',
    entreprise: 'Company {msg}',
    informationEntreprise: 'Company {msg} informations',
    historique: 'History',
    preference: 'My preferences',
    support: 'Support',
    session: 'My sessions',
    supprimer: 'Delete my account',
  },

  // views/Action.vue
  action: {
    connexion: 'Login',
  },

  // views/AdminAddApplication.vue
  adminAddApp: {
    header: 'Add application inside catalog',
    application: 'Application',
    namespace: 'Namespace',
    namespaceHint: 'If empty, the application will be available for everybody',
    ajouter: 'Add',
    readme: 'Readme',
  },

  // components/admin/FormBuilder.vue
  formBuilder: {
    configuration: 'Configuration',
    description: 'Description',
    defaut: 'Default value (optional)',
    visible: 'Visible ?',
    text: 'Text',
    nombre: 'Number',
    mdp: 'Password',
    boolean: 'Enable/Disable',
    depend: 'Dependent key (only on boolean value)'
  },

  // views/AdminEditApplication.vue
  adminEditApp: {
    header: 'Update an application inside catalog',
    namespace: 'Namespace',
    namespaceHint: 'If empty, the application will be available for everybody',
    modifier: 'Update',
    readme: 'Readme',
    annuler: 'Cancel'
  },

  // view/Administration.vue
  administration: {
    statistiques: 'Statistics',
    applications: 'Applications',
    utilisateurs: 'Users',
    entreprises: 'Companies',
    pricing: 'Pricing',
    promotion: 'Promotion code',
    support: 'Support',
    logs: 'Logs'
  },

  // view/Applications.vue
  applications: {
    showAll: 'Show all applications from all namespaces',
    noApp: 'No application is deployed for the moment',
    deployer: 'Deploy here'
  },

  // view/Catalogue.vue
  catalogue: {
    filtres: 'Filters',
    noService: 'Aucun service n\'est déployé pour le moment. Contactez l\'<strong>ADMIN</strong> afin de <strong>résoudre</strong> le problème.',
    recherche: 'Search an application',
    noApp: 'No application is deployed for the moment',
  },

  // view/Deploiement.vue
  deploiement: {
    header: 'Configuration - <small class="text-body-1">Deploy with your own configuration</small>',
    nom: 'Name',
    version: 'Select version',
    basic: 'Basic',
    avance: 'Advanced',
    premium: 'Premium',
    deploiementLabel: 'Selection of the deployment plan',
    commander: 'Order',
    exist: "An application already has the same name"
  },

  // views/ForgotPassword.vue
  forgotPassword: {
    header: 'Enter your user account email address and we will send you a reset password link',
    email: 'Email address',
    envoyer: 'Send reset link',
    retour: 'Back',
  },

  // views/Login.vue
  login: {
    header: 'Welcome on the sign in panel. Please sign in to access our services.',
    username: 'Username',
    mdp: 'Password',
    mdpLost: 'Forgot your password ?',
    connexion: 'Sign in',
    inscription: 'Sign up',
    google: 'Continue with Google',
    github: 'Continue with GitHub',
  },

  // views/NoEntreprise.vue
  noEntreprise: {
    title: 'Oh no :(',
    subtitle: 'You are not currently in any company ...',
    message: 'To fix that, please click on any button below',
  },

  // components/error/ErrorComponent.vue
  errorComponent: {
    accueil: 'Home',
    creer: 'Create a company',
    rejoindre: 'Join a company',
    supprimer: 'Delete my account'
  },

  // view/NotFoundPage.vue
  notFound: {
    subtitle: 'OH OH! Looks like you are lost !',
    message: 'The page you are looking for does not exist. How you got here is a mystery. But you can click the button below to return to the home page.'
  },

  // view/Error50x/Error50x.vue
  error50x: {
    subtitle: 'OH OH! Looks like there is an error !',
    500: 'There is an internal error',
    502: 'The gateway received an invalid response',
    503: 'The service is not available at the moment',
    504: 'The server did not respond in time',
  },

  // view/Panier.vue
  panier: {
    noApp: 'There is no application inside your cart'
  },

  // views/Signup.vue
  signup: {
    header: 'Create account',
    nom: 'Last Name',
    prenom: 'First Name',
    username: 'Usersame',
    mail: 'Email address',
    entreprise: 'Company',
    mdp: 'Password',
    confirmMdp: 'Password confirmation',
    cgu: 'Accept the general conditions of use',
    retour: 'Back',
    inscription: 'Sign up'
  },

  // views/UpgradeConfiguration.vue
  upgrade: {
    header: 'Update - <small class="text-body-1">Update my application</small>',
    nom: 'Name',
    version: 'Select version',
    update: 'Update',
    annuler: 'Cancel'
  },

  // components/ReadmeComponent.vue
  readme: {
    fermer: 'Close',
  },

  // components/Release.vue
  release: {
    url: 'Access URL',
    upgrade: 'Upgrade',
    rollback: 'Rollback',
    eteindre: 'Shutdown',
    allumer: 'Start',
    aJour: 'UP TO DATE',
    outdated: 'UPDATE AVAILABLE {msg}',
    demarre: 'start',
    lancement: 'Your application is launching, please wait'
  },

  // components/account/preferences/AdvancedPreferences.vue
  advancedPreference: {
    serviceDeploy: 'Receive notification when a new service is deployed',
    serviceSupprimer: 'Receive notification when a user deletes a service',
    update: 'Receive notification when a user updates a service',
    rollback: 'Receive notification when a user rollbacks a service',
    active: 'Receive notification when a user activates a service',
    desactive: 'Receive notification when a user desactivates a service',
    invitation: 'Receive notification when a user requests to join the company. Requires having the Administrator role in the company',
    rejoint: 'Receive notification when a user joins the company',
    quitte: 'Receive notification when a user leaves the company',
  },

  // components/account/preferences/BasicPreference.vue
  basicPreference: {
    serviceDeploy: 'Receive notification when a new service is deployed',
    serviceSupprimer: 'Receive notification when a user deletes a service',
    updateRollback: 'Receive notification when a user updates/rollbacks a service',
    activeDesactive: 'Receive notification when a user activates/desactivates a service',
    rejointQuitte: 'Receive notification when a user joins/leaves the company',
    invitation: 'Receive notification when a user requests to join the company. Requires having the Administrator role in the company',
  },

  // components/account/preferences/Preferences.vue
  preferences: {
    header: 'My preferences',
    enregistrer: 'Save',
    affichageAvance: 'Advanced display',
    aucun: 'None',
    email: 'Email',
    site: 'Site',
    emailSite: 'Email + Site',
    success: 'Your preferences have been updated !',
  },

  // components/account/support/NewEntrepriseTicket.vue
  newEntrepriseTicket: {
    header: 'New ticket',
    envoyer: 'Send ticket',
    fermer: 'Close',
    sujet: 'Subject',
    noApp: 'No application',
    label: 'Application',
    addScreenshot: 'Add screenshot',
  },

  // components/account/support/NewUserTicket.vue
  newUserTicket: {
    header: 'New ticket',
    envoyer: 'Send ticket',
    fermer: 'Close',
    sujet: 'Subject',
    addScreenshot: 'Add screenshot',
  },

  // components/account/support/Support.vue
  support: {
    header: 'Support',
    id: 'ID',
    utilisateur: 'User',
    sujet: 'Subject',
    type: 'Type',
    app: 'Application',
    nomApp: 'Application\'s name',
    entreprise: 'Company',
    status: 'Status',
  },

  // components/account/support/SupportCompany.vue
  supportCompany: {
    header: '{msg}\'s tickets',
    tooltip: 'Create a new ticket for the company',
    entreprise: 'Company',
    id: 'ID',
    sujet: 'Subject',
    type: 'Type',
    app: 'Application',
    appName: 'Name',
    date: 'Date',
    status: 'Status',
    details: 'Details',
  },

  // components/account/support/SupportUser.vue
  supportUser: {
    header: 'My tickets',
    tooltip: 'Create a new ticket',
    id: 'ID',
    utilisateur: 'User',
    type: 'Type',
    sujet: 'Subject',
    date: 'Date',
    status: 'Status',
    details: 'Details',
  },

  // components/account/support/TicketDetailsUser.vue
  ticketDetailsUser: {
    header: 'Ticket : {msg}',
    details: 'Ticket\'s details',
    type: 'Type',
    date: 'Date',
    lastEdit: 'Last edit',
    status: 'Status',
  },

  // components/account/support/TicketDetailsEntreprise.vue
  ticketDetailsEntreprise: {
    header: 'Ticket : {msg}',
    entreprise: 'Company',
    application: 'Application',
    nomApp: 'Application\'s Name',
    details: 'Ticket\'s details',
    type: 'Type',
    date: 'Date',
    lastEdit: 'Last edit',
    status: 'Status',
  },

  // components/account/support/TicketDetails.vue
  ticketResponse: {
    label: 'Answer',
  },

  // components/account/EditAccount.vue
  editAccount: {
    header: 'Edit my informations',
    avatarLabel: 'Choose an avatar',
    nom: 'Last Name',
    prenom: 'First Name',
    username: 'Usersame',
    mail: 'Email address',
    mdp: 'Actual password',
    newMdp: 'New password',
    newMdpConfirm: 'New password confirmation',
    enregistrer: 'Save',
  },

  // components/account/Factures.vue
  facture: {
    header: 'Bills',
    application: 'Application',
    nomApplication: 'Application name',
    plan: 'Plan',
    date: 'Date',
    telecharger: 'Download',
  },

  // components/account/MonthlyFactures.vue
  monthlyFacture: {
    header: 'Monthly Bills',
    application: 'Application',
    nomApplication: 'Application name',
    date: 'Date',
    telecharger: 'Download',
  },

  // components/account/History.vue
  historique: {
    header: 'History',
    username: 'Username',
    entreprise: 'Company',
    date: 'Date',
    action: 'Action',
  },

  // components/account/InfoCompany.vue
  informationEntreprise: {
    header: 'Company {msg} informations',
    headerAdmin: 'Edit {msg}\'s informations',
    entrepriseLabel: 'Company name',
    adresse: 'Address',
    ville: 'City',
    codePostal: 'Postal code',
    sauvegarder: 'Save'
  },

  // components/account/Invitations.vue
  invitation: {
    header: 'Invitations',
    nombre: 'Invitation count : {msg}',
    username: 'Username',
    mail: 'Email address',
    date: 'Date',
    actions: 'Actions',
  },

  // components/account/Mycompany.vue
  myCompany: {
    header: 'My company',
    tooltip: 'Create or join a company',
    username: 'Username',
    role: 'Role',
    mail: 'Email address',
    action: 'Action',
    admin: 'Set admin',
  },

  // components/account/Sessions.vue
  sessions: {
    header: 'My sessions',
    logout: 'Logout all sessions',
    ip: 'IP address',
    start: 'Beginning of the session',
    last: 'Last access to the session',
    action: 'Action'
  },

  // components/administration/entreprises/Entreprises.vue
  adminEntreprise: {
    header: 'List of companies',
    recherche: 'Find a company',
    nom: 'Company name',
    date: 'Creation date',
    page: 'Conpany page',
    detailsHeader: 'Company {msg}',
    detailsRetour: 'Back',
  },

  // components/administration/entreprises/Logs.vue
  adminLogs: {
    header: 'Logs of API',
  },

  // components/administration/pricing/Pricing.vue
  adminPricing: {
    header: 'Pricing',
    cpu: 'CPU price (1000m)',
    ram: 'RAM price (1Gi)',
    disque: 'Disk price',
    sauvegarder: 'Save',
  },

  // components/administration/pricing/Kubecost.vu
  kubecost: {
    header: 'Application\'s cost',
    nom: 'Name',
    namespace: 'Namespace',
    cpu: 'CPU',
    ram: 'RAM',
    disque: 'DISK',
    total: 'Total',
    details: 'Details',
  },

  // components/administration/promotions/CreatePromotion.vue
  adminCreatePromotion: {
    header: 'Promotion code creation',
    code: 'Promotion Code',
    reduction: 'Reduction (%)',
    debut: 'Start date',
    fin: 'End date',
    utilisationMax: 'Maximum number of uses',
    subtitle: 'Only an end date or a maximum number of uses is mandatory',
    fermer: 'Close',
    creer: 'Create',
  },

  // components/administration/promotions/Promotion.vue
  adminPromotion: {
    tooltipCreate: 'Add promotion code',
    aVenir: 'Upcoming promotions',
    enCours: 'Current promotions',
    fini: 'Promotions ended',
  },

  // components/administration/promotions/PromotionTable.vue
  adminPromotionTable: {
    codeDelete: 'Are you sure to delete <i>{msg}</i> ? <small><b>THIS OPERATION IS IRREVERSIBLE !</b></small>',
    code: 'Promotion Code',
    reduction: 'Reduction (%)',
    debut: 'Start date',
    fin: 'End date',
    utilisatationsRestante: 'Remaining uses',
    utilisations: 'Uses',
    arreter: 'Stop',
    supprimer: 'Delete',
  },

  // components/administration/stats/LastEvent.vue
  lastEvent: {
    header: 'Last events on Autoscaling',
    utilisateur: 'Username',
    entreprise: 'Entreprise',
    date: 'Date',
    action: 'Action',
  },

  // components/administration/stats/Stats.vue
  stats: {
    header: 'Autoscaling\'s statistics',
  },

  // components/administration/stats/StatsApp.vue
  statsApp: {
    header: 'Application Informations',
    application: 'Number of applications',
    lancer: 'Launched applications',
    pause: 'Application paused',
  },

  // components/administration/stats/StatsGenerales.vue
  statsGenerales: {
    header: 'General informations',
    version: 'Version',
    compteActif: 'Number of active accounts',
    compteTotal: 'Total number of accounts',
    appActive: 'Number of active applications',
    appTotal: 'Total number of applications',
  },

  // components/administration/stats/StatsUtilisateur.vue
  statsUtilisateur: {
    header: 'Users Informations',
    compte: 'Nombre of accounts',
    verifie: 'Audited accounts',
    nonVerifie: 'Unaudited accounts',
  },

  // components/administration/stats/StatsWeekly.vue
  statsWeeky: {
    header: 'Weekly informations',
    lundi: 'Monday',
    mardi: 'Tuesday',
    mercredi: 'Wednesday',
    jeudi: 'Thusday',
    vendredi: 'Friday',
    samedi: 'Saturday',
    dimanche: 'Sunday',
    newUser: 'Registered users',
    delUser: 'Deleted users',
    newApp: 'Deployed applications',
    delApp: 'Removed applications',
  },

  // components/administration/stats/utilisateur/Utilisateurs.vue
  adminUtilisateur: {
    header: 'Users list',
    rechercheLabel: 'Find a user',
    rechercheTypeLabel: 'Type of research',
    id: 'ID',
    nom: 'Last Name',
    prenom: 'First Name',
    username: 'Usersame',
    mail: 'Email address',
    date: 'Register date',
    verifier: 'Check',
    activer: 'Activate/Deactivate',
    details: 'Details',
    supprimer: 'Delete',
  },

  // components/administration/stats/utilisateur/UtilisateursDetails.vue
  adminUtilisateurDetails: {
    sessions: 'Sessions',
    details: 'Details',
    id: 'ID',
    username: 'Usersame',
    mail: 'Email address',
    entreprises: 'Companies',
    noSession: 'No session',
    ip: 'IP address',
    start: 'Beginning of the session',
    last: 'Last access to the session',
    action: 'Logout',
    logout: 'Logout',
    desactiver: 'Disable',
    activer: 'Enable',
    supprimer: 'Delete',
  },

  // components/administration/support/Support.vue
  adminSupport: {
    header: 'Support',
    rechercheLabel: 'Search a ticket',
    rechercheTypeLabel: 'Type of search',
  },

  // components/administration/Applications.vue
  adminApplications: {
    rechercheLabel: 'Find an application',
    noApp: 'No application found',
    addApp: 'Add an application to the catalog',
    refresh: 'Refresh repositories',
    supprimer: 'Are you sure you want to remove the <i>{msg}</i> application from the catalog?',
    enableDisableDeploy: 'Allows to authorize or not the deployments on the cluster'
  },

  // components/admin/ApplicationAdmin.vue
  applicationAdmin: {
    supprimer: 'Delete',
  },

  // components/basket/PriceDetails.vue
  priceDetails: {
    header: 'Summary',
    adresse: 'Billing address',
    code: 'Promotion Code',
    appliquer: 'Apply',
    htc: 'Subtotal',
    reduction: 'Reduction',
    taxe: 'Tax',
    total: 'Total',
    order: 'Order',
    cancel: 'Cancel the order',
  },

  // components/release/historique/Historique.vue
  appHistorique: {
    empty: 'No app history is available at the moment',
    username: 'Username',
    date: 'Date',
    action: 'Action',
    details: 'Details',
  },

  // components/release/secrets/Secrets.vue
  appSecrets: {
    empty: 'The current application does not include any secrets',
    typeSecret: 'Password type',
    secret: 'Password',
  },

  // components/release/cost/Cost.vue
  appCost: {
    label: 'Window',
    auj: 'Today',
    hier: 'Yesterday',
    semaine: 'Week',
    semainePrecedente: 'Last week',
    mois: 'Month',
  },

  // components/release/cost/CostTable.vue
  appCostTable: {
    nom: 'Name',
    cpu: 'CPU',
    ram: 'RAM',
    disque: 'DISK',
    total: 'Total',
  },

  // components/release/cost/CostCharts.vue
  appCostChart: {
    cpu: 'CPU',
    ram: 'RAM',
    disque: 'DISK',
    total: 'TOTAL',
  },

  // components/CookieConsent.vue
  cookieConsent: {
    message: 'Autoscaling On Demand need to use cookies for analyse',
    ok: 'Accept',
    refuser: 'Refuse',
  },

  error: {
    deployDisabled: 'Deployments are disabled, please contact support with any questions.\n',
    cannotDelete: 'Cannot delete the account !',
    companyExist: 'This company already exists!',
    error: 'An error has occurred !',
    noAuthorization: 'You do not have the authorization to load this element',
    authServerDown: 'The authentication server is not accessible',
    authDisable: 'Your account is deactivated!',
    authWrong: 'You entered the wrong username or password!',
    internalError: 'Internal error',
    invalidAction: 'Invalid action',
    alreadyExistUser: 'An account with the same username or email already exists',
    alreadyValidate: 'Account already validated',
    alreadySend: 'You have already sent a request to this company!',
    accountNotExist: 'This account does not exist!',
    noInvitation: 'No invitation for this user',
    noApp: 'No application',
    reductionRequired: 'You must specify a reduction',
    reductionExpire: 'You must specify a number of use or an end date',
    noData: 'No data available',
    noAdresse: 'Cannot order a billing address is missing',
    noPrice: 'No price saved',
    emptyReduction: 'Please set a reduction',
    invalidPromo: 'Invalid promotion code',
  },

  success: {
    updatePricing: 'Pricing updated',
    promoCreer: 'Promotion code {msg} created',
    promoDelete: 'Promotion code {msg} deleted',
    stopPromo: 'Promotion code {msg} stoped',
    usedPromo: 'Promotion code {msg} used',
    ticketOuvert: 'Ticket open',
    ticketFermer: 'Ticket close',
  }
}
