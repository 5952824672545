


























































import axios from "axios";
import AdvancedMetrics from "@/components/release/metrics/AdvancedMetrics.vue";
import moment from "moment";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({
  components: {
    AdvancedMetrics
  }
})
export default class UltraMetrics extends Vue {
  @Prop() release: any

  public podsName: any = []

  public podsCPU: any = new Map()
  public podsCPULoaded: number = 0
  public loadedCPU: boolean = false

  public podsRam: any = new Map()
  public podsRamLoaded: number = 0
  public loadedRAM: boolean = false

  public podsStorage: any = new Map()
  public podsStorageLoaded: number = 0
  public loadedStorage: boolean = false

  public cpuTimeout: any = {}
  public ramTimeout: any = {}
  public storageTimeout: any = {}

  public reloadCharts: number = 0

  public config: any = {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  }

  get darkTheme() {
    return this.$vuetify.theme.dark;
  }

  public mounted() {
    this.loadPods(this.release);
  }

  public loadPods(val: any) {
    const params = new URLSearchParams();
    params.append(
        "query",
        `kube_pod_info{namespace="${this.$route.params.namespace}", pod=~"${val[0].name}.*"}`
    );

    axios
        .post(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
            params,
            this.config
        )
        .then((response) => {
          this.podsName = [];

          response.data.data.result.forEach((pod: any) => {
            this.podsName.push(pod.metric.pod);
          });

          // Supprime les doublons
          this.podsName = Array.from(new Set(this.podsName));

          this.loadCPU();
          this.loadMemory();
          this.loadStorage();
        })
        .catch(() => {
        });
  }

  public loadCPU() {
    this.podsName.forEach((podName: any) => {
      const params = new URLSearchParams();

      params.append(
          "query",
          `kube_pod_container_resource_limits{resource="cpu", pod="${podName}", container=~".*"}`
      );

      axios
          .post(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
              params,
              this.config
          )
          .then((response) => {
            let maxCPU = 0.5; // Limite si pas de limite appliquer à l'app
            let name = this.release[0].chart.metadata.name;

            if (response.data.data.result.length > 0) {
              maxCPU = parseFloat(
                  response.data.data.result[0].values[
                  response.data.data.result[0].values.length - 1
                      ][1]
              );

              name = response.data.data.result[0].metric.container;
            }

            const params2 = new URLSearchParams();

            params2.append(
                "query",
                `node_namespace_pod_container:container_cpu_usage_seconds_total:sum_irate{pod="${podName}", container="${name}"}`
            );

            axios
                .post(
                    `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
                    params2,
                    this.config
                )
                .then((data) => {
                  let cpuData: any[][] = [];

                  if (this.podsCPU.has(podName)) {
                    cpuData = this.podsCPU.get(podName).data;
                  }

                  if (data.data.data.result.length > 0) {
                    data.data.data.result[0].values.forEach((values: any) => {
                      if (cpuData.length > 100) {
                        cpuData.shift();
                      }

                      cpuData.push([
                        moment(values[0] * 1000).unix() * 1000,
                        parseFloat(values[1]),
                      ]);
                    });
                  }

                  this.podsCPU.set(podName, {data: cpuData, max: maxCPU});
                  // Permet de savoir si tout les pods cpu sont chargés
                  this.podsCPULoaded = this.podsCPU.size;
                })
                .catch(() => {
                });
          })
          .catch(() => {
          });
    });

    this.cpuTimeout = setTimeout(this.loadCPU, 15000);
  }

  public loadMemory() {
    this.podsName.forEach((podName: any) => {
      const params = new URLSearchParams();

      params.append(
          "query",
          `sum by(pod) (container_memory_usage_bytes{pod="${podName}", container=~".*", container!="", container!="POD"})`
      );

      axios
          .post(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
              params,
              this.config
          )
          .then((response) => {
            let ramData: any[][] = [];

            if (this.podsRam.has(podName)) {
              ramData = this.podsRam.get(podName).data;
            }

            if (response.data.data.result.length > 0) {
              response.data.data.result[0].values.forEach((values: any) => {
                if (ramData.length > 100) {
                  ramData.shift();
                }

                ramData.push([values[0] * 1000, parseFloat(values[1])]);
              });
            }

            // Récupération du max ram pour le pod
            const params2 = new URLSearchParams();

            params2.append(
                "query",
                `kube_pod_container_resource_limits{resource="memory", pod="${podName}", container=~".*"}`
            );

            axios
                .post(
                    `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
                    params2,
                    this.config
                )
                .then((data) => {
                  // ramMax si pas de limite
                  let ramMax = 3000000000;

                  if (data.data.data.result.length > 0) {
                    ramMax = parseInt(data.data.data.result[0].values[0][1]);
                  }

                  this.podsRam.set(podName, {data: ramData, max: ramMax});
                  // Permet de savoir si tout les pods ram sont chargés
                  this.podsRamLoaded = this.podsRam.size;
                })
                .catch(() => {
                });
          })
          .catch(() => {
          });
    });

    this.ramTimeout = setTimeout(this.loadMemory, 15000);
  }

  public loadStorage() {
    this.podsName.forEach((podName: any) => {
      // Stockage pris
      const params = new URLSearchParams();

      params.append(
          "query",
          `sum without(instance, node) (kubelet_volume_stats_capacity_bytes{persistentvolumeclaim=~".*${podName}"}) - sum without(instance, node) (kubelet_volume_stats_available_bytes{persistentvolumeclaim=~".*${podName}"})`
      );

      axios
          .post(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
              params,
              this.config
          )
          .then((response) => {
            if (response.data.data.result.length > 0) {
              let storageData: any[][] = [];

              if (this.podsStorage.has(podName)) {
                storageData = this.podsStorage.get(podName).data;
              }

              response.data.data.result.forEach((result: any) => {
                result.values.forEach((data: any) => {
                  if (storageData.length > 100) {
                    storageData.shift();
                  }

                  storageData.push([data[0] * 1000, parseInt(data[1])]);
                });
              });

              // Stockage dispo
              const params2 = new URLSearchParams();

              params2.append(
                  "query",
                  `sum without(instance, node) (kubelet_volume_stats_capacity_bytes{persistentvolumeclaim=~".*${podName}"})`
              );
              axios
                  .post(
                      `${process.env.VUE_APP_SPRING_API_BASEURL}/api/${this.$route.params.namespace}/prometheus`,
                      params2,
                      this.config
                  )
                  .then((data) => {
                    let sum: number = 0;

                    data.data.data.result.forEach((r: any) => {
                      sum += parseFloat(r.values[r.values.length - 1][1]);
                    });

                    this.podsStorage.set(podName, {
                      data: storageData,
                      max: sum,
                    });
                    // Permet de savoir si tout les pods ram sont chargés
                    this.podsStorageLoaded = this.podsStorage.size;
                  })
                  .catch(() => {
                  });
            } else {
              // Si pas de données
              this.podsStorage.set(podName, {data: [], max: 0});
              this.podsStorageLoaded = this.podsStorage.size;
            }
          })
          .catch(() => {
          });
    });
    this.storageTimeout = setTimeout(this.loadStorage, 15000);
  }

  @Watch('release')
  public onReleaseChange(val: any) {
    this.release = val;
    this.loadPods(this.release);
  }

  @Watch('podsCPULoaded')
  public onPodsCPULoaded(val: any) {
    this.loadedCPU = val === this.podsName.length;
  }

  @Watch('podsRamLoaded')
  public onPodsRamLoaded(val: any) {
    this.loadedRAM = val === this.podsName.length;
  }

  @Watch('podsStorageLoaded')
  public onPodsStorageLoaded(val: any) {
    this.loadedStorage = val === this.podsName.length;
  }

  public destroyed() {
    clearTimeout(this.cpuTimeout);
    clearTimeout(this.ramTimeout);
    clearTimeout(this.storageTimeout);
  }
}
