





















































































import PromotionTable from "@/components/administration/promotions/PromotionTable.vue";
import CreatePromotion from "@/components/administration/promotions/CreatePromotion.vue";

import {PROMO_REQUEST} from "@/api/actions/pricing/const_promotion";
import {Component, Vue} from "vue-property-decorator";

const moment = require("moment");

@Component({
  components: {
    PromotionTable,
    CreatePromotion
  }
})
export default class Promotions extends Vue {
  public loading: boolean = true
  public promoKey: number = 0
  public promos: any = []
  public promosIncomming: any = []
  public promosStarted: any = []
  public promosFinish: any = []
  public success: boolean = false
  public successMessage: String = ""
  public error: boolean = false
  public errorMessage: String = ""

  get input(): CreatePromotion {
    // Obligatoire sinon il ne reconnait pas la méthode open de DialogConfirmation
    return this.$refs.input as CreatePromotion;
  }

  public mounted() {
    this.loadPromotions();
  }

  public loadPromotions() {
    this.eraseData();

    this.$store
        .dispatch(PROMO_REQUEST)
        .then((response) => {
          this.promos = response;
          this.sortPromotions();
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error;
        })
        .finally(() => (this.loading = false));
  }

  public sortPromotions() {
    const today = moment().startOf("day");

    this.promos.forEach((promo: any) => {
      const dateDebut = moment(promo.dateDebut, "DDMMYYYY");

      let dateFin = promo.dateFin;

      if (dateFin !== null) {
        dateFin = moment(promo.dateFin, "DDMMYYYY");
      }

      // Promo a venir
      if (dateDebut > today && dateFin !== null && dateFin > today) {
        this.promosIncomming.push(promo);
      } else {
        if (
            (dateFin !== null && dateFin <= today) ||
            (promo.utilisationsRestantes !== null &&
                promo.utilisationsRestantes <= 0)
        ) {
          // Promo finie
          this.promosFinish.push(promo);
        } else {
          // Promo en cours
          this.promosStarted.push(promo);
        }
      }
    });
  }

  public createPromotion() {
    this.input.open();
  }

  public reloadPromo(response: any) {
    if (response) {
      this.success = true;
      this.successMessage = response;
    }

    this.loadPromotions();
    this.promoKey++;
  }

  public errorPromo(response: any) {
    this.error = true;
    this.errorMessage = response;
  }

  public eraseData() {
    this.promos = [];
    this.promosIncomming = [];
    this.promosStarted = [];
    this.promosFinish = [];
  }
}
