































import {Chart} from "highcharts-vue";
import Highcharts from "highcharts";
import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component({
  components: {
    highcharts: Chart,
  }
})
export default class BasicMetrics extends Vue {
  @Prop() cpu: any
  @Prop() ram: any
  @Prop() storage: any
  @Prop() theme: any

  public themeType: any = []
  public reloadKey: number = 0

  get gaugeOptions() {
    return {
      chart: {
        type: "solidgauge",
      },

      title: null,

      exporting: {
        enabled: false,
      },

      tooltip: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      pane: {
        center: ["50%", "75%"],
        size: "100%",
        startAngle: -90,
        endAngle: 90,
        background: {
          backgroundColor: "#EEE",
          innerRadius: "60%",
          outerRadius: "100%",
          shape: "arc",
        },
      },

      yAxis: {
        stops: [
          [0.1, "#55BF3B"],
          [0.5, "#DDDF0D"],
          [0.9, "#DF5353"],
        ],
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: null,
        tickAmount: 2,
        labels: {
          y: 16,
        },
      },

      plotOptions: {
        solidgauge: {
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true,
          },
        },
      },
    }
  }

  get cpuGauge(): any {
    return Highcharts.merge(this.gaugeOptions, {
      title: {
        text: "CPU",
        align: "center",
        verticalAlign: "top",
      },

      yAxis: {
        min: 0,
        max: 100,
      },

      series: [
        {
          name: "Consommation CPU",
          data: [this.cpu],
          dataLabels: {
            format:
                '<div style="text-align:center">' +
                '<span style="font-size:25px">{y}</span>&nbsp' +
                '<span style="font-size:12px;opacity:0.4">%</span>' +
                "</div>",
          },
        },
      ],
    })
  }

  get ramGauge(): any {
    return Highcharts.merge(this.gaugeOptions, {
      title: {
        text: "RAM",
        align: "center",
        verticalAlign: "top",
      },

      yAxis: {
        min: 0,
        max: 100,
      },

      series: [
        {
          name: "Consommation RAM",
          data: [this.ram],
          dataLabels: {
            format:
                '<div style="text-align:center">' +
                '<span style="font-size:25px">{y}</span>&nbsp' +
                '<span style="font-size:12px;opacity:0.4">%</span>' +
                "</div>",
          },
        },
      ],
    })
  }

  get storageGauge(): any {
    return Highcharts.merge(this.gaugeOptions, {
      title: {
        text: "Disque",
        align: "center",
        verticalAlign: "top",
      },

      yAxis: {
        min: 0,
        max: 100,
      },

      series: [
        {
          name: "Consommation espace",
          data: [this.storage],
          dataLabels: {
            format:
                '<div style="text-align:center">' +
                '<span style="font-size:25px">{y}</span>&nbsp' +
                '<span style="font-size:12px;opacity:0.4">%</span>' +
                "</div>",
          },
        },
      ],
    })
  }

  @Watch('cpu')
  public onCPUChange(val: any, old: any) {
    if (val !== old) {
      this.cpuGauge.series[0].data = [val];
    }
  }

  @Watch('ram')
  public onRamChange(val: any, old: any) {
    if (val !== old) {
      this.ramGauge.series[0].data = [val];
    }
  }

  @Watch('storage')
  public onStorageChange(val: any, old: any) {
    if (val !== old) {
      this.storageGauge.series[0].data = [val];
    }
  }

  @Watch('theme')
  public onThemeChange(val: any, old: any) {
    if (val !== old) {
      this.theme = val;
    }

    // Récupération du theme actuel
    this.themeType = this.theme
        ? this.$vuetify.theme.themes["dark"]
        : this.$vuetify.theme.themes["light"];

    this.changeColors(this.cpuGauge);
    this.changeColors(this.ramGauge);
    this.changeColors(this.storageGauge);

    this.reloadKey++;
  }

  public changeColors(gauge: any) {
    gauge.chart.backgroundColor = this.themeType.backgroundContainers;
    gauge.chart.plotBackgroundColor = this.themeType.backgroundContainers;
  }

  public beforeMount() {
    // Récupération du theme actuel
    this.themeType = this.theme
        ? this.$vuetify.theme.themes["dark"]
        : this.$vuetify.theme.themes["light"];

    this.changeColors(this.cpuGauge);
    this.changeColors(this.ramGauge);
    this.changeColors(this.storageGauge);
  }
}
