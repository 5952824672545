












import Vue from "vue";

import {Component, Prop} from "vue-property-decorator";

@Component({})
export default class TicketMessage extends Vue {
  @Prop({}) message: any

  bgcColor: string = "";
  flexClass: string = "";

  get userData() {
    return this.$store.getters.userData;
  }

  public mounted(): void {
    this.setColor();
  }

  public setColor(): void {
    // Message de droite
    if (this.message.auteur.startsWith("admin")) {
      this.bgcColor =
          "background-color: var(--v-info-base) !important; padding: .75em; border-radius: 1.25em 0 1.25em 1.25em; margin-bottom: 0.5em; color: white; max-width: 100%; white-space: pre-line";
      this.flexClass = "d-flex flex-column align-end";
    } else {
      this.bgcColor =
          "background-color: var(--v-warning-base) !important; padding: .75em; border-radius: 0 1.25em 1.25em 1.25em; margin-bottom: 0.5em; color: white; max-width: 100%; white-space: pre-line";
      this.flexClass = "d-flex flex-column align-start";
    }
  }
}
