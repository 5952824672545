





















import Vue from "vue";

import {Component} from "vue-property-decorator";

import axios from "axios";
import {TranslateResult} from "vue-i18n";

const moment = require("moment");

@Component({})
export default class LastEvent extends Vue {
  loading: boolean = true;

  historique: {}[] = [];

  get headers(): {
    text: TranslateResult;
    align: string;
    value: string;
    sortable?: boolean;
  }[] {
    return [
      {
        text: this.$t("lastEvent.utilisateur"),
        align: "center",
        sortable: false,
        value: "utilisateur",
      },
      {
        text: this.$t("lastEvent.entreprise"),
        align: "center",
        sortable: false,
        value: "entreprise",
      },
      {
        text: this.$t("lastEvent.date"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("lastEvent.action"),
        align: "center",
        value: "action",
        sortable: false,
      },
    ];
  }

  public mounted(): void {
    this.initHistorique();
  }

  public initHistorique(): void {
    this.loading = true;

    axios
        .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/historique`)
        .then((response) => {
          moment.locale("fr");

          if (response.data.content && response.data.content.length > 0) {
            response.data.content.forEach((event: any) => {
              this.historique.push({
                utilisateur: event.utilisateur,
                entreprise: event.entreprise,
                date: moment(event.date).calendar(),
                action: event.action,
                timestamp: moment(event.date).format("x"),
              });
            });
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
  }
}
