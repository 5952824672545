
















import Vue from "vue";

import {Component, Watch} from "vue-property-decorator";

import axios from "axios";
import {Chart} from "highcharts-vue";
import Highcharts from "highcharts";

@Component({
  components: {
    highcharts: Chart,
  },
})
export default class StatsWeekly extends Vue {
  loading: boolean = true;

  weeklyStats: any;

  columnChart: any = Highcharts.merge(this.chartOptions, {
    title: null,
    series: [
      {
        name: this.$t("statsWeeky.newUser"),
      },
      {
        name: this.$t("statsWeeky.delUser"),
      },
      {
        name: this.$t("statsWeeky.newApp"),
      },
      {
        name: this.$t("statsWeeky.delApp"),
      },
    ],
  });

  reloadKey: number = 0;
  themeType: any;

  get chartOptions() {
    return {
      chart: {
        type: "column",
      },

      title: null,

      exporting: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      yAxis: {
        min: 0,
        title: null,
      },

      xAxis: {
        categories: [
          this.$t("statsWeeky.lundi"),
          this.$t("statsWeeky.mardi"),
          this.$t("statsWeeky.mercredi"),
          this.$t("statsWeeky.jeudi"),
          this.$t("statsWeeky.vendredi"),
          this.$t("statsWeeky.samedi"),
          this.$t("statsWeeky.dimanche"),
        ],
        crosshair: true,
      },

      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat:
            '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },

      plotOptions: {
        column: {
          pointPadding: 0.2,
          borderWidth: 0,
        },
      },
    };
  }

  get darkTheme() {
    return this.$vuetify.theme.dark;
  }

  public beforeMount(): void {
    this.themeType = this.darkTheme
        ? this.$vuetify.theme.themes["dark"]
        : this.$vuetify.theme.themes["light"];
    this.changeColor();
  }

  public mounted(): void {
    this.initStats();
  }

  public initStats(): void {
    this.loading = true;

    axios
        .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/stats/weekly`)
        .then((response) => {
          this.weeklyStats = response.data.deploymentWeeklyStats;

          // Stats utilisateur
          this.columnChart.series[0].data =
              this.weeklyStats.userCreatedThisWeekNb;
          this.columnChart.series[1].data =
              this.weeklyStats.userDeletedThisWeekNb;

          // Stats applications
          this.columnChart.series[2].data =
              this.weeklyStats.applicationDeployThisWeekNb;
          this.columnChart.series[3].data =
              this.weeklyStats.applicationDeletedThisWeekNb;

          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
  }

  public changeColor(): void {
    this.columnChart.chart.backgroundColor = this.darkTheme
        ? "#424242"
        : this.themeType.backgroundContainers;
    this.columnChart.chart.columnBackgroundColor = this.darkTheme
        ? "#424242"
        : this.themeType.backgroundContainers;
    this.columnChart.chart.plotBackgroundColor = this.darkTheme
        ? "#424242"
        : this.themeType.backgroundContainers;
  }

  @Watch("darkTheme")
  onDarkThemeChange() {
    this.themeType = this.darkTheme
        ? this.$vuetify.theme.themes["dark"]
        : this.$vuetify.theme.themes["light"];
    this.changeColor();
    this.reloadKey++;
  }
}
