











import Vue from "vue";

import colors from "vuetify/es5/util/colors";
import ErrorComponent from "@/components/error/ErrorComponent.vue";
import {Component} from "vue-property-decorator";

@Component({
  components: {
    ErrorComponent
  }
})
export default class NotFoundPage extends Vue {
  public color: any = {
    border: "#000000",
    visor: "#FFFFFF",
    fill: "#FFFFFF",
    circleBorder: "#000000",
    starSmallBorder: "#000000",
    starBigBorder: "#000000",
    planetRing: "#000000"
  }

  public beforeMount() {
    const theme = localStorage.getItem("dark_theme");
    if (theme) {
      this.$vuetify.theme.dark = theme === "true";
    }
    if (this.$vuetify.theme.dark) {
      this.color.border = colors.teal.accent3;
      this.color.visor = colors.blueGrey.darken1;
      this.color.fill = colors.blueGrey.darken3;
      this.color.circleBorder = colors.teal.accent3;
      this.color.starSmallBorder = colors.shades.white;
      this.color.starBigBorder = colors.teal.accent3;
      this.color.planetRing = colors.teal.accent3;
    }
  }
}
