















































import axios from "axios";
import {Component, Vue} from "vue-property-decorator";

@Component
export default class Kubecost extends Vue {
  public loading: boolean = true
  public error: boolean = false
  public errorMsg: String = ""
  public costData: any = []
  public total: number = 0

  get headers() {
    return [
      {
        text: this.$t("kubecost.nom"),
        align: "center",
        sortable: false,
        value: "nom",
      },
      {
        text: this.$t("kubecost.namespace"),
        align: "center",
        sortable: false,
        value: "namespace",
      },
      {
        text: this.$t("kubecost.cpu"),
        align: "center",
        sortable: false,
        value: "cpu",
      },
      {
        text: this.$t("kubecost.ram"),
        align: "center",
        sortable: false,
        value: "ram",
      },
      {
        text: this.$t("kubecost.disque"),
        align: "center",
        sortable: false,
        value: "disque",
      },
      {
        text: this.$t("kubecost.total"),
        align: "center",
        sortable: false,
        value: "total",
      },
      {
        text: this.$t("kubecost.details"),
        value: "details",
        align: "center",
        sortable: false,
      },
    ];
  }

  public mounted() {
    this.getCostData();
  }

  public getCostData() {
    axios
        .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/monthly-facture`)
        .then((result) => {
          result.data.forEach((item: any) => {
            const cpu = this.arrondi(item.cpuCost);
            const ram = this.arrondi(item.ramCost);
            const disk = this.arrondi(item.diskCost);

            this.costData.push({
              nom: item.appName,
              namespace: item.namespace,
              cpu: `${cpu} €`,
              ram: `${ram} €`,
              disque: `${disk} €`,
              total: `${this.arrondi(cpu + ram + disk)} €`,
              appName: item.appName,
            });

            this.total += this.arrondi(cpu + ram + disk);
          });
          this.total = this.arrondi(this.total);
          this.loading = false;
        })
        .catch((error) => {
          this.error = true;
          this.errorMsg = error;
        });
  }

  public voirApp(app: any) {
    this.$router.push({
      name: "Application",
      params: {
        cluster: "default",
        namespace: app.namespace,
        app: app.appName,
      },
    }).catch(() => {});
  }

  public arrondi(nombre: number) {
    let result = nombre * 100;
    result = Math.round(result);
    result = result / 100;
    return result;
  }
}
