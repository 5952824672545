





















































































































import Vue from "vue";

import {Component, Watch} from "vue-property-decorator";

import EditAccount from "@/components/account/EditAccount.vue";
import MyCompany from "@/components/account/MyCompany.vue";
import InfoCompany from "@/components/account/InfoCompany.vue";
import History from "@/components/account/History.vue";
import Sessions from "@/components/account/Sessions.vue";
import Support from "@/components/account/support/Support.vue";
import Preferences from "@/components/account/preferences/Preferences.vue";
import DialogConfirmation from "@/components/dialogue/DialogConfirmationComponent.vue";
import axios from "axios";

@Component({
  components: {
    Preferences,
    History,
    MyCompany,
    InfoCompany,
    EditAccount,
    Sessions,
    DialogConfirmation,
    Support,
  },
})
export default class Account extends Vue {
  toggle_exclusive: any = null;
  companyKey = 0;
  links = [
    "infos",
    "company",
    "entreprise",
    "historiques",
    "support",
    "preferences",
    "sessions",
  ];

  // Computed properties
  get haveEnterprise() {
    return this.$store.getters.haveEnterprise;
  }

  get userData() {
    return this.$store.getters.userData;
  }

  get companyName() {
    return this.$store.getters.entreprise;
  }

  get panel() {
    return this.$route.params.panel;
  }

  get confirm(): DialogConfirmation {
    // Obligatoire sinon il ne reconnait pas la méthode open de DialogConfirmation
    return this.$refs.confirm as DialogConfirmation;
  }

  // Mounted
  public mounted(): void {
    if (!this.panel) {
      this.$router.push({name: "Account", params: {panel: "infos"}}).catch(() => {});
    }

    this.checkPanel();
  }

  // Methods
  public checkPanel(): void {
    let isOk = false;

    for (let i = 0; i < this.links.length; i++) {
      if (this.links[i] === this.panel) {
        isOk = true;
        break;
      }
    }

    if (!isOk) {
      this.$router.push({name: "NotFoundPage"}).catch(() => {});
    }
  }

  public reloadCompany(): void {
    this.companyKey++;
  }

  public async deleteAccount() {
    if (
        await this.confirm.open(
            "dialogConfirmation.titleDelete",
            this.$t("dialogConfirmation.textDelete"),
            null
        )
    ) {
      axios
          .delete(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/delete/${this.userData.sub}`
          )
          .then(() => {
            localStorage.clear();
            window.location.href = `${window.location.protocol}//${window.location.host}`;
          })
          .catch(() => {
          });
    }
  }

  // Watcher
  @Watch("panel")
  onPanelChange(): void {
    this.checkPanel();
  }
}
