
















import axios from "axios";
import {marked} from "marked";
import Vue from "vue";
import {Component, Prop, Watch} from "vue-property-decorator";

@Component
export default class ReadmeComponent extends Vue {
  @Prop() application: any

  public readme: String | undefined = ""
  public dialog: boolean = false

  @Watch("application")
  onApplicationChange() {
    this.loadReadme()
  }

  public open() {
    this.dialog = true;
  }

  public loadReadme() {
    axios
        .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/assets/default/${this.application.availablePackageRef.context.namespace}/charts/${this.application.latestVersion.pkgVersion}/${this.application.availablePackageRef.identifier}`)
        .then((response) => {
          this.readme = marked.parse(response.data.availablePackageDetail[0].readme);
        })
        .catch(() => {
        });
  }
}
