




import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import {Chart} from "highcharts-vue";
import Highcharts from "highcharts";

@Component({
  components: {
    highcharts: Chart,
  },
})
export default class StatsPie extends Vue {
  @Prop({}) stats: any;

  pieChart: any = Highcharts.merge(this.chartOptions, {
    title: null,
    series: [
      {
        colorByPoint: true,
        data: [],
      },
    ],
  });

  reloadKey: number = 0;
  themeType: any;

  get darkTheme() {
    return this.$vuetify.theme.dark;
  }

  get chartOptions() {
    return {
      chart: {
        type: "pie",
      },

      title: null,

      exporting: {
        enabled: false,
      },

      credits: {
        enabled: false,
      },

      tooltip: {
        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
        pointFormat: '<tr><td style="padding:0"><b>{point.y}</b></td></tr>',
        footerFormat: "</table>",
        shared: true,
        useHTML: true,
      },

      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: "pointer",
          dataLabels: {
            enabled: true,
            format: "{point.name}: {point.percentage:.1f} %",
          },
        },
      },
    };
  }

  public mounted(): void {
    this.initStats();
  }

  public beforeMount(): void {
    this.themeType = this.darkTheme
        ? this.$vuetify.theme.themes["dark"]
        : this.$vuetify.theme.themes["light"];
    this.changeColor();
  }

  public initStats(): void {
    if (this.stats && Object.keys(this.stats).length > 0) {
      const keys = Object.keys(this.stats);
      for (let i = 0; i < Object.keys(this.stats).length; i++) {
        // On ignore le nb total de compte
        if (keys[i] !== "accountNb") {
          this.pieChart.series[0].data.push({
            name: keys[i],
            y: this.stats[keys[i]],
          });
        }
      }
    }
  }

  public changeColor(): void {
    this.pieChart.chart.backgroundColor = this.darkTheme
        ? "#424242"
        : this.themeType.backgroundContainers;
    this.pieChart.chart.plotBackgroundColor = this.darkTheme
        ? "#424242"
        : this.themeType.backgroundContainers;
  }

  @Watch("darkTheme")
  onDarkThemeChange() {
    this.themeType = this.darkTheme
        ? this.$vuetify.theme.themes["dark"]
        : this.$vuetify.theme.themes["light"];
    this.changeColor();
    this.reloadKey++;
  }
}
