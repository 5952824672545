

























































import Vue from "vue";

import {Component} from "vue-property-decorator";

import axios from "axios";
import {TranslateResult} from "vue-i18n";

const moment = require("moment");

@Component({})
export default class Sessions extends Vue {
  sessions: { start: string, lastAccess: string }[] = [];
  loading: boolean = true;

  success: boolean = false;
  successMessage: string = "";

  error: boolean = false;
  errorMessage: string = "";

  loadingLogout: boolean = false;

  get userData() {
    return this.$store.getters.userData;
  }

  get headers(): { text: TranslateResult, align: string, sortable: boolean, value: string }[] {
    return [
      {
        text: this.$t("sessions.ip"),
        align: "center",
        sortable: false,
        value: "ipAddress",
      },
      {
        text: this.$t("sessions.start"),
        value: "start",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("sessions.last"),
        value: "lastAccess",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("sessions.action"),
        value: "actions",
        align: "center",
        sortable: false,
      },
    ];
  }

  public mounted(): void {
    this.getSessions();
  }

  public getSessions(): void {
    this.loading = true;

    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/${this.userData.sub}`
        )
        .then((response) => {
          if (response.data.sessions) {
            this.sessions = response.data.sessions;

            moment.locale("fr");

            for (let j = 0; j < this.sessions.length; j++) {
              this.sessions[j].start = moment(this.sessions[j].start).format(
                  "LLL"
              );
              this.sessions[j].lastAccess = moment(
                  this.sessions[j].lastAccess
              ).format("LLL");
            }
          }

          this.loading = false;
        })
        .catch((error) => {
          this.errorMessage = error;
          this.success = false;
          this.error = true;
          this.loading = false;
        });
  }

  public logout(): void {
    this.loadingLogout = true;

    axios
        .put(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/${this.userData.sub}`
        )
        .then(() => {
          this.getSessions();
          this.successMessage = "Sessions déconnectés";
          this.success = true;
          this.error = false;
          this.loadingLogout = false;
        })
        .catch((err) => {
          this.errorMessage = err;
          this.success = false;
          this.error = true;
          this.loadingLogout = false;
        });
  }

  public logoutSession(session: any): void {
    this.loadingLogout = true;

    axios
        .delete(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/logout/${session.id}`
        )
        .then(() => {
          this.getSessions();
          this.successMessage = "Session déconnecté";
          this.success = true;
          this.error = false;
          this.loadingLogout = false;
        })
        .catch((err) => {
          this.errorMessage = err;
          this.success = false;
          this.error = true;
          this.loadingLogout = false;
        });
  }
}
