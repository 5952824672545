


































































import Vue from "vue";
import axios from "axios";

import {Component, Watch} from "vue-property-decorator";

import Releases from "@/components/Releases.vue";
import Alert from "@/components/alert/Alert.vue";

@Component({
  components: {
    Alert,
    Releases,
  },
})
export default class Applications extends Vue {
  datas: string[] = [];
  error: boolean = false;
  empty: boolean = false;
  allApp: boolean = false;
  loading: boolean = true;

  get userData() {
    return this.$store.getters.userData;
  }

  public mounted(): void {
    this.showAllApps(false);
  }

  public showAllApps(v: boolean): void {
    if (v) {
      if (
          this.userData &&
          this.userData.realm_access.roles.indexOf("ADMIN") !== -1
      ) {
        this.loading = true;
        axios
            .get(
                `${process.env.VUE_APP_SPRING_API_BASEURL}/api/services/clusters/default/releases`
            )
            .then((data) => {
              this.datas = [];
              data.data.data.forEach((app: any) => {
                this.datas.push(app);
              });
              this.error = false;
              if (this.datas.length === 0) {
                this.empty = true;
              }
            })
            .catch(() => {
              this.error = true;
            }).finally(() => {
          this.loading = false;
            });
      } else {
        this.userData.realm_access.roles.forEach((namespace: any) => {
          if (
              namespace !== "offline_access" &&
              namespace !== "uma_authorization" &&
              namespace !== "default-roles-elosi" &&
              namespace !== "ADMIN"
          ) {
            if (
                localStorage.getItem("namespace") && namespace !== localStorage.getItem("namespace")
            ) {
              axios
                  .get(
                      `${process.env.VUE_APP_SPRING_API_BASEURL}/api/services/clusters/default/namespaces/${namespace}/releases`
                  )
                  .then((response) => {
                    response.data.data.forEach((app: any) => {
                      this.datas.push(app);
                    });
                    this.error = false;
                    if (this.datas.length === 0) {
                      this.empty = true;
                    }
                    this.loading = false;
                  })
                  .catch(() => {
                    this.error = true;
                  });
            }
          }
        });
      }
    } else {
      if (
          localStorage.getItem("namespace") && localStorage.getItem("namespace") !== "undefined"
      ) {
        axios
            .get(
                `${
                    process.env.VUE_APP_SPRING_API_BASEURL
                }/api/services/clusters/default/namespaces/${localStorage.getItem(
                    "namespace"
                )}/releases`
            )
            .then((response) => {
              this.datas = response.data.data;
              this.error = false;
              if (this.datas.length === 0) {
                this.empty = true;
              }
              this.loading = false;
            })
            .catch(() => {
              this.error = true;
            });
      }
    }
  }

  @Watch("userData")
  onUserDataChange(newV: any) {
    if (newV) {
      this.showAllApps(false);
    }
  }
}
