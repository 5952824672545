import axios from "axios";
import * as ACTIONS from "../../actions/support/const_user_ticket";

const moment = require("moment");

const actions = {
  // eslint-disable-next-line no-unused-vars
  [ACTIONS.USER_ALL_TICKET_REQUEST]: ({commit}: any, nomUtilisateur: any) => {
    return new Promise((resolve, reject) => {
      if (nomUtilisateur) {
        axios
          .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/user/${nomUtilisateur}`
          )
          .then(response => {
            const tickets: any[] = [];

            moment.locale("fr");

            response.data.forEach((data: any) => {
              tickets.push({
                id: data.id,
                sujet: data.sujet,
                utilisateur: data.utilisateur,
                supportType: data.supportType,
                date: moment(data.date).calendar(),
                timestamp: moment(data.date).format("x"),
                ticketStatus: data.ticketStatus,
              });
            });

            resolve(tickets);
          })
          .catch(error => {
            if (error.response) {
              reject(error.response.data.message);
            } else if (error.request) {
              reject(error.request);
            }
            reject(error.message);
          })
      } else {
        axios
          .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/user`
          )
          .then(response => {
            const tickets: any[] = [];

            moment.locale("fr");

            if (response.data) {
              response.data.forEach((data: any) => {
                tickets.push({
                  id: data.id,
                  sujet: data.sujet,
                  utilisateur: data.utilisateur,
                  supportType: data.supportType,
                  date: moment(data.date).calendar(),
                  timestamp: moment(data.date).format("x"),
                  ticketStatus: data.ticketStatus,
                });
              });
            }

            resolve(tickets);
          })
          .catch(error => {
            if (error.response) {
              reject(error.response.data.message);
            } else if (error.request) {
              reject(error.request);
            }
            reject(error.message);
          })
      }
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.USER_TICKET_REQUEST]: ({commit}: any, idTicket: any) => {
    return new Promise((resolve, reject) => {
      axios
        .get(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/user/id/${idTicket}`
        )
        .then((response) => {
          const messages: any[] = [];

          response.data.messagesSupportList.forEach((msg: any) => {
            messages.push({
              message: msg.message,
              auteur: msg.auteur,
              date: moment(msg.date).calendar(),
            });
          });

          const ticket = {
            id: response.data.id,
            sujet: response.data.sujet,
            utilisateur: response.data.utilisateur,
            supportType: response.data.supportType,
            date: moment(response.data.date).calendar(),
            lastModification: moment(response.data.lastModification).calendar(),
            ticketStatus: response.data.ticketStatus,
            messagesSupportList: messages,
            screenshots: response.data.screenshotsString,
          };

          resolve(ticket);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        })
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.USER_NEW_TICKET]: ({commit}: any, data: any) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/user/`, data
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        })
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.USER_NEW_SCREENSHOT]: ({commit}: any, data: any) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/user/${data.ticketId}/screenshot`, data.screenshot, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        })
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.USER_TICKET_NEW_MESSAGE]: ({commit}: any, data: any) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/user/id/${data.ticketId}/message`, data.message
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        })
    });
  },

  // eslint-disable-next-line no-unused-vars
  [ACTIONS.USER_UPDATE_TICKET_STATUS]: ({commit}: any, data: any) => {
    return new Promise((resolve, reject) => {
      axios
        .put(
          `${process.env.VUE_APP_SPRING_API_BASEURL}/api/support/user/id/${data.ticketId}/status/${data.ticketStatus}`
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          if (error.response) {
            reject(error.response.data.message);
          } else if (error.request) {
            reject(error.request);
          }
          reject(error.message);
        })
    });
  },
}

export default {
  actions
};
