








































































































































































import Vue from "vue";

import {Component, Prop} from "vue-property-decorator";

@Component({})
export default class AdvancedPreference extends Vue {
  @Prop({}) newApp: number | undefined;
  @Prop({}) deleteApp: number | undefined;
  @Prop({}) invitationNotif: number | undefined;
  @Prop({}) disableApp: number | undefined;
  @Prop({}) activateApp: number | undefined;
  @Prop({}) updateApp: number | undefined;
  @Prop({}) rollbackApp: number | undefined;
  @Prop({}) memberNotif: number | undefined;
  @Prop({}) memberLeaveNotif: number | undefined;
  @Prop({}) items: number | undefined;

  // VueJS souleve une error si on modifie la valeur des props
  invitationNotifAdvanced: any = 3;
  newAppAdvanced: any = 3;
  disableAppAdvanced: any = 3;
  activateAppAdvanced: any = 3;
  deleteAppAdvanced: any = 3;
  updateAppAdvanced: any = 3;
  rollbackAppAdvanced: any = 3;
  memberNotifAdvanced: any = 3;
  memberLeaveNotifAdvanced: any = 3;

  public mounted(): void {
    this.invitationNotifAdvanced = this.invitationNotif;
    this.newAppAdvanced = this.newApp;
    this.disableAppAdvanced = this.disableApp;
    this.activateAppAdvanced = this.activateApp;
    this.deleteAppAdvanced = this.deleteApp;
    this.updateAppAdvanced = this.updateApp;
    this.rollbackAppAdvanced = this.rollbackApp;
    this.memberNotifAdvanced = this.memberNotif;
    this.memberLeaveNotifAdvanced = this.memberLeaveNotif;
  }

  public changeValue(type: string, value: number): void {
    this.$emit("value-change", {type, value});
  }
}
