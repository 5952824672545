



























































































































































































import Vue from "vue";

import {Component, Watch} from "vue-property-decorator";

import {SET_RELOAD_APP_BAR} from "@/store/actions/data";
import CountryFlag from "vue-country-flag";
import i18n from "@/plugins/i18n";
import axios from "axios";

@Component({
  components: {
    CountryFlag,
  },
})
export default class EditAccount extends Vue {
  value: string = "";
  showPassword: boolean = false;
  showPasswordConfirm: boolean = false;
  showCurrentPassword: boolean = false;
  email: string = "";
  password: string = "";
  passwordConfirm: string = "";
  currentPassword: string = "";
  success: boolean = false;
  error: boolean = false;
  nochange: boolean = false;
  errorMessage: string = "";
  avatar: any = null;
  avatarActuel: string = "";
  avatarUrl: string | undefined = "";

  locale: string = "";
  federatedIdentity: {} = {};

  languages: any = [
    {flag: "fr", language: "fr", title: "Français"},
    {flag: "gb", language: "en", title: "English"},
  ];

  public languageSelected: {} = {};

  get userData() {
    return this.$store.getters.userData;
  }

  public beforeMount(): void {
    this.email = this.userData.email;
    this.getAvatar();
    this.getFederatedIdentity();
    this.locale = i18n.locale;

    for (let i = 0; i < this.languages.length; i++) {
      if (this.languages[i].language === this.locale) {
        this.languageSelected = this.languages[i];
        break;
      }
    }
  }

  public getFederatedIdentity(): void {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/${this.userData.sub}`
        )
        .then((response) => {
          if (response.data != null) {
            this.federatedIdentity = response.data.federatedIdentity;
          }
        });
  }

  public avatarChange(e: any): void {
    this.success = false;
    this.avatarUrl = URL.createObjectURL(e);
  }

  public getAvatar(): void {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/avatar/${this.userData.sub}`
        )
        .then((response) => {
          if (response.data != null) {
            this.avatarActuel = response.data;
            this.avatarUrl = '';
          }
        }).catch(() => {
    });
  }

  public updateInformations(): void {
    this.success = false;
    this.error = false;

    if (this.avatar != null) {
      this.avatarUrl = undefined;
      const formData = new FormData();
      formData.append("image", this.avatar);
      axios
          .post(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/avatar/add`,
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
          )
          .then((response) => {
            if (response.data) {
              this.$store.dispatch(SET_RELOAD_APP_BAR, true);
              this.success = true;
              this.nochange = false;
            }
            this.getAvatar();
          }).catch(() => {
      });
    }

    if (
        (this.currentPassword === "" && this.password === "") ||
        (this.currentPassword !== this.password &&
            this.password !== "" &&
            this.password === this.passwordConfirm)
    ) {
      axios
          .put(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/user`, {
            email: this.email,
            password: this.password,
            currentPassword: this.currentPassword,
          })
          .then((response) => {
            if (response.data === "OK") {
              this.success = true;
              this.nochange = false;
              this.getAvatar();
            }
            if (response.data === "Aucun changement" && !this.success) {
              this.nochange = true;
              this.success = false;
            }
          })
          .catch((error) => {
            if (error.response.data.message) {
              this.errorMessage = error.response.data.message;
            }
            this.error = true;
          });
    }
  }

  @Watch("languageSelected")
  onLanguageChange(value: any) {
    i18n.locale = value.language;
    localStorage.setItem("lang", i18n.locale);
  }
}
