
























































import Vue from "vue";

import {Component} from "vue-property-decorator";

import axios from "axios";
import MyCompany from "@/components/account/MyCompany.vue";
import SearchApp from "@/components/SearchApp.vue";
import {TranslateResult} from "vue-i18n";

@Component({
  components: {
    MyCompany,
    SearchApp,
  },
})
export default class Entreprises extends Vue {
  entreprises: { nom: string }[] = [];
  entreprisesBackup: { nom: string }[] = [];

  entreprise: string = "";

  get headers(): {
    text: TranslateResult;
    align: string;
    value: string;
    sortable?: boolean;
  }[] {
    return [
      {
        text: this.$t("adminEntreprise.nom"),
        align: "center",
        value: "nom",
      },
      {
        text: this.$t("adminEntreprise.date"),
        align: "center",
        sortable: true,
        value: "dateCreation",
      },
      {
        text: this.$t("adminEntreprise.page"),
        value: "actions",
        align: "center",
        sortable: false,
      },
    ];
  }

  public mounted(): void {
    this.loadEntreprises();
  }

  public loadEntreprises(): void {
    axios
        .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/entreprises`)
        .then((response) => {
          this.entreprises = response.data;
          this.entreprisesBackup = this.entreprises;
        })
        .catch(() => {
        });
  }

  public voirEntreprise(item: any): void {
    this.entreprise = item.nom;
  }

  public retourListEntreprise(): void {
    this.entreprise = "";
    this.entreprises = this.entreprisesBackup;
  }

  public updateSearchCompany(event: any): void {
    if (event.length > 0 && this.entreprisesBackup.length === 0) {
      this.entreprisesBackup = this.entreprises;
    }

    this.entreprises = this.entreprisesBackup;

    if (event.length > 0) {
      this.entreprises = this.entreprises.filter((value) =>
          value.nom.toLowerCase().includes(event.toLowerCase())
      );
    } else {
      this.entreprisesBackup = [];
    }
  }
}
