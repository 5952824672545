import Vue from "vue";
import Vuex from "vuex";
import auth from "../store/modules/auth";
import data from "../store/modules/data";
import api_facture from "../api/modules/pricing/api_facture"
import api_monthly_facture from "../api/modules/pricing/api_monthly_facture"
import api_pricing from "../api/modules/pricing/api_pricing"
import api_promotion from "../api/modules/pricing/api_promotion"
import api_user_ticket from "../api/modules/support/api_user_ticket"
import api_entreprise_ticket from "../api/modules/support/api_entreprise_ticket"
import api_invitation from "../api/modules/user/api_invitation"

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
  modules: {
    auth,
    data,
    api_facture,
    api_monthly_facture,
    api_pricing,
    api_promotion,
    api_user_ticket,
    api_entreprise_ticket,
    api_invitation
  },
  strict: debug
});
