var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticClass:"pa-0",attrs:{"cols":"12"}},[_c('v-sheet',{staticClass:"d-flex mb-3",attrs:{"color":"tertiary","rounded":"lg"}},[(
        (_vm.userData.entrepriseAdmin &&
          _vm.userData.entrepriseAdmin.includes(_vm.entrepriseName)) ||
          (_vm.userData.realm_access &&
            _vm.userData.realm_access.roles.includes('ADMIN'))
      )?_c('Invitations',{attrs:{"nom-entreprise-admin":_vm.nomEntrepriseAdmin},on:{"reload_entreprise":function($event){return _vm.reloadCompany()}}}):_vm._e()],1),_c('v-sheet',{staticClass:"mb-3",attrs:{"color":"tertiary","rounded":"lg"}},[(_vm.error)?_c('v-alert',{attrs:{"dense":"","dismissible":"","prominent":"","type":"error"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.errorMessage))+" ")]):_vm._e(),(_vm.success)?_c('v-alert',{attrs:{"dense":"","dismissible":"","prominent":"","type":"success"},model:{value:(_vm.success),callback:function ($$v) {_vm.success=$$v},expression:"success"}},[_vm._v(" "+_vm._s(_vm.successMessage)+" ")]):_vm._e(),_c('div',{staticClass:"pa-3 text-uppercase d-flex justify-space-between align-center"},[_c('div',[_vm._v(" "+_vm._s(_vm.$t("myCompany.header"))+" "),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.show = !_vm.show}}},[_c('v-icon',[_vm._v(_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down"))])],1)],1),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({on:{"click":function($event){return _vm.createJoinCompany()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-domain")])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t("myCompany.tooltip")))])])],1),_c('v-divider'),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-container',[_c('DialogInput',{ref:"input"}),(_vm.loading)?_c('v-skeleton-loader',{attrs:{"type":"table"}}):_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.users,"locale":"fr-FR","sort-by":"date_arrive"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.entrepriseName))]),_c('v-spacer'),_c('DialogConfirmation',{ref:"confirm"})],1)]},proxy:true},{key:"item.actions",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":item.username === _vm.userData.preferred_username ||
                    item.role.includes('Administrateur') ||
                    !_vm.userData.entrepriseAdmin ||
                    !_vm.userData.entrepriseAdmin.includes(_vm.entrepriseName) ||
                    item.disabled,"small":""},on:{"click":function($event){return _vm.delAccount(item)}}},[_vm._v(" mdi-delete ")])]}},{key:"item.admin",fn:function(ref){
                    var item = ref.item;
return [_c('v-icon',{attrs:{"disabled":item.username === _vm.userData.preferred_username ||
                    item.role.includes('Administrateur') ||
                    !_vm.userData.entrepriseAdmin ||
                    !_vm.userData.entrepriseAdmin.includes(_vm.entrepriseName) ||
                    item.disabled,"small":""},on:{"click":function($event){return _vm.upgradeUser(item)}}},[_vm._v(" mdi-account-arrow-up ")])]}}],null,true)})],1)],1)])],1),_c('v-sheet',{staticClass:"d-flex mb-3",attrs:{"color":"tertiary","rounded":"lg"}},[(
        (_vm.userData.entrepriseAdmin &&
          _vm.userData.entrepriseAdmin.includes(_vm.entrepriseName)) ||
          (_vm.userData.realm_access &&
            _vm.userData.realm_access.roles.includes('ADMIN'))
      )?_c('Factures',{attrs:{"nom-entreprise-admin":_vm.entrepriseName}}):_vm._e()],1),_c('v-sheet',{staticClass:"d-flex mb-3",attrs:{"color":"tertiary","rounded":"lg"}},[(
        (_vm.userData.entrepriseAdmin &&
          _vm.userData.entrepriseAdmin.includes(_vm.entrepriseName)) ||
          (_vm.userData.realm_access &&
            _vm.userData.realm_access.roles.includes('ADMIN'))
      )?_c('MonthlyFactures',{attrs:{"nom-entreprise-admin":_vm.entrepriseName}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }