import Vuex from "vuex";
import Vue from "vue";

import {
  SET_APPLICATION_VALUES,
  SET_ENTREPRISE,
  SET_HAVE_ENTERPRISE,
  SET_MOBILE,
  SET_NAMESPACE,
  SET_RELOAD_APP_BAR,
  SET_SELECTED_ENTREPRISE,
  SET_SELECTED_NAMESPACE
} from "../actions/data";

Vue.use(Vuex);

const state = {
  namespace: "",
  haveEnterprise: false,
  reloadAppbar: false,
  entreprise: "",
  selectedEntreprise: 0,
  selectedNamespace: 0,
  mobile: false,
  applicationValues: {},
  availableLanguage: ['fr', 'en'],
}

const getters = {
  namespace: () => {
    return state.namespace
  },
  haveEnterprise: () => {
    return state.haveEnterprise
  },
  reloadAppbar: () => {
    return state.reloadAppbar
  },
  entreprise: () => {
    return state.entreprise
  },
  selectedEntreprise: () => {
    return state.selectedEntreprise
  },
  selectedNamespace: () => {
    return state.selectedNamespace
  },
  mobile: () => {
    return state.mobile
  },
  applicationValues: () => {
    return state.applicationValues
  },
  availableLanguage: () => {
    return state.availableLanguage
  },
}

const mutations = {
  setNs(namespaceState: any, data: any) {
    state.namespace = data
  },
  setHvEntpr(haveEnterpriseState: any, data: any) {
    state.haveEnterprise = data
  },
  setRelAppBar(reloadAppbarState: any, data: any) {
    state.reloadAppbar = data
  },
  setEntreprise(entrepriseState: any, data: any) {
    state.entreprise = data
  },
  setSelectedEntreprise(selectedEntrepriseState: any, data: any) {
    state.selectedEntreprise = data
  },
  setSelectedNamespace(selectedNamespaceState: any, data: any) {
    state.selectedNamespace = data
  },
  setMobile(mobileState: any, data: any) {
    state.mobile = data
  },
  setApplicationValues(applicationValuesState: any, data: any) {
    state.applicationValues = data
  },
}

const actions = {
  [SET_NAMESPACE]({commit}: any, value: any) {
    commit('setNs', value)
  },
  [SET_HAVE_ENTERPRISE]({commit}: any, value: any) {
    commit('setHvEntpr', value)
  },
  [SET_RELOAD_APP_BAR]({commit}: any, value: any) {
    commit('setRelAppBar', value)
  },
  [SET_ENTREPRISE]({commit}: any, value: any) {
    commit('setEntreprise', value)
  },
  [SET_SELECTED_ENTREPRISE]({commit}: any, value: any) {
    commit('setSelectedEntreprise', value)
  },
  [SET_SELECTED_NAMESPACE]({commit}: any, value: any) {
    commit('setSelectedNamespace', value)
  },
  [SET_MOBILE]({commit}: any, value: any) {
    commit('setMobile', value)
  },
  [SET_APPLICATION_VALUES]({commit}: any, value: any) {
    commit('setApplicationValues', value)
  },
}

export default {
  state,
  getters,
  mutations,
  actions
};
