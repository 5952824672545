
















import Vue from "vue";

import {Component, Prop} from "vue-property-decorator";

@Component({})
export default class DatePicker extends Vue {
  @Prop({}) value: string | undefined;

  menu: boolean = false;

  get picker(): string {
    return this.value!;
  }

  set picker(val: string) {
    // Retour avec formattage de la date dd/mm/yyyy
    const [year, month, day] = val.split("-");
    this.menu = false;
    this.$emit("input", `${day}/${month}/${year}`);
  }
}
