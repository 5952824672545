import Vue from 'vue'
import VueI18n from 'vue-i18n'

import fr from '@/i18n/fr';
import en from '@/i18n/en';

Vue.use(VueI18n)

const messages = {
  fr,
  en
};

export default new VueI18n({
  locale: 'fr', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
});
