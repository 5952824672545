





















import Vue from "vue";

import {Component} from "vue-property-decorator";

import axios from "axios";
import {TranslateResult} from "vue-i18n";

const moment = require("moment");

@Component({})
export default class History extends Vue {
  users: {}[] = [];
  editedIndex: number = -1;
  entrepriseName: string = "";
  errorMessage: string = "";
  error: boolean = false;
  loading: boolean = true;

  get userData(): void {
    return this.$store.getters.userData;
  }

  get headers(): {
    text: TranslateResult;
    align: string;
    sortable: boolean;
    value: string;
  }[] {
    return [
      {
        text: this.$t("historique.username"),
        align: "center",
        sortable: false,
        value: "utilisateur",
      },
      {
        text: this.$t("historique.entreprise"),
        align: "center",
        sortable: false,
        value: "entreprise",
      },
      {
        text: this.$t("historique.date"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("historique.action"),
        align: "center",
        value: "action",
        sortable: false,
      },
    ];
  }

  public beforeMount(): void {
    this.entrepriseName = this.$store.getters.entreprise;

    this.initialize();
  }

  public initialize(): void {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/historique/${this.entrepriseName}`
        )
        .then((response) => {
          moment.locale("fr");
          if (response.data.length > 0) {
            response.data.forEach((historique: any) => {
              this.users.push({
                utilisateur: historique.utilisateur,
                entreprise: historique.entreprise,
                date: moment(historique.date).calendar(),
                action: historique.action,
                timestamp: moment(historique.date).format("x"),
              });
              this.loading = false;
            });
          } else {
            this.loading = false;
          }
        })
        .catch((error) => {
          if (error.response.data.message) {
            this.errorMessage = error.response.data.message;
          }
          this.loading = false;
          this.error = true;
        });
  }
}
