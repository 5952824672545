





































import Vue from "vue";

import {Component, Prop} from "vue-property-decorator";
import StatsPie from "@/components/administration/stats/StatsPie.vue";

@Component({
  components: {
    StatsPie,
  },
})
export default class StatsUtilisateur extends Vue {
  @Prop({}) stats: any;

  statsUtilisateur: any[] = [];

  public mounted(): void {
    this.initStatsUtilisateur();
  }

  public initStatsUtilisateur(): void {
    this.statsUtilisateur = [
      {
        stat: this.stats.keycloakStats.accountNb,
        texte: this.$t("statsUtilisateur.compte"),
        couleur: "info",
        icon: "mdi-account-multiple",
      },
      {
        stat: this.stats.keycloakStats.accountVerified,
        texte: this.$t("statsUtilisateur.verifie"),
        couleur: "success",
        icon: "mdi-account-check",
      },
      {
        stat: this.stats.keycloakStats.accountNotVerified,
        texte: this.$t("statsUtilisateur.nonVerifie"),
        couleur: "error",
        icon: "mdi-account-lock",
      },
    ];
  }
}
