












import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class CostTable extends Vue {
  @Prop() stats: any

  public data: any = []

  get headers() {
    return [
      {
        text: this.$t("appCostTable.nom"),
        align: "center",
        sortable: false,
        value: "nom",
      },
      {
        text: this.$t("appCostTable.cpu"),
        align: "center",
        sortable: false,
        value: "cpu",
      },
      {
        text: this.$t("appCostTable.ram"),
        align: "center",
        sortable: false,
        value: "ram",
      },
      {
        text: this.$t("appCostTable.disque"),
        align: "center",
        sortable: false,
        value: "disque",
      },
      {
        text: this.$t("appCostTable.total"),
        align: "center",
        sortable: false,
        value: "total",
      },
    ];
  }

  public mounted() {
    this.loadData();
  }

  public loadData() {
    for (let i = 0; i < this.stats.length; i++ ) {
      this.data.push({
        nom: this.stats[i].nom,
        cpu: `${this.stats[i].cpu} €`,
        ram: `${this.stats[i].ram} €`,
        disque: `${this.stats[i].disque} €`,
        total: `${this.stats[i].total} €`,
      });
    }
  }
}
