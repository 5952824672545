













































import axios from "axios";
import Alert from "@/components/alert/Alert.vue";
import Vue from "vue";
import {Component, Prop} from "vue-property-decorator";

@Component({
  components: {
    Alert
  }
})
export default class DialogueRollback extends Vue {
  @Prop() dialog: boolean | undefined
  @Prop() dialogTitle: String | undefined
  @Prop() dialogContent: String | undefined
  @Prop() release: any

  public versions: any = []
  public version: number = 1
  public loading: boolean = false
  public error: boolean = false
  public errorMessage: String = ""

  public mounted() {
    for (let i = 1; i < this.release.version; i++) {
      this.versions.push(i);
    }
    this.version = this.versions[this.versions.length - 1];
  }

  public accept() {
    this.loading = true;
    axios
        .put(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/services/clusters/${this.$route.params.cluster}/namespaces/${this.$route.params.namespace}/releases/${this.$route.params.app}?action=rollback&version=${this.version}`,
            {}
        )
        .then(() => {
          this.loading = false;
          this.$emit("confirmRollback", this.dialog);
        })
        .catch((error) => {
          this.loading = false;
          this.error = true;
          this.errorMessage = error.response.data.message;
        });
  }

  public cancel() {
    this.$emit("cancelRollback", this.dialog);
  }
}
