














import {Component, Vue} from "vue-property-decorator";
import {bootstrap} from "vue-gtag";

@Component
export default class CookieConsent extends Vue {
  public isOpen?: boolean = false;

  public created() {
    this.isOpen = localStorage.getItem("rgpd:google-analytics") === null;
  }

  public accept() {
    this.isOpen = false;
    bootstrap().then(() => {
      localStorage.setItem("rgpd:google-analytics", "true");
    });
  }

  public deny() {
    this.isOpen = false;
    localStorage.setItem("rgpd:google-analytics", "false");
  }
}
