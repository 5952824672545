


























































import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import NewEntrepriseTicket from "@/components/account/support/NewEntrepriseTicket.vue";

import {ENTREPRISE_ALL_TICKET_REQUEST} from "@/api/actions/support/const_entreprise_ticket";
import {TranslateResult} from "vue-i18n";

@Component({
  components: {
    NewEntrepriseTicket,
  },
})
export default class SupportCompany extends Vue {
  @Prop({}) isAdmin: boolean | undefined;
  @Prop({}) search: string | undefined;
  @Prop({}) typeRechercheSelected: string | undefined;

  companyName: string = "";

  loading: boolean = true;
  show: boolean = true;

  error: boolean = false;
  errorMessage: string = "";

  tickets: {
    id: string;
    sujet: string;
    entreprise: string;
    application: string;
    nomApplication: string;
    supportType: string;
    ticketStatus: string;
  }[] = [];
  backupTickets: {
    id: string;
    sujet: string;
    entreprise: string;
    application: string;
    nomApplication: string;
    supportType: string;
    ticketStatus: string;
  }[] = [];

  dialog: boolean = false;

  get headers(): {
    text: TranslateResult;
    align: string;
    sortable: boolean;
    value: string;
  }[] {
    return [
      {
        text: this.$t("supportCompany.id"),
        align: "center",
        sortable: false,
        value: "id",
      },
      {
        text: this.$t("supportCompany.sujet"),
        align: "center",
        sortable: false,
        value: "sujet",
      },
      {
        text: this.$t("supportCompany.entreprise"),
        value: "entreprise",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("supportCompany.app"),
        value: "application",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("supportCompany.appName"),
        value: "nomApplication",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("supportCompany.type"),
        value: "supportType",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("supportCompany.date"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("supportCompany.status"),
        value: "ticketStatus",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("supportCompany.details"),
        value: "details",
        align: "center",
        sortable: false,
      },
    ];
  }

  public mounted(): void {
    this.companyName = this.$store.getters.entreprise;

    this.getTickets();
  }

  public getTickets(): void {
    this.$store
        .dispatch(
            ENTREPRISE_ALL_TICKET_REQUEST,
            this.isAdmin ? null : this.companyName
        )
        .then((response) => {
          this.tickets = response;
          this.backupTickets = response;
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error;
        })
        .finally(() => (this.loading = false));
  }

  public newTicket(): void {
    this.dialog = !this.dialog;
  }

  public detailsTicket(ticket: { id: string }): void {
    this.$emit("details", ticket.id);
  }

  public updateSearchTicket(): void {
    if (this.search !== undefined && this.search.length === 0) {
      this.tickets = this.backupTickets;
    } else {
      switch (this.typeRechercheSelected) {
        case this.$t("support.id"): {
          this.tickets = this.tickets.filter((value) =>
              value.id.includes(this.search!)
          );
          break;
        }
        case this.$t("support.sujet"): {
          this.tickets = this.tickets.filter((value) =>
              value.sujet.toLowerCase().includes(this.search?.toLowerCase()!)
          );
          break;
        }
        case this.$t("support.entreprise"): {
          this.tickets = this.tickets.filter((value) =>
              value.entreprise.toLowerCase().includes(this.search?.toLowerCase()!)
          );
          break;
        }
        case this.$t("support.application"): {
          this.tickets = this.tickets.filter((value) =>
              value.application.toLowerCase().includes(this.search?.toLowerCase()!)
          );
          break;
        }
        case this.$t("support.nomApp"): {
          this.tickets = this.tickets.filter((value) =>
              value.nomApplication.toLowerCase().includes(this.search?.toLowerCase()!)
          );
          break;
        }
        case this.$t("support.type"): {
          this.tickets = this.tickets.filter((value) =>
              value.supportType.toLowerCase().includes(this.search?.toLowerCase()!)
          );
          break;
        }
        case this.$t("support.status"): {
          this.tickets = this.tickets.filter((value) =>
              value.ticketStatus.toLowerCase().includes(this.search?.toLowerCase()!)
          );
          break;
        }
        default: {
          this.tickets = this.backupTickets;
          break;
        }
      }
    }
  }

  @Watch("search")
  onSearchChange() {
    this.tickets = this.backupTickets;
    this.updateSearchTicket();
  }

  @Watch("typeRechercheSelected")
  onTypeRechercheSelectedChange() {
    this.tickets = this.backupTickets;
    this.updateSearchTicket();
  }
}
