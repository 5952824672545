













































import {Component, Prop, Vue, Watch} from "vue-property-decorator";

@Component
export default class FormConfiguration extends Vue {
  @Prop() config: any
  @Prop() configs: any
  @Prop() index: number | undefined

  public showPassword: boolean = false

  @Watch('config')
  onConfigChange(val: any) {
    this.$emit("configuration-form-deploy", val, this.index);
  }

  public mounted() {
    if (this.config.select === "mdp") {
      this.config.value = this.generatePassword();
    }
    this.$emit("configuration-form-deploy", this.config, this.index);
  }

  public getConfigValueByKey(key: String) {
    return this.configs.filter((conf: any) => {
      return conf.key === key;
    });
  }

  public generatePassword() {
    let CharacterSet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let password = "";
    const size = 12;
    for (let i = 0; i < size; i++) {
      password += CharacterSet.charAt(
          Math.floor(Math.random() * CharacterSet.length)
      );
    }
    return password;
  }
}
