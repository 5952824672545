





















import Vue from "vue";

import {Component} from "vue-property-decorator";

import Kubecost from "@/components/administration/pricing/Kubecost.vue";

import {PRICING_REQUEST, PRICING_UPDATE,} from "@/api/actions/pricing/const_pricing";
import {TranslateResult} from "vue-i18n";

@Component({
  components: {
    Kubecost,
  },
})
export default class Pricing extends Vue {
  loading: boolean = true;

  btnLoading: boolean = false;

  pricing: any = {};

  success: boolean = false;
  successMessage: TranslateResult = "";

  error: boolean = false;
  errorMsg: string = "";

  public mounted(): void {
    this.getPricingData();
  }

  public getPricingData(): void {
    this.loading = true;

    this.$store
        .dispatch(PRICING_REQUEST)
        .then((res) => {
          this.pricing = res;
        })
        .catch((err) => {
          this.pricing = {
            diskPrice: 0,
            ramPrice: 0,
            cpuPrice: 0,
          };
          this.error = true;
          const msg = this.$t(err);
          this.errorMsg = msg.length > 0 ? msg : err;
        })
        .finally(() => (this.loading = false));
  }

  public updatePrice(): void {
    this.btnLoading = true;
    this.success = false;

    this.$store
        .dispatch(PRICING_UPDATE, this.pricing)
        .then(() => {
          this.success = true;
          this.successMessage = this.$t("success.updatePricing");
        })
        .catch((err) => {
          this.error = true;
          const msg = this.$t(err);
          this.errorMsg = msg.length > 0 ? msg : err;
        })
        .finally(() => (this.btnLoading = false));
  }
}
