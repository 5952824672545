






































































































































import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import axios from "axios";
import Notifications from "@/components/app/appbar/Notifications.vue";
import AppBarDropdown from "@/components/app/appbar/desktop/AppBarDropdown.vue";
import {SET_MOBILE, SET_RELOAD_APP_BAR} from "@/store/actions/data";

@Component({
  components: {
    Notifications,
    AppBarDropdown,
  },
})
export default class AppBar extends Vue {
  @Prop({}) entreprises: any;
  @Prop({}) namespaces: any;
  @Prop({}) links: any;
  @Prop({}) loadingApp: any;

  avatar: string | undefined = "";

  get userData() {
    return this.$store.getters.userData;
  }

  get hasEntreprise() {
    return this.$store.getters.haveEnterprise;
  }

  get reloadAppbar() {
    return this.$store.getters.reloadAppbar;
  }

  public mounted(): void {
    this.getAvatar();
  }

  public darkMode(): void {
    this.$emit("darkMode");
  }

  public logout(): void {
    this.$emit("logout");
  }

  public setMobile(): void {
    this.$store.dispatch(SET_MOBILE, true);
  }

  public getAvatar(): void {
    if (this.userData) {
      if (this.userData.picture) {
        this.avatar = this.userData.picture;
      } else {
        axios
            .get(
                `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/avatar/${this.userData.sub}`
            )
            .then((response) => {
              if (response.data != null) {
                this.avatar = response.data;
              }
            })
            .catch(() => {
              this.avatar = undefined;
            });
      }
    }
  }

  @Watch("reloadAppbar")
  onReloadAppbarChange(newV: any) {
    // Si a true on reload l'avatar, et on passe la valeur a false
    if (newV) {
      this.getAvatar();
      this.$store.dispatch(SET_RELOAD_APP_BAR, false);
    }
  }

  @Watch("userData")
  onUserDataChange(newV: any) {
    if (newV) {
      this.getAvatar();
    }
  }
}
