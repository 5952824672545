



































































































import Vue from "vue";
import axios from "axios";

import {Component, Watch} from "vue-property-decorator";

import MaterialCard from "@/components/material/MaterialCard.vue";
import AppCard from "@/components/app/AppCard.vue";
import FormBuilder from "@/components/admin/FormBuilder.vue";
import ReadmeComponent from "@/components/ReadmeComponent.vue";

@Component({
  components: {
    ReadmeComponent,
    MaterialCard,
    AppCard,
    FormBuilder,
  },
})
export default class AdminAddApplication extends Vue {
  availableApp: string[] = [];
  selectedApp: string = "";
  chartData: string[] = [];
  namespaces: string[] = [];
  selectedNamespaces: string[] = [];
  items: typeof FormBuilder[] = [FormBuilder];
  configurations: {}[] = [];
  showReadme: boolean = false;

  get URL() {
    return process.env.VUE_APP_SPRING_API_BASEURL
  }

  get readme(): ReadmeComponent {
    return this.$refs.readme as ReadmeComponent;
  }

  public mounted(): void {
    this.loadCharts();
    this.getNamespaces();
  }

  public openReadme(): void {
    this.readme.open();
  }

  public submit(): void {
    axios
        .post(
            `${
                process.env.VUE_APP_SPRING_API_BASEURL
            }/api/default/${localStorage.getItem("namespace")}/applications/${
                this.selectedApp
            }`,
            {config: this.configurations, namespaces: this.selectedNamespaces}
        )
        .then(() => {
          this.$router.push({path: "/administration/applications"}).catch(() => {});
        })
        .catch(() => {
        });
  }

  public getNamespaces(): void {
    axios
        .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/default/namespaces`)
        .then((response) => {
          response.data.namespaces.forEach((value: { metadata: { name: string } }) => {
            this.namespaces.push(value.metadata.name);
          });
        })
        .catch(() => {
        });
  }

  public loadCharts(): void {
    axios
        .get(
            `${
                process.env.VUE_APP_SPRING_API_BASEURL
            }/api/assets/default/${localStorage.getItem(
                "namespace"
            )}/charts?size=1000`
        )
        .then((response) => {
          response.data.availablePackageSummaries.forEach((chart: any) => {
            if (chart.application === undefined)
              this.availableApp = this.availableApp.concat(chart.name);
          });
        })
        .catch(() => {
        });
  }

  public loadChart(chart: any): void {
    axios
        .get(
            `${
                process.env.VUE_APP_SPRING_API_BASEURL
            }/api/assets/default/${localStorage.getItem(
                "namespace"
            )}/charts?filterOptions.query=${chart}`
        )
        .then((response) => {
          if (response.data.availablePackageSummaries.length > 0)
            this.chartData = response.data.availablePackageSummaries[0];
        })
        .catch(() => {
        });
  }

  public addNewForm(): void {
    this.items.push(FormBuilder);
  }

  public removeForm(form: number) {
    if (form !== 0) {
      this.items.splice(form, 1);
    }
  }

  public updateConfiguration(conf: {}, n: number): void {
    if (this.configurations[n] === undefined) {
      this.configurations[n] = {};
    }
    this.configurations[n] = conf;
  }

  @Watch("selectedApp")
  onSelectedAppchange(newV: string, oldV: string): void {
    if (newV && newV !== oldV) {
      this.loadChart(newV);
    }
  }

  @Watch("showReadme")
  onShowReadmechange(newV: boolean): void {
    if (newV) {
      this.showReadme = true;
    }
  }
}
