






























































import Vue from "vue";

import {Component} from "vue-property-decorator";

import {TranslateResult} from "vue-i18n";
import {regex, required} from "vee-validate/dist/rules";
import {extend, setInteractionMode, ValidationObserver, ValidationProvider,} from "vee-validate";

setInteractionMode("eager");

extend("required", {
  ...required,
  message: "{_field_} ne peut pas être vide",
});

extend("regex", {
  ...regex,
  message: "{_field_} {_value_} ne respecte pas la règle {regex}",
});

@Component({
  components: {
    ValidationProvider,
    ValidationObserver,
  },
})
export default class DialogInput extends Vue {
  input: string = "";
  dialog: boolean = false;
  resolve: any = null;
  reject: any = null;
  message: TranslateResult = "";
  title: string = "";
  options: object = {
    color: "tertiary",
    width: 400,
    zIndex: 200,
    noconfirm: false,
  };

  get observer(): Vue & { validate: () => boolean } {
    return this.$refs.observer as Vue & { validate: () => boolean };
  }

  public open(title: string, message: string, options: any): Promise<string> {
    this.dialog = true;
    this.title = title;
    this.message = message;
    this.options = Object.assign(this.options, options);
    return new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }

  public agree(): void {
    this.observer.validate();
    this.resolve(true);
    this.dialog = false;
  }

  public cancel(): void {
    this.resolve(false);
    this.dialog = false;
  }
}
