


























































import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import NewUserTicket from "@/components/account/support/NewUserTicket.vue";

import {USER_ALL_TICKET_REQUEST} from "@/api/actions/support/const_user_ticket";
import {TranslateResult} from "vue-i18n";

@Component({
  components: {
    NewUserTicket,
  },
})
export default class SupportUser extends Vue {
  @Prop({}) isAdmin: boolean | undefined;
  @Prop({}) search: string | undefined;
  @Prop({}) typeRechercheSelected: string | undefined;

  loading: boolean = true;
  show: boolean = true;

  error: boolean = false;
  errorMessage: string = "";

  tickets: {
    id: string;
    sujet: string;
    utilisateur: string;
    supportType: string;
    ticketStatus: string;
  }[] = [];
  backupTickets: {
    id: string;
    sujet: string;
    utilisateur: string;
    supportType: string;
    ticketStatus: string;
  }[] = [];

  dialog: boolean = false;

  get userData() {
    return this.$store.getters.userData;
  }

  get headers(): {
    text: TranslateResult;
    align: string;
    sortable: boolean;
    value: string;
  }[] {
    return [
      {
        text: this.$t("supportUser.id"),
        align: "center",
        sortable: false,
        value: "id",
      },
      {
        text: this.$t("supportUser.sujet"),
        align: "center",
        sortable: false,
        value: "sujet",
      },
      {
        text: this.$t("supportUser.utilisateur"),
        align: "center",
        sortable: false,
        value: "utilisateur",
      },
      {
        text: this.$t("supportUser.type"),
        value: "supportType",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("supportUser.date"),
        value: "date",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("supportUser.status"),
        value: "ticketStatus",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("supportUser.details"),
        value: "details",
        align: "center",
        sortable: false,
      },
    ];
  }

  public mounted(): void {
    this.getTickets();
  }

  public getTickets(): void {
    this.$store
        .dispatch(
            USER_ALL_TICKET_REQUEST,
            !this.isAdmin ? this.userData.preferred_username : null
        )
        .then((response) => {
          this.tickets = response;
          this.backupTickets = response;
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error;
        })
        .finally(() => (this.loading = false));
  }

  public newTicket(): void {
    this.dialog = !this.dialog;
  }

  public detailsTicket(ticket: { id: string }): void {
    this.$emit("details", ticket.id);
  }

  public updateSearchTicket(): void {
    if (this.search !== undefined && this.search.length === 0) {
      this.tickets = this.backupTickets;
    } else {
      switch (this.typeRechercheSelected) {
        case this.$t("support.id"): {
          this.tickets = this.tickets.filter((value) =>
              value.id.toLowerCase().includes(this.search?.toLowerCase()!)
          );
          break;
        }
        case this.$t("support.utilisateur"): {
          this.tickets = this.tickets.filter((value) =>
              value.utilisateur.toLowerCase().includes(this.search?.toLowerCase()!)
          );
          break;
        }
        case this.$t("support.sujet"): {
          this.tickets = this.tickets.filter((value) =>
              value.sujet.toLowerCase().includes(this.search?.toLowerCase()!)
          );
          break;
        }
        case this.$t("support.type"): {
          this.tickets = this.tickets.filter((value) =>
              value.supportType.toLowerCase().includes(this.search?.toLowerCase()!)
          );
          break;
        }
        case this.$t("support.status"): {
          this.tickets = this.tickets.filter((value) =>
              value.ticketStatus.toLowerCase().includes(this.search?.toLowerCase()!)
          );
          break;
        }
        default: {
          this.tickets = this.backupTickets;
          break;
        }
      }
    }
  }

  @Watch("search")
  onSearchChange() {
    this.tickets = this.backupTickets;
    this.updateSearchTicket();
  }

  @Watch("typeRechercheSelected")
  onTypeRechercheSelectedChange() {
    this.tickets = this.backupTickets;
    this.updateSearchTicket();
  }
}
