

























































































import axios from "axios";
import SearchApp from "@/components/SearchApp.vue";
import DialogConfirmation from "@/components/dialogue/DialogConfirmationComponent.vue";
import UtilisateurDetails from "@/components/administration/utilisateurs/UtilisateurDetails.vue";
import {Component, Vue, Watch} from "vue-property-decorator";
import {TranslateResult} from "vue-i18n";

const moment = require("moment");

@Component({
  components: {SearchApp, DialogConfirmation, UtilisateurDetails}
})
export default class Utilisateurs extends Vue {
  public users: any = []
  public usersBackup: any = []
  public success: boolean = false
  public successMessage: String = ""
  public reloadKey: number = 0
  public loading: boolean = true
  public sortBy: String = "dateCreation"
  public sortDesc: boolean = true
  public searchInput: String = ""
  public showDetailsUtilisateur: boolean = false
  public user: any = null
  public reloadUserDetails: number = 0
  public selected: TranslateResult = ""

  get headers() {
    return [
      {
        text: this.$t("adminUtilisateur.id"),
        align: "center",
        value: "id",
      },
      {
        text: this.$t("adminUtilisateur.mail"),
        align: "center",
        value: "email",
      },
      {
        text: this.$t("adminUtilisateur.nom"),
        align: "center",
        value: "lastName",
      },
      {
        text: this.$t("adminUtilisateur.prenom"),
        align: "center",
        value: "firstName",
      },
      {
        text: this.$t("adminUtilisateur.username"),
        align: "center",
        value: "username",
      },
      {
        text: this.$t("adminUtilisateur.date"),
        align: "center",
        sortable: true,
        value: "dateCreation",
      },
      {
        text: this.$t("adminUtilisateur.verifier"),
        align: "center",
        sortable: true,
        value: "emailVerifier",
      },
      {
        text: this.$t("adminUtilisateur.activer"),
        value: "desactiver",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("adminUtilisateur.details"),
        value: "details",
        align: "center",
        sortable: false,
      },
      {
        text: this.$t("adminUtilisateur.supprimer"),
        value: "supprimer",
        align: "center",
        sortable: false,
      },
    ];
  }

  get typeRecherche() {
    return [
      this.$t("adminUtilisateur.mail"),
      this.$t("adminUtilisateur.nom"),
      this.$t("adminUtilisateur.prenom"),
      this.$t("adminUtilisateur.username"),
    ];
  }

  get typeRechercheSelected() {
    return this.selected;
  }

  set typeRechercheSelected(value: any) {
    this.selected = value;
  }

  get confirm(): DialogConfirmation {
    // Obligatoire sinon il ne reconnait pas la méthode open de DialogConfirmation
    return this.$refs.confirm as DialogConfirmation;
  }

  public mounted() {
    this.selected = this.$t("adminUtilisateur.mail");
    this.loadUsers();
  }

  public loadUsers() {
    this.loading = true;

    this.users = [];

    axios
        .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/user`)
        .then((response) => {
          moment.locale("fr");

          response.data.forEach((user: any) => {
            this.users.push({
              id: user.id,
              firstName: user.firstName,
              lastName: user.lastName,
              username: user.username,
              email: user.email,
              dateCreation: moment
                  .unix(user.createdTimestamp / 1000)
                  .format("L"),
              emailVerifier: user.emailVerified
                  ? "mdi-checkbox-multiple-marked"
                  : "mdi-checkbox-multiple-blank-outline",
              desactiver: user.enabled
                  ? {icon: "mdi-stop-circle", color: "#EF5350", activated: true}
                  : {
                    icon: "mdi-play-circle",
                    color: "#33691E",
                    activated: false,
                  },
            });
          });

          this.loading = false;
        })
        .catch(() => {
        })
        .finally(() => {
          this.usersBackup = this.users;
        });
  }

  public updateSearchUser(event: any) {
    if (event.length > 0 && this.usersBackup.length === 0) {
      this.usersBackup = this.users;
    }

    if (this.usersBackup.length > 0 || event.length > 0) {
      this.users = this.usersBackup;
    }

    if (event.length > 0) {
      this.searchInput = event;

      switch (this.typeRechercheSelected) {
        case this.$t("adminUtilisateur.nom"): {
          this.users = this.users.filter((value: any) =>
              value.lastName.toLowerCase().includes(event.toLowerCase())
          );
          break;
        }
        case this.$t("adminUtilisateur.prenom"): {
          this.users = this.users.filter((value: any) =>
              value.firstName.toLowerCase().includes(event.toLowerCase())
          );
          break;
        }
        case this.$t("adminUtilisateur.username"): {
          this.users = this.users.filter((value: any) =>
              value.username.toLowerCase().includes(event.toLowerCase())
          );
          break;
        }
        case this.$t("adminUtilisateur.mail"): {
          this.users = this.users.filter((value: any) =>
              value.email.toLowerCase().includes(event.toLowerCase())
          );
          break;
        }
      }
    } else {
      this.usersBackup = [];
    }
  }

  public async desactiverUtilisateur(item: any) {
    const texte = item.desactiver.activated
        ? "Êtes-vous sûr de vouloir désactiver <i>"
        : "Êtes-vous sûr de vouloir activer <i>";

    const activer = item.desactiver.activated ? "désactivé" : "activé";

    if (
        await this.confirm.open(
            "Confirmation",
            `${texte} ${item.lastName} ${item.firstName} (${item.username})</i> ?`, true
        )
    ) {
      axios
          .put(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/activateDesactivate/${item.id}`
          )
          .then(() => {
            this.success = true;
            this.successMessage = `Utilisateur ${item.lastName} ${item.firstName} (${item.username}) ${activer}`;
            this.loadUsers();

            this.reloadUserDetails++;
          })
          .catch(() => {
          });
    }
  }

  public async supprimerUtilisateur(item: any) {
    if (
        await this.confirm.open(
            "Confirmation",
            `Êtes-vous sûr de vouloir supprimer <i>${item.lastName} ${item.firstName} (${item.username}) </i> ? <small><strong>CETTE OPERATION EST IRREVERSIBLE !</strong></small>`,
            true
        )
    ) {
      axios
          .delete(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/delete/${item.id}`
          )
          .then(() => {
            this.success = true;
            this.successMessage = `Utilisateur ${item.lastName} ${item.firstName} (${item.username}) supprimer`;

            // Si effectué depuis la page details d'un utilisateur
            this.user = null;
            this.showDetailsUtilisateur = false;

            this.loadUsers();
          })
          .catch(() => {
          });
    }
  }

  public detailsUtilisateur(item: any) {
    this.user = item;
    this.showDetailsUtilisateur = true;
  }

  public retourListeUtilisateur() {
    this.user = null;
    this.showDetailsUtilisateur = false;
    this.success = false;
    this.users = this.usersBackup;
  }

  @Watch("typeRechercheSelected")
  public onTypeRechercheSelectedChange(val: any, old: any) {
    if (val !== old) {
      this.updateSearchUser(this.searchInput);
    }
  }
}
