




























import Vue from "vue";
import axios from "axios";

import {Component} from "vue-property-decorator";

import {AUTH_LOGOUT, AUTH_REFRESH_TOKEN} from "@/store/actions/auth";
import DialogConfirmation from "@/components/dialogue/DialogConfirmationComponent.vue";
import DialogInput from "@/components/dialogue/DialogInputComponent.vue";
import ErrorComponent from "@/components/error/ErrorComponent.vue";

@Component({
  components: {
    ErrorComponent,
    DialogInput,
    DialogConfirmation,
  },
})
export default class NoEntreprise extends Vue {
  error: boolean = false;
  errorMessage: string = "";

  get userData() {
    return this.$store.getters.userData;
  }

  get confirm(): DialogConfirmation {
    // Obligatoire sinon il ne reconnait pas la méthode open de DialogConfirmation
    return this.$refs.confirm as DialogConfirmation;
  }

  get input(): DialogInput {
    return this.$refs.input as DialogInput;
  }

  public async delAccount(): Promise<void> {
    if (
        await this.confirm.open(
            "dialogConfirmation.titleDelete",
            "dialogConfirmation.textDelete",
            null
        )
    ) {
      axios
          .delete(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/delete/${this.userData.sub}`
          )
          .then(() => {
            this.$store.dispatch(AUTH_REFRESH_TOKEN).then(() => {
              this.logout();
            });
          });
    }
  }

  public async createNewEntreprise(): Promise<void> {
    if (
        await this.input.open(
            "dialogInput.titleCreateEntreprise",
            "dialogInput.textCreateEntreprise",
            null
        )
    ) {
      axios
          .post(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/create/${this.input.input}`
          )
          .then(() => {
            this.$store.dispatch(AUTH_REFRESH_TOKEN).then(() => {
              this.$router.push({name: "Applications"}).catch(() => {});
            });
          })
          .catch((error) => {
            this.error = true;
            this.errorMessage = error.response.data.error;
          });
    }
  }

  public async joinEntreprise(): Promise<void> {
    if (
        await this.input.open(
            "dialogInput.titleJoinEntreprise",
            "dialogInput.textJoinEntreprise",
            null
        )
    ) {
      axios
          .post(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/join/${this.input.input}`
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            this.error = true;
            this.errorMessage = error.response.data.error;
          });
    }
  }

  public logout(): void {
    this.$store.dispatch(AUTH_LOGOUT, this.userData.session_state).then(() => {
      window.location.href = `${window.location.protocol}//${window.location.host}/`;
    });
  }
}
