import { render, staticRenderFns } from "./Releases.vue?vue&type=template&id=5c0f1270&scoped=true&"
import script from "./Releases.vue?vue&type=script&lang=ts&"
export * from "./Releases.vue?vue&type=script&lang=ts&"
import style0 from "./Releases.vue?vue&type=style&index=0&id=5c0f1270&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c0f1270",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VHover } from 'vuetify/lib/components/VHover';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VHover,VListItem,VListItemAvatar,VListItemTitle,VTooltip})
