






























































import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import {FACTURES_REQUEST} from "@/api/actions/pricing/const_facture";
import {TranslateResult} from "vue-i18n";

@Component({})
export default class Factures extends Vue {
  @Prop({}) nomEntrepriseAdmin: string | undefined;

  factures: {}[] = [];

  firstLoad: boolean = true;

  error: boolean = false;
  errorMessage: string = "";
  success: boolean = false;
  successMessage: string = "";

  show: boolean = true;
  loading: boolean = true;

  entrepriseName: string = "";

  sortBy: string = "date";
  sortDesc: boolean = true;

  get headers(): {
    text: TranslateResult;
    align: string;
    value: string;
    sortable?: boolean;
  }[] {
    return [
      {
        text: this.$t("facture.application"),
        align: "center",
        value: "application",
      },
      {
        text: this.$t("facture.nomApplication"),
        align: "center",
        value: "nomApplication",
      },
      {
        text: this.$t("facture.plan"),
        align: "center",
        value: "deploymentPlan",
      },
      {
        text: this.$t("facture.date"),
        value: "date",
        align: "center",
      },
      {
        text: this.$t("facture.telecharger"),
        value: "actions",
        align: "center",
        sortable: false,
      },
    ];
  }

  get companyName() {
    return this.$store.getters.entreprise;
  }

  public mounted(): void {
    this.init();
  }

  public init(): void {
    if (
        this.nomEntrepriseAdmin !== undefined &&
        this.nomEntrepriseAdmin.length > 0
    ) {
      this.entrepriseName = this.nomEntrepriseAdmin;
    } else {
      this.entrepriseName = this.companyName;
    }

    if (this.entrepriseName) {
      this.initialize();
    }
  }

  public initialize(): void {
    this.$store
        .dispatch(FACTURES_REQUEST, this.entrepriseName)
        .then((response) => {
          this.factures = response;
          this.loading = false;
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error;
        })
        .finally(() => (this.firstLoad = false));
  }

  public downloadBill(item: any): void {
    const filename = `facture_${item.nomApplication}_${item.date}.pdf`;

    const element = document.createElement("a");

    element.setAttribute(
        "href",
        `data:text/plain;;base64,${encodeURIComponent(item.pdf)}`
    );

    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  }

  @Watch("companyName")
  onCompanyNameChange(newV: any) {
    if (newV !== undefined) {
      this.init();
    }
  }
}
