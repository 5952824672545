
































import Vue from "vue";

import {Component, Watch} from "vue-property-decorator";

import Stats from "@/components/administration/stats/Stats.vue";
import Applications from "@/components/administration/Applications.vue";
import Utilisateurs from "@/components/administration/utilisateurs/Utilisateurs.vue";
import Entreprises from "@/components/administration/entreprises/Entreprises.vue";
import Pricing from "@/components/administration/pricing/Pricing.vue";
import Promotions from "@/components/administration/promotions/Promotions.vue";
import Support from "@/components/account/support/Support.vue";
import Logs from "@/components/administration/logs/Logs.vue";

@Component({
  components: {
    Stats,
    Applications,
    Utilisateurs,
    Pricing,
    Promotions,
    Entreprises,
    Support,
    Logs,
  },
})
export default class Administration extends Vue {
  links: { name: string; icon: string; displayName: string }[] = [
    {
      name: "statistiques",
      icon: "mdi-chart-bar",
      displayName: "administration.statistiques",
    },
    {
      name: "applications",
      icon: "mdi-application-braces",
      displayName: "administration.applications",
    },
    {
      name: "utilisateurs",
      icon: "mdi-account-group",
      displayName: "administration.utilisateurs",
    },
    {
      name: "entreprises",
      icon: "mdi-domain",
      displayName: "administration.entreprises",
    },
    {
      name: "pricing",
      icon: "mdi-currency-eur",
      displayName: "administration.pricing",
    },
    {
      name: "promotions",
      icon: "mdi-currency-eur-off",
      displayName: "administration.promotion",
    },
    {
      name: "support",
      icon: "mdi-lifebuoy",
      displayName: "administration.support",
    },
    {
      name: "logs",
      icon: "mdi-file-document-multiple-outline",
      displayName: "administration.logs",
    },
  ];
  activeLink: number = 0;

  get panel(): string {
    return this.$route.params.panel;
  }

  public mounted(): void {
    if (!this.panel) {
      this.$router.push({
        name: "Administration",
        params: {panel: "statistiques"},
      }).catch(() => {});
    }

    this.checkPanel();
  }

  checkPanel(): void {
    let isOk = false;

    for (let i = 0; i < this.links.length; i++) {
      if (this.links[i].name === this.panel) {
        isOk = true;
        break;
      }
    }

    if (!isOk) {
      this.$router.push({name: "NotFoundPage"}).catch(() => {});
    }
  }

  @Watch("activeLink")
  onActiveLinkChange(newV: number, oldV: number): void {
    if (newV === undefined) {
      this.activeLink = oldV;
    }
  }

  @Watch("panel")
  onPanelChange(): void {
    this.checkPanel();
  }
}
