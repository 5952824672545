








































import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class Secrets extends Vue {
  @Prop() secrets: any

  public selectedPwd: boolean = false

  get headers() {
    return [
      {
        text: this.$t("appSecrets.typeSecret"),
        align: "center",
        sortable: false,
        width: "300",
      },
      {
        text: this.$t("appSecrets.secret"),
        align: "center",
        sortable: false,
      },
    ];
  }

  public showPwd(item: any) {
    this.selectedPwd = item;
  }

  public hidePwd() {
    this.selectedPwd = false;
  }

  public decodeBase64(value: any) {
    return Buffer.from(value, "base64");
  }
}
