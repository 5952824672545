





















































import axios from "axios";
import moment from "moment";
import {Component, Vue} from "vue-property-decorator";

@Component
export default class Historique extends Vue {

  public historique: any = []
  public dialog: boolean = false
  public detailsText: String = ""
  public loading: boolean = true

  get headers() {
    return [
      {
        text: this.$t("appHistorique.username"),
        align: "center",
        sortable: false,
        value: "utilisateur",
      },
      {text: this.$t("appHistorique.date"), value: "date", align: "center"},
      {
        text: this.$t("appHistorique.action"),
        align: "center",
        value: "action",
        sortable: false,
      },
      {
        text: this.$t("appHistorique.details"),
        align: "center",
        value: "details",
        sortable: false,
      },
    ];
  }

  public mounted() {
    this.loadHistorique();
  }

  public loadHistorique() {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/historique/${this.$route.params.namespace}/${this.$route.params.app}`
        )
        .then((response) => {
          moment.locale("fr");
          response.data.forEach((value: any) => {
            this.historique.push({
              utilisateur: value.utilisateur,
              date: moment(value.date).calendar(),
              action: value.action,
              timestamp: moment(value.date).format("x"),
              details: value.details,
            });
          });
        })
        .catch(() => {
        }).finally(() => {
          this.loading = false;  
        });
  }

  public displayDetails(item: any) {
    this.detailsText = item.details;
  }
}
