var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"disabled":!_vm.hasEntreprise,"allow-overflow":"","max-height":"50%","right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":!_vm.hasEntreprise,"text":""}},'v-btn',attrs,false),on),[(_vm.notifications.length !== 0)?[_c('v-badge',{attrs:{"content":_vm.notifications.length,"value":_vm.notifications.length,"color":"secondary","overlap":""}},[_c('v-icon',[_vm._v("mdi-bell-outline")])],1)]:[_c('v-icon',[_vm._v("mdi-bell-outline")])]],2)]}}])},[_c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"500px","min-width":"500px"}},[_c('v-sheet',[_c('v-card-title',{staticClass:"v-subheader font-weight-bold"},[_vm._v(" Notifications ")]),_c('v-list',[_c('v-list-item-group',[(_vm.notifications.length === 0)?_c('v-list-item',{attrs:{"dense":"","three-line":""}},[_c('v-list-item-avatar',{attrs:{"color":"purple"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" mdi-lightbulb-on-outline ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{},[_vm._v("Aucune notification ")]),_c('v-list-item-subtitle',{staticClass:"caption"})],1)],1):_vm._l((_vm.notifications),function(item,i){return _c('v-hover',{key:i,scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-list-item',{attrs:{"disabled":item.disabled,"to":item.sujet.includes('application') &&
                  item.sujet !== 'Suppression application'
                  ? {
                    name: 'Application',
                    params: {
                      namespace: item.entreprise,
                      app: item.release,
                      cluster: 'default',
                    },
                  }
                  : item.sujet === 'Invitation'
                    ? { name: 'Account' }
                    : { name: 'Applications' },"active-class":"no-active","dense":"","three-line":""}},[_c('v-list-item-avatar',{attrs:{"color":item.couleur}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(" "+_vm._s(item.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',{},[_vm._v(_vm._s(item.sujet)+" ")]),_c('v-list-item-subtitle',{staticClass:"caption"},[_vm._v(_vm._s(item.message)+" ")])],1),(!hover)?_c('v-list-item-action-text',[_vm._v(" "+_vm._s(_vm._f("formatDateNotification")(item.date))+" ")]):_vm._e(),(hover)?_c('v-list-item-action',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteNotification(item)}}},[_c('v-icon',[_vm._v("mdi-close-circle")])],1)],1):_vm._e()],1)]}}],null,true)})})],2)],1),_c('v-card-actions',{staticClass:"text-center py-2"},[_c('v-btn',{attrs:{"disabled":_vm.notifications.length === 0,"loading":_vm.deleteAll,"block":"","text":"","x-small":""},on:{"click":_vm.deleteAllNotification}},[_c('span',[_vm._v("Tout supprimer")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }