




























































































































































































































































import axios from "axios";
import {Component, Prop, Vue} from "vue-property-decorator";

const moment = require("moment");

@Component
export default class UtilisateurDetails extends Vue {
  @Prop() user: any
  @Prop() successMessage: any

  public userData: any = null
  public success: boolean = false
  public loading: boolean = true
  public isAdmin: boolean = false
  public btnLoading: boolean = false
  public avatarActuel: any = null
  public showDetails: boolean = true

  public mounted() {
    this.getUserData();

    // Si il y a un message de success, utile pour la désactivation/activation d'un compte
    if (this.successMessage) {
      this.success = true;
    }
  }

  public getUserData() {
    this.loading = true;

    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/${this.user.id}`
        )
        .then((response) => {
          this.userData = response.data;

          if (this.userData.roles.length > 0) {
            for (let i = 0; i < this.userData.roles.length; i++) {
              if (this.userData.roles[i].name === "ADMIN") {
                this.isAdmin = true;
              }
            }
          }

          if (this.userData.sessions.length > 0) {
            moment.locale("fr");

            for (let j = 0; j < this.userData.sessions.length; j++) {
              this.userData.sessions[j].start = moment(
                  this.userData.sessions[j].start
              ).format("LLL");
              this.userData.sessions[j].lastAccess = moment(
                  this.userData.sessions[j].lastAccess
              ).format("LLL");
            }
          }

          this.getAvatar();
        })
        .catch(() => {
          this.loading = false;
        });
  }

  public getAvatar() {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/avatar/${this.user.id}`
        )
        .then((response) => {
          if (response.data != null) {
            this.avatarActuel = response.data;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
  }

  public retourListeUtilisateur() {
    this.$emit("retourListeUtilisateur");
  }

  public logoutUtilisateur() {
    this.btnLoading = true;

    axios
        .put(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/${this.user.id}`
        )
        .then(() => {
          this.getUserData();
          this.successMessage = "Utilisateur déconnecté";
          this.success = true;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
  }

  public desactiverUtilisateur() {
    this.btnLoading = true;
    this.$emit("desactiverUtilisateur", this.user);
  }

  public supprimerUtilisateur() {
    this.btnLoading = true;
    this.$emit("supprimerUtilisateur", this.user);
  }

  public logoutSession(idSession: any) {
    axios
        .delete(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/logout/${idSession}`
        )
        .then(() => {
          this.getUserData();
          this.successMessage = "Utilisateur déconnecté";
          this.success = true;
          this.btnLoading = false;
        })
        .catch(() => {
          this.btnLoading = false;
        });
  }
}
