import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from "axios"
import Vuex from 'vuex'
// @ts-ignore
import VueClipboards from 'vue-clipboards'
import store from "./store"
import {AUTH_LOGOUT, AUTH_REFRESH_TOKEN} from "./store/actions/auth"

import Highcharts from "highcharts"
import loadHighchartsMore from 'highcharts/highcharts-more'
import loadSolidGauge from 'highcharts/modules/solid-gauge'
import HighchartsVue from "highcharts-vue"

import i18n from './plugins/i18n'

export const theme: object = {
  colors: ["#2b908f", "#90ee7e", "#f45b5b", "#7798BF", "#aaeeee", "#ff0066", "#eeaaee",
    "#55BF3B", "#DF5353", "#7798BF", "#aaeeee"],
  chart: {
    backgroundColor: '#1e1e1e',
    style: {
      fontFamily: "'Unica One', sans-serif"
    },
    plotBorderColor: '#606063'
  },
  title: {
    style: {
      color: '#E0E0E3',
      textTransform: 'uppercase',
      fontSize: '20px'
    }
  },
  subtitle: {
    style: {
      color: '#E0E0E3',
      textTransform: 'uppercase'
    }
  },
  xAxis: {
    gridLineColor: '#707073',
    labels: {
      style: {
        color: '#E0E0E3'
      }
    },
    lineColor: '#707073',
    minorGridLineColor: '#505053',
    tickColor: '#707073',
    title: {
      style: {
        color: '#A0A0A3'

      }
    }
  },
  yAxis: {
    gridLineColor: '#707073',
    labels: {
      style: {
        color: '#E0E0E3'
      }
    },
    lineColor: '#707073',
    minorGridLineColor: '#505053',
    tickColor: '#707073',
    tickWidth: 1,
    title: {
      style: {
        color: '#A0A0A3'
      }
    }
  },
  tooltip: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    style: {
      color: '#F0F0F0'
    }
  },
  plotOptions: {
    series: {
      dataLabels: {
        color: '#B0B0B3'
      },
      marker: {
        lineColor: '#333'
      }
    },
    boxplot: {
      fillColor: '#505053'
    },
    candlestick: {
      lineColor: 'white'
    },
    errorbar: {
      color: 'white'
    }
  },
  legend: {
    itemStyle: {
      color: '#E0E0E3'
    },
    itemHoverStyle: {
      color: '#FFF'
    },
    itemHiddenStyle: {
      color: '#606063'
    }
  },
  credits: {
    style: {
      color: '#666'
    }
  },
  labels: {
    style: {
      color: '#707073'
    }
  },

  drilldown: {
    activeAxisLabelStyle: {
      color: '#F0F0F3'
    },
    activeDataLabelStyle: {
      color: '#F0F0F3'
    }
  },

  navigation: {
    buttonOptions: {
      symbolStroke: '#DDDDDD',
      theme: {
        fill: '#505053'
      }
    }
  },

  // scroll charts
  rangeSelector: {
    buttonTheme: {
      fill: '#505053',
      stroke: '#000000',
      style: {
        color: '#CCC'
      },
      states: {
        hover: {
          fill: '#707073',
          stroke: '#000000',
          style: {
            color: 'white'
          }
        },
        select: {
          fill: '#000003',
          stroke: '#000000',
          style: {
            color: 'white'
          }
        }
      }
    },
    inputBoxBorderColor: '#505053',
    inputStyle: {
      backgroundColor: '#333',
      color: 'silver'
    },
    labelStyle: {
      color: 'silver'
    }
  },

  navigator: {
    handles: {
      backgroundColor: '#666',
      borderColor: '#AAA'
    },
    outlineColor: '#CCC',
    maskFill: 'rgba(255,255,255,0.1)',
    series: {
      color: '#7798BF',
      lineColor: '#A6C7ED'
    },
    xAxis: {
      gridLineColor: '#505053'
    }
  },

  scrollbar: {
    barBackgroundColor: '#808083',
    barBorderColor: '#808083',
    buttonArrowColor: '#CCC',
    buttonBackgroundColor: '#606063',
    buttonBorderColor: '#606063',
    rifleColor: '#FFF',
    trackBackgroundColor: '#404043',
    trackBorderColor: '#404043'
  },

  // special colors for some of the
  legendBackgroundColor: 'rgba(0, 0, 0, 0.5)',
  background2: '#505053',
  dataLabelsColor: '#B0B0B3',
  textColor: '#C0C0C0',
  contrastTextColor: '#F0F0F3',
  maskColor: 'rgba(255,255,255,0.3)'
}

export const theme1: object = {
  // colors: ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce', '#492970',
  //     '#f28f43', '#77a1e5', '#c42525', '#a6c96a'],
  chart: {
    backgroundColor: '#fff',
    borderWidth: 0,
    plotBackgroundColor: '#fff',
    plotShadow: false,
    plotBorderWidth: 0
  },
  title: {
    style: {
      color: '#274b6d',//#3E576F',
      fontSize: '16px'
    }
  },
  subtitle: {
    style: {
      color: '#4d759e'
    }
  },
  xAxis: {
    gridLineWidth: 0,
    lineColor: '#C0D0E0',
    tickColor: '#C0D0E0',
    labels: {
      style: {
        color: '#666',
        cursor: 'default',
        fontSize: '11px',
        lineHeight: '14px'
      }
    },
    title: {
      style: {
        color: '#4d759e',
        fontWeight: 'bold'
      }
    }
  },
  yAxis: {
    minorTickInterval: null,
    lineColor: '#C0D0E0',
    lineWidth: 1,
    tickWidth: 1,
    tickColor: '#C0D0E0',
    labels: {
      style: {
        color: '#666',
        cursor: 'default',
        fontSize: '11px',
        lineHeight: '14px'
      }
    },
    title: {
      style: {
        color: '#4d759e',
        fontWeight: 'bold'
      }
    }
  },
  legend: {
    itemStyle: {
      color: '#274b6d',
      fontSize: '12px'
    },
    itemHoverStyle: {
      color: '#000'
    },
    itemHiddenStyle: {
      color: '#CCC'
    }
  },
  labels: {
    style: {
      color: '#3E576F'
    }
  },

  navigation: {
    buttonOptions: {
      theme: {
        stroke: '#CCCCCC'
      }
    }
  }
}

loadHighchartsMore(Highcharts)
loadSolidGauge(Highcharts)

Vue.prototype.$highcharts = Highcharts
Vue.use(HighchartsVue, {Highcharts})

if (localStorage.getItem("dark_theme") === 'true') {
  Highcharts.setOptions(theme)
} else {
  Highcharts.setOptions(theme1)
}

Vue.config.productionTip = false

Vue.use(VueClipboards)
Vue.use(Vuex)

axios.defaults.headers.common = {
  "Authorization": `Bearer ${localStorage.getItem('token')}`,
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
}

let req = true
axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  const originalRequest = error.config
  if (error.response.status !== 401) {
    return Promise.reject(error)
  } else if (req) {
    req = false
    if (error.config.url.includes('/api/authentication/refresh')) {
      const url = router.currentRoute.path !== "/login" ? `?from=${router.currentRoute.path}` : "";
      store.dispatch(AUTH_LOGOUT, store.getters.userData.session_state).then(() => {
        router.push(`/login${url}`).catch(() => {});
      }).catch(() => {
        router.push(`/login${url}`).catch(() => {});
      }).finally(() => {
        req = true
      })
      return Promise.reject(error)
    }

    return store.dispatch(AUTH_REFRESH_TOKEN).then((resp) => {
      const config = error.config
      config.headers['Authorization'] = `Bearer ${resp.data.access_token}`
      return new Promise((resolve, reject) => {
        axios.request(originalRequest).then(response => {
          resolve(response)
          return axios(originalRequest)
        }).catch((erreur) => {
          reject(erreur)
        }).finally(() => {
          req = true
        })
      })
    }).catch((err) => {
      const url = router.currentRoute.path !== "/login" ? `?from=${router.currentRoute.path}` : "";
      store.dispatch(AUTH_LOGOUT, store.getters.userData.session_state).then(() => {
        router.push(`/login${url}`).catch(() => {})
      }).catch(() => {
        router.push(`/login${url}`).catch(() => {})
      }).finally(() => {
        req = true
      })
      Promise.reject(err)
    })
  }
})

new Vue({
  router,
  vuetify,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
