

























































































































































import axios from "axios";
import {SET_APPLICATION_VALUES} from "@/store/actions/data";
import {FACTURES_GENERATE} from "@/api/actions/pricing/const_facture";
import {PRICING_REQUEST} from "@/api/actions/pricing/const_pricing";
import {PROMO_REQUEST_ONE, PROMO_USE,} from "@/api/actions/pricing/const_promotion";
import {Component} from "vue-property-decorator";
import Vue from "vue";
import VueI18n from "vue-i18n";

@Component
export default class PriceDetails extends Vue {

  public loading: boolean = false
  public show: boolean = false
  public showAdresse: boolean = false
  public success: boolean = false
  public successMsg: VueI18n.TranslateResult = ""
  public error: boolean = false
  public errorMsg: VueI18n.TranslateResult = ""
  public sousTotal: number = 0
  public taxe: number = 0
  public taxePourcentage: number = 20
  public total: number = 0
  public reduction: number = 0
  public reductionPourcentage: number = 0
  public adresseFacturation: any = null
  public adresseSet: boolean = false
  public pricing: any = {}
  public codePromo: String = ""

  get applicationValues() {
    return this.$store.getters.applicationValues
  }

  get entreprise() {
    return this.$store.getters.entreprise
  }

  public mounted() {
    this.getPricingData()
    this.getAdresseFacturation()
  }

  public submit() {
    this.loading = true;
    axios
        .post(
            `${
                process.env.VUE_APP_SPRING_API_BASEURL
            }/api/services/clusters/default/namespaces/${localStorage.getItem(
                "namespace"
            )}/deploy`,
            {
              appRepositoryResourceName:
              this.applicationValues.chart.availablePackageRef.identifier.split('/')[0],
              appRepositoryResourceNamespace:
              this.applicationValues.chart.availablePackageRef.context.namespace,
              chartName: this.applicationValues.chart.name,
              releaseName: this.applicationValues.name,
              version: this.applicationValues.version.pkgVersion,
              values: this.applicationValues.values.toString(),
              datas: this.applicationValues.configuration,
              deploymentPlan: this.applicationValues.plan,
            }
        )
        .then((response) => {
          // Mise à jour du code promo
          if (this.codePromo.length > 0) {
            this.$store.dispatch(PROMO_USE, this.codePromo);
          }

          // Envois de la facture
          this.$store.dispatch(FACTURES_GENERATE, {
            application: this.applicationValues.chart.name,
            nomApplication: this.applicationValues.name,
            entreprise: this.$store.getters.entreprise,
            deploymentPlan: this.applicationValues.plan,
            prixHT: this.sousTotal,
            tauxReduction: this.reductionPourcentage,
          });

          this.$router.push({
            name: "Application",
            params: {
              cluster: "default",
              namespace: response.data.data[0].namespace,
              app: this.applicationValues.name,
            },
          }).catch(() => {});
        })
        .catch((error) => {
          if (error.response.data.error)
            this.$emit("error", this.$t(error.response.data.error));
          else
            this.$emit("error", error.response.data.message);
          this.loading = false;
        });
  }

  public calculPrice(applyReduction: any) {
    if (applyReduction && this.reductionPourcentage > 0) {
      if (this.reductionPourcentage >= 100) {
        this.reduction = this.sousTotal;
        this.taxe = 0;
        this.total = 0;
      } else {
        this.reduction = (this.sousTotal * this.reductionPourcentage) / 100;
        this.taxe =
            ((this.sousTotal - this.reduction) * this.taxePourcentage) / 100;
        this.total = this.sousTotal - this.reduction + this.taxe;
      }
    } else {
      this.sousTotal =
          this.applicationValues.pricing *
          ((this.getConfiguration("resources.limits.cpu")
                  ? this.getConfiguration("resources.limits.cpu").value.split(
                  "m"
              )[0] / 1000
                  : 1) *
              this.pricing.CPU +
              (this.getConfiguration("resources.limits.memory")
                  ? this.getConfiguration("resources.limits.memory").value.split(
                      "Mi"
                  )[0]
                  : 1) *
              this.pricing.RAM +
              this.pricing.storage);
      this.taxe = (this.sousTotal * this.taxePourcentage) / 100;
      this.total = this.sousTotal + this.taxe;
      this.reduction = 0;
    }
  }

  public appliquerPromotion() {
    this.loading = true;

    if (this.codePromo.length === 0) {
      this.codePromo = "undefined";
    }

    this.$store
        .dispatch(PROMO_REQUEST_ONE, this.codePromo)
        .then((response) => {
          this.reductionPourcentage = response;
          this.show = false;
          this.success = true;
          this.successMsg = this.$t("success.usedPromo", {
            msg: this.codePromo,
          });
          this.error = false;
          this.calculPrice(true);
        })
        .catch(() => {
          this.reductionPourcentage = 0;
          this.error = true;
          this.errorMsg = this.$t("error.invalidPromo");
          this.success = false;
          this.calculPrice(false);
        })
        .finally(() => {
          this.loading = false;
        });
  }

  public cancelCart() {
    this.loading = true;
    this.$store.dispatch(SET_APPLICATION_VALUES, {});
    this.$router.push({name: "Applications"}).catch(() => {});
  }

  public getAdresseFacturation() {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/entreprises/${this.entreprise}`
        )
        .then((response) => {
          this.adresseFacturation = response.data;
          if (
              this.adresseFacturation.adresse === null &&
              this.adresseFacturation.ville === null &&
              this.adresseFacturation.codePostal === null
          ) {
            this.error = true;
            this.errorMsg = this.$t("error.noAdresse");
          } else {
            this.adresseSet = true;
          }
        })
        .catch((error) => {
          this.error = true;
          this.errorMsg = error.response.data;
        });
  }

  public getPricingData() {
    this.$store
        .dispatch(PRICING_REQUEST)
        .then((res) => {
          this.pricing = res.data;
        })
        .catch((err) => {
          this.pricing = {
            storage: 0,
            RAM: 0,
            CPU: 0,
          };
          this.error = true;
          const msg = this.$t(`error.${err}`);
          this.errorMsg = msg.length > 0 ? msg : err;
        })
        .finally(() => {
          this.calculPrice(false);
        });
  }

  public getConfiguration(name: any) {
    if (this.applicationValues.configuration)
      return this.applicationValues.configuration.find(
          (app: any) => app.key === name
      );
  }
}
