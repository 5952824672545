





































import Vue from "vue";

import {Component, Prop} from "vue-property-decorator";

import StatsPie from "@/components/administration/stats/StatsPie.vue";
import {TranslateResult} from "vue-i18n";

@Component({
  components: {
    StatsPie,
  },
})
export default class StatsApp extends Vue {
  @Prop({}) stats: any;

  statsApplication: {
    stat: number;
    texte: TranslateResult;
    couleur: string;
    icon: string;
  }[] = [];

  public mounted(): void {
    this.initStatsApplication();
  }

  public initStatsApplication(): void {
    this.statsApplication = [
      {
        stat: this.stats.backStats.applicationNb,
        texte: this.$t("statsApp.application"),
        couleur: "info",
        icon: "mdi-application-brackets",
      },
      {
        stat: this.stats.backStats.applicationActiveNb,
        texte: this.$t("statsApp.lancer"),
        couleur: "success",
        icon: "mdi-application-cog",
      },
      {
        stat: this.stats.backStats.applicationInactiveNb,
        texte: this.$t("statsApp.pause"),
        couleur: "error",
        icon: "mdi-movie-remove",
      },
    ];
  }
}
