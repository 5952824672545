











import Vue from "vue";
import axios from "axios";

import {Component} from "vue-property-decorator";

import Release from "@/components/Release.vue";
import Alert from "@/components/alert/Alert.vue";

@Component({
  components: {
    Alert,
    Release,
  },
})
export default class Application extends Vue {
  data: [] = [];
  error: boolean = false;

  public mounted(): void {
    this.loadData();
  }

  public loadData(): void {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/services/clusters/${this.$route.params.cluster}/namespaces/${this.$route.params.namespace}/releases/${this.$route.params.app}`
        )
        .then((response) => {
          this.data = response.data.data;
        })
        .catch(() => {
          this.$router.push({name: "Applications"}).catch(() => {});
        });
  }

  public reloadData(): void {
    this.loadData();
  }
}
