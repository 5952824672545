






















































































































import Vue from "vue";

import {Component} from "vue-property-decorator";

import axios from "axios";
import Stomp, {Client} from "webstomp-client";
import SockJS from "sockjs-client";

const moment = require("moment");
let stompClient: Client | null = null;

@Component({
  filters: {
    formatDateNotification(date: string): string {
      moment.locale("fr");
      return moment(date).fromNow();
    },
  },
})
export default class Notifications extends Vue {
  notifications: { disabled: boolean; date: string }[] = [];
  deleteAll: boolean = false;

  get hasEntreprise() {
    return this.$store.getters.haveEnterprise;
  }

  get userData() {
    return this.$store.getters.userData;
  }

  public mounted(): void {
    if (this.$store.getters.isAuthenticated) {
      this.connectSocket();
      this.getNotifications();
    }
  }

  public connectSocket(): void {
    const socket = new SockJS(
        `${
            process.env.VUE_APP_SPRING_API_BASEURL
        }/api/connect?access_token=${localStorage.getItem("token")}`
    );
    stompClient = Stomp.over(socket);
    stompClient.debug = () => {
    };
    const that = this;
    stompClient.connect({}, function () {
      stompClient!.subscribe(
          `/topic/${that.userData.sub}`,
          function (messageOutput: any) {
            that.handleMessageReceipt(messageOutput.body);
          }
      );
    });
  }

  public handleMessageReceipt(messageOutput: string): void {
    this.notifications.push(JSON.parse(messageOutput));
    this.notifications.sort(
        (a, b) => moment(b.date).format("x") - moment(a.date).format("x")
    );
  }

  public getNotifications(): void {
    this.notifications = [];
    axios
        .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/notifications`)
        .then((response) => {
          response.data.forEach((value: any) => {
            this.notifications.push(value);
          });
          this.notifications.sort(
              (a, b) => moment(b.date).format("x") - moment(a.date).format("x")
          );
        })
        .catch(() => {
        });
  }

  public deleteNotification(element: any): void {
    element.disabled = true;
    axios
        .delete(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/notifications/${this.userData.sub}/${element.id}`
        )
        .then(() => {
          this.notifications.splice(this.notifications.indexOf(element), 1);
          this.notifications.sort(
              (a, b) => moment(b.date).format("x") - moment(a.date).format("x")
          );
        })
        .catch(() => {
        });
  }

  public deleteAllNotification(): void {
    this.notifications.forEach((value) => (value.disabled = true));
    this.deleteAll = true;
    axios
        .delete(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/user/notifications/${this.userData.sub}/all`
        )
        .then(() => {
          this.notifications = [];
        })
        .catch(() => {
        })
        .finally(() => {
          this.deleteAll = false;
        });
  }
}
