var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-center"},[_c('v-app',{attrs:{"id":"inspire"}},[_c('AppBar',{attrs:{"entreprises":_vm.entreprises,"links":_vm.links,"loading-app":_vm.loadingApp,"namespaces":_vm.namespaces},on:{"darkMode":function($event){return _vm.darkMode()},"logout":function($event){return _vm.logout()}}}),_c('AppBarMobile',{attrs:{"entreprises":_vm.entreprises,"links":_vm.links,"namespaces":_vm.namespaces},on:{"darkMode":function($event){return _vm.darkMode()},"logout":function($event){return _vm.logout()}}}),_c('v-main',{staticClass:"background"},[_c('v-container',{attrs:{"fluid":""}},[(
            _vm.$route.name !== 'Login' &&
              _vm.$route.name !== 'NotFoundPage' &&
              _vm.$route.name !== '500' &&
              _vm.$route.name !== 'Signup' &&
              _vm.$route.name !== 'Action' &&
              _vm.$route.name !== 'ForgotPassword' &&
              _vm.$route.name !== 'Callback' &&
              _vm.userData &&
              !_vm.userData.email_verified
          )?_c('v-alert',{attrs:{"dense":"","prominent":"","type":"warning"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" "+_vm._s(_vm.$t("app.activation"))+" ")]),_c('v-col',{staticClass:"shrink"},[_c('v-btn',{attrs:{"loading":_vm.mailLoad,"color":"tertiary","text":""},on:{"click":_vm.sendEmail}},[_vm._v(_vm._s(_vm.$t("app.mailActivation"))+" ")])],1)],1)],1):_vm._e(),(
            _vm.$route.name !== 'Login' &&
              _vm.$route.name !== 'NotFoundPage' &&
              _vm.$route.name !== '500' &&
              _vm.$route.name !== 'Signup' &&
              _vm.$route.name !== 'Action' &&
              _vm.$route.name !== 'ForgotPassword' &&
              _vm.$route.name !== 'Callback' &&
              _vm.invitation
          )?_c('v-alert',{attrs:{"dense":"","prominent":"","type":"info"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"grow"},[_vm._v(" "+_vm._s(_vm.$t("app.invitation", {msg: _vm.inviteCompany}))+" ")])],1)],1):_vm._e(),_c('router-view',{key:_vm.viewKey})],1)],1),_c('cookie-consent')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }