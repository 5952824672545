import {
  AUTH_CREATE_ACCOUNT,
  AUTH_CREATE_ACCOUNT_AND_JOIN,
  AUTH_CREATION_SUCCESS,
  AUTH_ERROR,
  AUTH_LOGOUT,
  AUTH_PROVIDER,
  AUTH_REFRESH_TOKEN,
  AUTH_REQUEST,
  AUTH_SUCCESS,
  AUTH_SUCCESS_PROVIDER
} from "../actions/auth"
import axios from "axios"

const state = {
  token: localStorage.getItem("token") || "",
  status: "",
  jwtdata: localStorage.getItem("token") ? JSON.parse(Buffer.from((localStorage.getItem("token") || "").split(".")[1], "base64").toString()) : "",
  hasLoadedOnce: false
}

const getters = {
  isAuthenticated: (state: any) => !!state.token,
  userData: (state: any) => state.jwtdata
}

const actions = {
  [AUTH_REQUEST]: ({commit}: any, user: any) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST)
      delete axios.defaults.headers.common['Authorization']
      axios({url: `${process.env.VUE_APP_SPRING_API_BASEURL}/api/authentication/login`, data: user, method: 'POST'})
        .then(resp => {
          localStorage.setItem("token", resp.data.access_token)
          localStorage.setItem("refresh-token", resp.data.refresh_token)
          axios.defaults.headers.common = {
            "Authorization": `Bearer ${resp.data.access_token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
          commit(AUTH_SUCCESS, resp)
          resolve(resp)
        })
        .catch(err => {
          commit(AUTH_ERROR, err)
          localStorage.removeItem('token')
          localStorage.removeItem('refresh-token')
          reject(err)
        })
    });
  },
  [AUTH_CREATE_ACCOUNT]: ({commit}: any, user: any) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_CREATE_ACCOUNT)
      delete axios.defaults.headers.common['Authorization']
      axios({url: `${process.env.VUE_APP_SPRING_API_BASEURL}/api/authentication/create`, data: user, method: 'POST'})
        .then(response => {
          commit(AUTH_CREATION_SUCCESS)
          resolve(response)
        })
        .catch(error => {
          commit(AUTH_ERROR, error)
          reject(error)
        })
    })
  },
  [AUTH_CREATE_ACCOUNT_AND_JOIN]: ({commit}: any, user: any) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_CREATE_ACCOUNT_AND_JOIN)
      delete axios.defaults.headers.common['Authorization']
      axios({
        url: `${process.env.VUE_APP_SPRING_API_BASEURL}/api/authentication/createAndJoin`,
        data: user,
        method: 'POST'
      })
        .then(response => {
          commit(AUTH_CREATION_SUCCESS)
          resolve(response)
        })
        .catch(error => {
          commit(AUTH_ERROR, error)
          reject(error)
        })
    })
  },
  [AUTH_LOGOUT]: ({commit}: any, sessionId: any) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_LOGOUT);
      delete axios.defaults.headers.common['Authorization']
      axios({
        url: `${process.env.VUE_APP_SPRING_API_BASEURL}/api/authentication/logout`,
        data: {token: sessionId},
        method: 'PUT'
      })
        .then(() => {
          localStorage.removeItem("token");
          localStorage.removeItem('refresh-token')
          localStorage.removeItem('namespace')
          resolve("")
        })
        .catch(err => {
          localStorage.removeItem("token");
          localStorage.removeItem('refresh-token')
          localStorage.removeItem('namespace')
          reject(err)
        })
    })
  },
  [AUTH_REFRESH_TOKEN]: ({commit}: any) => {
    return new Promise((resolve, reject) => {
      delete axios.defaults.headers.common['Authorization']
      axios({
        url: `${process.env.VUE_APP_SPRING_API_BASEURL}/api/authentication/refresh`,
        data: {token: localStorage.getItem("refresh-token")},
        method: 'POST'
      })
        .then((resp) => {
          localStorage.setItem("token", resp.data.access_token);
          localStorage.setItem("refresh-token", resp.data.refresh_token);
          axios.defaults.headers.common = {
            "Authorization": `Bearer ${resp.data.access_token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
          commit(AUTH_SUCCESS, resp)
          resolve(resp);
        })
        .catch(err => {
          reject(err)
        })
    })
  },
  [AUTH_PROVIDER]: ({commit}: any, token: any) => {
    return new Promise((resolve, reject) => {
      axios.defaults.headers.common = {
        Authorization: `Bearer ${token.token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      };
      axios({url: `${process.env.VUE_APP_SPRING_API_BASEURL}/api/authentication/provider`, method: 'POST'})
        .then((resp) => {
          localStorage.setItem("token", resp.data.access_token)
          localStorage.setItem("refresh-token", resp.data.refresh_token)
          axios.defaults.headers.common = {
            "Authorization": `Bearer ${resp.data.access_token}`,
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*'
          }
          commit(AUTH_SUCCESS_PROVIDER, resp.data.access_token)
          resolve(resp);
        })
        .catch(err => {
          reject(err)
        })
    })
  }
}

const mutations = {
  [AUTH_REQUEST]: (state: any) => {
    state.status = "loading"
  },
  [AUTH_CREATE_ACCOUNT]: (state: any) => {
    state.status = "loading"
  },
  [AUTH_CREATE_ACCOUNT_AND_JOIN]: (state: any) => {
    state.status = "loading"
  },
  [AUTH_SUCCESS]: (state: any, resp: any) => {
    state.status = "success"
    state.token = resp.data.access_token
    state.jwtdata = state.token ? JSON.parse(Buffer.from((localStorage.getItem("token") || "").split(".")[1], "base64").toString()) : ""
    state.hasLoadedOnce = true
  },
  [AUTH_SUCCESS_PROVIDER]: (state: any, resp: any) => {
    state.status = "success"
    state.token = resp
    state.jwtdata = state.token ? JSON.parse(Buffer.from((localStorage.getItem("token") || "").split(".")[1], "base64").toString()) : ""
  },
  [AUTH_CREATION_SUCCESS]: (state: any) => {
    state.status = "success"
  },
  [AUTH_ERROR]: (state: any) => {
    state.status = "error"
    state.hasLoadedOnce = true
  },
  [AUTH_LOGOUT]: (state: any) => {
    state.token = ""
    state.jwtdata = ""
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
