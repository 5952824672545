import Vue from 'vue'
import VueRouter from 'vue-router'
import Applications from '../views/Applications.vue'
import Application from '../views/Application.vue'
import Catalogue from '../views/Catalogue.vue'
import Action from '../views/Action.vue'
import Deploiement from '../views/Deploiement.vue'
import ConfigurationDeploiement from '../views/ConfigurationDeploiement.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import NotFoundPage from '../views/NotFoundPage.vue'
import Error50x from '../views/Error50x/Error50x.vue'
import Account from '../views/Account.vue'
import Callback from '../views/Callback.vue'
import Administration from "../views/Administration.vue"
import UpgradeConfiguration from "../views/UpgradeConfiguration.vue"
import AdminAddApplication from "../views/AdminAddApplication.vue"
import AdminEditApplication from "../views/AdminEditApplication.vue"
import NoEntreprise from "../views/NoEntreprise.vue"
import Login from "../views/Login.vue"
import Signup from "../views/Signup.vue"
import Panier from "../views/Panier.vue"
import store from '../store'
// @ts-ignore
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import VueGtag from 'vue-gtag'

Vue.use(VueRouter)

const ifNotAuthenticated = (to: any, from: any, next: any) => {
  if (!store.getters.isAuthenticated) {
    return next()
  }
  next('/')
}

const ifHasEntrepriseAndIsAuthenticated = (to: any, from: any, next: any) => {
  if (store.getters.isAuthenticated && (store.getters.userData.realm_access && store.getters.userData.realm_access.roles.filter((value: any) => value !== 'offline_access' && value !== 'uma_authorization' && value !== 'default-roles-elosi' && value !== 'default-roles-autoscaling-realm' && value !== 'ADMIN').length > 0)) {
    if (to.name === 'NoEntreprise') return next('/')
    return next();
  } else if (store.getters.isAuthenticated) {
    if (to.name !== "NoEntreprise" && to.name !== "Account")
      return next('/home');
    return next();
  }
  return next(`/login${to.path ? `?from=${to.path}` : ""}`)
}

// Permet de vérifié sur l'utilisateur est authentifié et est admin
// Remplace la balise meta
const ifAuthenticatedAndAdmin = (to: any, from: any, next: any) => {
  if (store.getters.isAuthenticated && store.getters.userData.realm_access.roles.filter((value: any) => value === 'ADMIN').length > 0) {
    return next();
  } else if (store.getters.isAuthenticated) {
    return next('404')
  }
  return next(`/login${to.path ? `?from=${to.path}` : ""}`)
}

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/signup',
    name: 'Signup',
    component: Signup,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/',
    name: 'Applications',
    component: Applications,
    beforeEnter: ifHasEntrepriseAndIsAuthenticated,
  },
  {
    path: '/home',
    name: 'NoEntreprise',
    component: NoEntreprise,
    beforeEnter: ifHasEntrepriseAndIsAuthenticated,
  },
  {
    path: '/catalogue',
    name: 'Catalogue',
    component: Catalogue,
    beforeEnter: ifHasEntrepriseAndIsAuthenticated,
  },
  {
    path: '/action',
    name: 'Action',
    component: Action,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/deploiement/:namespace/:repo',
    name: 'Deploiement',
    component: Deploiement,
    beforeEnter: ifHasEntrepriseAndIsAuthenticated,
  },
  {
    path: '/deploiement/config/:namespace/:repo/:name',
    name: 'ConfigDeploiement',
    component: ConfigurationDeploiement,
    beforeEnter: ifHasEntrepriseAndIsAuthenticated,
  },
  {
    path: '/:cluster/:namespace/apps/:app',
    name: 'Application',
    component: Application,
    beforeEnter: ifHasEntrepriseAndIsAuthenticated,
  },
  {
    path: '/:cluster/:namespace/apps/:app/upgrade',
    name: 'ApplicationUpgrade',
    component: UpgradeConfiguration,
    beforeEnter: ifHasEntrepriseAndIsAuthenticated,
  },
  {
    path: '/account/:panel?',
    name: 'Account',
    component: Account,
    beforeEnter: ifHasEntrepriseAndIsAuthenticated,
  },
  {
    path: '/administration/catalogue/add',
    name: 'AddCatalogue',
    component: AdminAddApplication,
    beforeEnter: ifAuthenticatedAndAdmin,
  },
  {
    path: '/administration/catalogue/edit/:app',
    name: 'EditCatalogue',
    component: AdminEditApplication,
    beforeEnter: ifAuthenticatedAndAdmin,
  },
  {
    path: '/administration/:panel?',
    name: 'Administration',
    component: Administration,
    beforeEnter: ifAuthenticatedAndAdmin,
  },
  {
    path: '/panier',
    name: 'Panier',
    component: Panier,
    beforeEnter: ifHasEntrepriseAndIsAuthenticated,
  },
  {
    path: '/callback',
    name: 'Callback',
    component: Callback,
  },
  {
    path: '/50x',
    name: '500',
    component: Error50x,
  },
  {
    path: '*',
    name: 'NotFoundPage',
    component: NotFoundPage,
    beforeEnter: ifHasEntrepriseAndIsAuthenticated,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

Vue.use(VueGtag, {
  config: {id: process.env.VUE_APP_GOOGLE_ANALYTICS_ID},
  bootstrap: localStorage.getItem('rgpd:google-analytics') === 'true'
}, router)

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next()
})

router.afterEach(() => {
  NProgress.done();
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(value => value.meta.requiredBeAdmin)) {
    if (store.getters.isAuthenticated) {
      if (store.getters.userData.realm_access.roles.indexOf('ADMIN') !== -1) {
        next();
      } else {
        next({
          name: 'NotFoundPage'
        });
      }
    }
  } else {
    next();
  }
});

export default router
