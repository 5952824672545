






















































































import Vue from "vue";
import {VuetifyThemeItem} from "vuetify/types/services/theme";
import {Component} from "vue-property-decorator";

const axios = require("axios");

@Component
export default class ForgotPassword extends Vue {
  public icon: VuetifyThemeItem = ''
  public dialog: boolean = true
  public disable: boolean = false
  public email: String = ""
  public valid: boolean = true
  public errorMessage: String = ""
  public error: boolean = false
  public success: boolean = false

  public mounted() {
    if (!this.$vuetify.theme.dark) {
      this.icon = '/logo_autoscaling.svg';
    } else {
      this.icon = '/logo_autoscaling_dark.svg';
    }
  }

  public submit() {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      this.disable = true;
      delete axios.defaults.headers.common["Authorization"];
      axios
          .post(
              `${process.env.VUE_APP_SPRING_API_BASEURL}/api/authentication?userId=${this.email}&action=resetPassword`
          )
          .then(() => {
            this.success = true;
          })
          .catch((error: any) => {
            this.error = true;
            this.errorMessage = error.response.data.message;
          });
    }
  }
}
