






























































import Vue from "vue";

import {Component, Prop} from "vue-property-decorator";

import {MONTHLY_FACTURES_REQUEST} from "@/api/actions/pricing/const_monthly_facture";
import {TranslateResult} from "vue-i18n";

@Component({})
export default class MonthlyFactures extends Vue {
  @Prop({}) nomEntrepriseAdmin: string | undefined;

  factures: {}[] = [];

  firstLoad: boolean = true;

  error: boolean = false;
  errorMessage: string = "";
  success: boolean = false;
  successMessage: string = "";

  show: boolean = true;

  entrepriseName: string = "";

  sortBy: string = "date";
  sortDesc: boolean = true;

  get headers(): {
    text: TranslateResult;
    align: string;
    value: string;
    sortable?: boolean;
  }[] {
    return [
      {
        text: this.$t("monthlyFacture.application"),
        align: "center",
        value: "application",
      },
      {
        text: this.$t("monthlyFacture.nomApplication"),
        align: "center",
        value: "nomApplication",
      },
      {
        text: this.$t("monthlyFacture.date"),
        value: "date",
        align: "center",
      },
      {
        text: this.$t("monthlyFacture.telecharger"),
        value: "actions",
        align: "center",
        sortable: false,
      },
    ];
  }

  public mounted(): void {
    if (
        this.nomEntrepriseAdmin !== undefined &&
        this.nomEntrepriseAdmin.length > 0
    ) {
      this.entrepriseName = this.nomEntrepriseAdmin;
    } else {
      this.entrepriseName = this.$store.getters.entreprise;
    }

    this.initialize();
  }

  public initialize(): void {
    this.$store
        .dispatch(MONTHLY_FACTURES_REQUEST, this.entrepriseName)
        .then((response) => {
          this.factures = response;
        })
        .catch((error) => {
          this.error = true;
          this.errorMessage = error;
        })
        .finally(() => (this.firstLoad = false));
  }

  public downloadBill(item: any): void {
    const filename = `facture_monthly_${item.nomApplication}_${item.date}.pdf`;

    const element = document.createElement("a");

    element.setAttribute(
        "href",
        `data:text/plain;;base64,${encodeURIComponent(item.pdf)}`
    );

    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
  }
}
