




























































































































































import Vue from "vue";

import {Component, Prop} from "vue-property-decorator";

import TicketMessage from "@/components/account/support/TicketMessage.vue";
import TicketReponse from "@/components/account/support/TicketReponse.vue";

import {
  ENTREPRISE_TICKET_REQUEST,
  ENTREPRISE_UPDATE_TICKET_STATUS,
} from "@/api/actions/support/const_entreprise_ticket";
import {TranslateResult} from "vue-i18n";

@Component({
  components: {
    TicketMessage,
    TicketReponse,
  },
})
export default class TicketDetailsEntreprise extends Vue {
  @Prop({}) isAdmin: boolean | undefined;
  @Prop({}) idTicket: string | undefined;

  ticket: any

  reloadKey: number = 0;

  loading: boolean = true;

  success: boolean = false;
  successMsg: TranslateResult = "";

  error: boolean = false;
  errorMsg: string = "";

  bgcColor: string = "";

  screenshotZoom: string = "";
  zoomDialog: boolean = false;

  public beforeMount(): void {
    this.getTicket();
  }

  public getTicket(): void {
    this.$store
        .dispatch(ENTREPRISE_TICKET_REQUEST, this.idTicket)
        .then((response) => {
          this.ticket = response;

          switch (this.ticket.ticketStatus) {
            case "FERMER":
              this.bgcColor = "background-color: var(--v-error-base) !important";
              break;
            case "ARCHIVE":
              this.bgcColor = "background-color: var(--v-error-base) !important";
              break;
            case "OUVERT":
              this.bgcColor =
                  "background-color: var(--v-success-base) !important";
              break;
            case "EN_ATTENTE":
              this.bgcColor =
                  "background-color: var(--v-warning-base) !important";
              break;
          }
        })
        .catch((error) => {
          this.error = true;
          this.errorMsg = error;
        })
        .finally(() => {
          this.reloadKey++;
          this.loading = false;
          this.scrollMessages();
        });
  }

  public scrollMessages(): void {
    const messagesList = document.getElementById(`messagesList`);

    if (messagesList === null) {
      setTimeout(this.scrollMessages, 100);
    } else {
      messagesList.scrollTop = messagesList.scrollHeight;
    }
  }

  public refreshData(event: TranslateResult): void {
    this.getTicket();
    this.success = true;
    this.error = false;
    this.successMsg = event;
  }

  public errorTicket(event: string): void {
    this.success = false;
    this.error = true;
    this.errorMsg = event;
  }

  public retour(): void {
    this.$emit("retourListeTicket");
  }

  public open(): void {
    const data = {ticketId: this.idTicket, ticketStatus: "OUVERT"};
    this.$store
        .dispatch(ENTREPRISE_UPDATE_TICKET_STATUS, data)
        .then(() => {
        })
        .catch(() => {
        })
        .finally(() => this.refreshData(this.$t("success.ticketOuvert")));
  }

  public close(): void {
    const data = {ticketId: this.idTicket, ticketStatus: "FERMER"};
    this.$store
        .dispatch(ENTREPRISE_UPDATE_TICKET_STATUS, data)
        .then(() => {
        })
        .catch(() => {
        })
        .finally(() => this.refreshData(this.$t("success.ticketFermer")));
  }

  public zoomImage(file: string): void {
    this.screenshotZoom = file;
    this.zoomDialog = true;
  }
}
