
















































































import Vue from "vue";

import {Component} from "vue-property-decorator";

import axios from "axios";
import StatsGenerales from "@/components/administration/stats/StatsGenerales.vue";
import StatsWeekly from "@/components/administration/stats/StatsWeekly.vue";
import StatsUtilisateur from "@/components/administration/stats/StatsUtilisateur.vue";
import StatsApp from "@/components/administration/stats/StatsApp.vue";
import LastEvent from "@/components/administration/stats/LastEvent.vue";

@Component({
  components: {
    StatsGenerales,
    StatsWeekly,
    StatsUtilisateur,
    StatsApp,
    LastEvent,
  },
})
export default class Stats extends Vue {
  loading: boolean = true;

  stats: {} = {};

  public mounted(): void {
    this.initStats();
  }

  public initStats(): void {
    this.loading = true;

    axios
        .get(`${process.env.VUE_APP_SPRING_API_BASEURL}/api/stats`)
        .then((response) => {
          this.stats = response.data;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
  }
}
