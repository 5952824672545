



























































































import DatePicker from "@/components/DatePicker.vue";
import {PROMO_NEW} from "@/api/actions/pricing/const_promotion";
import {Component, Vue} from "vue-property-decorator";
import {TranslateResult} from "vue-i18n";

@Component({
  components: {
    DatePicker
  }
})
export default class CreatePromotion extends Vue {
  public loading: boolean = false
  public dialog: boolean = false
  public resolve: any = null
  public codePromo: any = null
  public reduction: any = null
  public dateDebut: any = null
  public dateFin: any = null
  public utilisationMax: any = null
  public error: boolean = false
  public errorMessage: TranslateResult = ""

  public open() {
    this.dialog = true;
  }

  public close() {
    this.dialog = false;
  }

  public createCode() {
    this.loading = false;

    if (this.reduction == null) {
      this.error = true;
      this.errorMessage = this.$t("error.emptyReduction");
    } else {
      const data = {
        code: this.codePromo,
        reduction: this.reduction,
        dateDebut: this.dateDebut,
        dateFin: this.dateFin,
        utilisationsRestantes: this.utilisationMax,
      };

      this.$store
          .dispatch(PROMO_NEW, data)
          .then((response) => {
            this.error = false;
            this.$emit(
                "reloadPromo",
                this.$t("success.promoCreer", {msg: response})
            );
          })
          .catch((err) => {
            this.error = true;
            this.errorMessage = err;
          })
          .finally(() => {
            this.loading = false
          });
    }
    this.dialog = false
  }
}
