import { render, staticRenderFns } from "./StatsPie.vue?vue&type=template&id=0e74e231&"
import script from "./StatsPie.vue?vue&type=script&lang=ts&"
export * from "./StatsPie.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports