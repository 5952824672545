var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"Config-app-view","fluid":"","tag":"section"}},[(_vm.loadingApp)?_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"9"}})],1):_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"9"}},[(_vm.error && !_vm.errorMessage)?_c('Alert',{attrs:{"message":"Impossible de joindre le backend !"}}):(_vm.error)?_c('Alert',{attrs:{"message":_vm.$t(_vm.errorMessage)}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('v-card',[_c('MaterialCard',{attrs:{"color":"secondary","icon":"tune"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_c('p',{domProps:{"innerHTML":_vm._s(_vm.$t('upgrade.header'))}})]},proxy:true}])},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-container',{staticClass:"py-0"},[_c('v-text-field',{attrs:{"disabled":"","label":"Nom","required":""},model:{value:(_vm.application.name),callback:function ($$v) {_vm.$set(_vm.application, "name", $$v)},expression:"application.name"}}),(_vm.versions !== undefined)?_c('v-select',{attrs:{"hint":_vm.$t('upgrade.version'),"item-text":function (versions) { return versions !== undefined &&
                    versions.pkgVersion +
                    ' - ' +
                    'App version ' +
                    versions.appVersion; },"item-value":function (versions) { return versions.pkgVersion; },"items":_vm.versions,"label":_vm.$t('upgrade.version'),"persistent-hint":"","single-line":""},model:{value:(_vm.version),callback:function ($$v) {_vm.version=$$v},expression:"version"}}):_vm._e(),_vm._l((_vm.items),function(item,index){return _c(item,{key:index,tag:"FormConfiguration",staticClass:"mt-3 mb-3",attrs:{"config":_vm.application.release.config[index],"configs":_vm.application.release.config,"index":index},on:{"configuration-form-deploy":_vm.updateConfiguration}})})],2)],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('AppCard',{staticClass:"mt-4 text-center",attrs:{"name":"name"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-img',{staticClass:"mt-n12 d-inline-block",attrs:{"src":_vm.chart.iconUrl,"width":"128"}}),_c('v-card-text',{staticClass:"text-center"},[_c('h6',{staticClass:"text-h6 mb-2 text--secondary"},[_vm._v(" "+_vm._s(_vm.chart.categories[0])+" ")]),_c('h4',{staticClass:"text-h4 mb-3 text--primary"},[_vm._v(" "+_vm._s(_vm.chart.name)+" ")]),_c('p',{staticClass:"text--secondary"},[_vm._v(" "+_vm._s(_vm.chart.shortDescription)+" ")]),_c('v-btn',{staticClass:"mb-2",attrs:{"loading":_vm.loading,"block":"","color":"warning","min-width":"100","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t("upgrade.update"))+" ")]),_c('v-btn',{attrs:{"loading":_vm.loading,"to":{
                name: 'Application',
                params: {
                  namespace: _vm.application.namespace,
                  app: _vm.application.name,
                  cluster: 'default',
                },
              },"block":"","color":"error","min-width":"100"}},[_vm._v(" "+_vm._s(_vm.$t("upgrade.annuler"))+" ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }