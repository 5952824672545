var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{style:({ zIndex: _vm.options.zIndex }),attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.cancel.apply(null, arguments)}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',[_c('v-card',[_c('v-toolbar',{attrs:{"color":_vm.options.color,"dark":"","dense":"","flat":""}},[_c('v-toolbar-title',{staticClass:"text-body-2 font-weight-bold grey--text"},[_vm._v(" "+_vm._s(_vm.$t(_vm.title))+" ")])],1),_c('v-card-text',{directives:[{name:"show",rawName:"v-show",value:(!!_vm.message),expression:"!!message"}],staticClass:"pa-4",domProps:{"innerHTML":_vm._s(_vm.$t(_vm.message))}}),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('validation-provider',{attrs:{"rules":{
              required: true,
              regex: '^[a-z0-9]([-a-z0-9]*[a-z0-9])?$',
            },"name":"Nom de l'entreprise"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$t('dialogInput.entrepriseLabel'),"filled":""},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})]}}],null,true)})],1),_c('v-card-actions',{staticClass:"pt-3"},[_c('v-spacer'),(!_vm.options.noconfirm)?_c('v-btn',{staticClass:"body-2 font-weight-bold",attrs:{"color":"primary","text":""},nativeOn:{"click":function($event){return _vm.cancel.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("dialogInput.annuler"))+" ")]):_vm._e(),_c('v-btn',{staticClass:"body-2 font-weight-bold",attrs:{"disabled":invalid,"color":"error"},nativeOn:{"click":function($event){return _vm.agree.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.$t("dialogInput.confirmer"))+" ")])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }