




































































































































import Vue from "vue";
import axios from "axios";

import {Component} from "vue-property-decorator";

import Alert from "@/components/alert/Alert.vue";
import FormConfiguration from "@/components/deploiement/FormConfiguration.vue";
import AppCard from "@/components/app/AppCard.vue";
import MaterialCard from "@/components/material/MaterialCard.vue";

@Component({
  components: {
    FormConfiguration,
    Alert,
    AppCard,
    MaterialCard,
  },
})
export default class UpgradeConfiguration extends Vue {
  application: any;
  chart: any = {};
  items: typeof FormConfiguration[] = [];
  datas: {}[] = [];
  versions: { attributes: { version: string } }[] = [];
  version: string = "";
  error: boolean = false;
  errorMessage: string = "";
  valid: boolean = true;
  nom: string = "";
  loading: boolean = false;
  loadingApp: boolean = true;
  nomRegle: {}[] = [
    (v: string) => !!v || "Le nom est requis",
    (v: string) =>
        /^[a-z0-9]([-a-z0-9]*[a-z0-9])?(\.[a-z0-9]([-a-z0-9]*[a-z0-9])?)*$/.test(
            v
        ) || "Le nom du service est incorrect !",
  ];

  get URL() {
    return process.env.VUE_APP_SPRING_API_BASEURL
  }

  get form(): Vue & { validate: () => boolean } {
    return this.$refs.form as Vue & { validate: () => boolean };
  }

  public beforeMount(): void {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/services/clusters/${this.$route.params.cluster}/namespaces/${this.$route.params.namespace}/releases/${this.$route.params.app}`
        )
        .then((response) => {
          this.application = response.data.data[0];
          axios
              .get(
                  `${process.env.VUE_APP_SPRING_API_BASEURL}/api/assets/default/${this.$route.params.namespace}/charts?filterOptions.query=${this.application.chart.metadata.name}`
              )
              .then((data) => {
                this.chart = data.data.availablePackageSummaries[0];
                if (this.application.release && this.application.release.config) {
                  this.datas = this.application.release.config;
                  for (let i = 0; i < this.application.release.config.length; i++) {
                    this.items.push(FormConfiguration);
                  }
                }
                this.loadVersions();
              })
              .catch(() => {
                this.error = true;
              });
        })
        .catch(() => {
          this.$router.push({name: "Applications"}).catch(() => {});
        })
  }

  public loadVersions() {
    axios
        .get(
            `${process.env.VUE_APP_SPRING_API_BASEURL}/api/assets/default/${this.chart.availablePackageRef.context.namespace}/charts/${this.chart.availablePackageRef.identifier}/versions`
        )
        .then((response) => {
          this.versions = response.data.packageAppVersions;
          this.version = this.application.chart.metadata.version;
        })
        .catch(() => {
          this.chart = null;
        }).finally(() => {
          this.loadingApp = false
        });
  }

  public updateConfiguration(conf: any, n: number) {
    if (this.datas[n] === undefined) {
      this.datas[n] = {};
    }
    this.datas[n] = conf;
  }

  public submit() {
    this.valid = this.form.validate();
    if (this.valid) {
      this.loading = true;

      // Conversion en YAML
      let values = "";
      this.datas.forEach((value: any) => {
        values += `${value.key}=${value.value}\n`;
      });

      axios
          .put(
              `${
                  process.env.VUE_APP_SPRING_API_BASEURL
              }/api/services/clusters/default/namespaces/${localStorage.getItem(
                  "namespace"
              )}/releases/${this.application.name}`,
              {
                appRepositoryResourceName:
                    this.chart.availablePackageRef.identifier.split('/')[0],
                appRepositoryResourceNamespace:
                this.chart.availablePackageRef.context.namespace,
                chartName: this.chart.name,
                releaseName: this.application.name,
                version: this.version,
                values: values.toString(),
                datas: this.datas,
              }
          )
          .then((response) => {
            this.$router.push({
              name: "Application",
              params: {
                cluster: "default",
                namespace: response.data.data.namespace,
                app: this.application.name,
              },
            }).catch(() => {});
          })
          .catch((error) => {
            this.error = true;
            this.errorMessage = error.response.data.message;
            this.loading = false;
          });
    }
  }
}
