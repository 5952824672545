























import Vue from "vue";

import {Component, Prop, Watch} from "vue-property-decorator";

import {SET_SELECTED_ENTREPRISE, SET_SELECTED_NAMESPACE,} from "@/store/actions/data";

@Component({})
export default class AppBarDropdownMobile extends Vue {
  @Prop({}) name: any;
  @Prop({}) dataList: any[] | undefined;

  selectedItem: number = 0;

  get selected() {
    if (this.name === "appbar.entreprises") {
      return this.$store.getters.selectedEntreprise;
    } else {
      return this.$store.getters.selectedNamespace;
    }
  }

  public mounted(): void {
    this.selectedItem = this.selected;
  }

  @Watch("selected")
  onSelectedChange(newV: any) {
    if (newV !== this.selectedItem) {
      this.selectedItem = newV;
    }
  }

  @Watch("selectedItem")
  onSelectedItemChange(newV: number, oldV: number) {
    // Si on clique 2 fois sur le meme, affichait () a la place de (DATA)
    if (newV !== undefined) {
      if (this.name === "appbar.entreprises") {
        this.$store.dispatch(SET_SELECTED_ENTREPRISE, newV);
      } else {
        this.$store.dispatch(SET_SELECTED_NAMESPACE, newV);
      }
    } else {
      this.selectedItem = oldV;
    }
  }
}
